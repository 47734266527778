import React, { useState, useEffect, useRef, useContext } from 'react';
import { BellOutlined, CaretDownOutlined, CaretUpOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Button, Empty, List, notification, Popover, Radio, Switch } from 'antd';
import NotificationsService from '../../services/NotificationsService';
import { useTranslation } from 'react-i18next';
import { io } from 'socket.io-client';
import { getStorageToken } from '../../helpers/storageTools';
import moment from 'moment';
import { SHORT_DATE_TIME_FORMAT } from '../../constants/formats';
import config from '../../config';
import arrowIcon from '../../assets/images/ic-arrow-blue.svg';
import LogoComponent from './LogoComponent';
import {
  EmailSettingsType,
  NotificationSettingsType,
  UpdatedEmailSetting,
  UpdatedNotificationSetting,
} from '../../models/NotificationModel';
import { AppContext } from '../../contexts/AppContextProvider';
import { useHistory } from 'react-router';
import { SET_NON_AUTHENTICATED } from '../../constants/actionTypes/appConstants';
import { IAppContext } from '../../typings/IApp';

const service = new NotificationsService();

const NotificationsLoadMore = (props: any) => {
  const { t } = useTranslation();
  const { isVisible = true, onLoadMore } = props;
  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <Button className="notification-load-more-btn common-secondary-btn " onClick={onLoadMore}>
        {t('notification.load.more')}
      </Button>
    </div>
  );
};

interface INotificationsListItem {
  item: any;
  isConsumer: boolean;
  isSuplier: boolean;
  checkAsRead: (item: any) => void;
}

const NotificationsListItem = ({ item, isConsumer, isSuplier, checkAsRead }: INotificationsListItem) => {
  const linkBtnRef = useRef<HTMLButtonElement>(null);
  return (
    <List.Item
      onClick={() =>
        !isSuplier &&
        (isConsumer ? !!item?.link && linkBtnRef.current?.click() : !!item?.agentLink && linkBtnRef.current?.click())
      }
      onPointerEnter={() => checkAsRead(item)}>
      <Badge
        className={`notification-item-container ${
          item.recipients?.some((item: any) => !item.read) && 'notification-notRead'
        }`}
        dot={item.recipients?.some((item: any) => !item.read)}
        color="blue">
        <div style={{ width: 300, marginBottom: '0.5rem' }}>
          {item.title && <div className="notification-message">{item.title}</div>}
          {item.message && <div className="notification-message">{item.message}</div>}
          {item.linkName && <div className="notification-linkName">|{item.linkName}|</div>}
        </div>
        <div className="notification-footer">
          {item.createdUser && (
            <>
              <LogoComponent
                id={item.createdUser.id}
                name={`${item.createdUser.firstName} ${item.createdUser.lastName}`}
                image={item.createdUser.image || undefined}
              />
              <span className="notification-point"></span>
            </>
          )}
          <div className="notification-datetime">
            {moment(item.createdDate).format(SHORT_DATE_TIME_FORMAT).split(' ').join(' at ')}
          </div>
        </div>
        <img className="notification-arrow" src={arrowIcon} width={10} height={10} />
        <Button
          ref={linkBtnRef}
          type="link"
          href={isConsumer ? item.link : item.agentLink}
          size="small"
          style={{ display: 'none' }}></Button>
      </Badge>
    </List.Item>
  );
};

const NotificationsList = (props: any) => {
  const {
    app: { isConsumer, isSupplier },
  } = useContext<IAppContext>(AppContext);
  const { options = [], loadMore, onLoadMore, setNotificationAsRead } = props;
  if (options.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const checkAsRead = (item: any) => {
    if (item.recipients?.some((item: any) => !item.read)) {
      setNotificationAsRead(item.id, options);
    }
  };

  return (
    <div className="notification-list-content">
      <List
        split={false}
        itemLayout="horizontal"
        loadMore={<NotificationsLoadMore isVisible={loadMore} onLoadMore={onLoadMore} />}
        dataSource={options}
        renderItem={(item: any) => (
          <NotificationsListItem item={item} isConsumer={isConsumer} isSuplier={isSupplier} checkAsRead={checkAsRead} />
        )}
      />
    </div>
  );
};

const SettingsNotifications = () => {
  const { t } = useTranslation();
  const [siteValue, setSiteValue] = useState('all');
  const [emailValue, setEmailValue] = useState('all');
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettingsType>([]);
  const [emailSettings, setEmailSettings] = useState<EmailSettingsType>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<string | null>(null);
  const [currentEmailSelected, setCurrentEmailSelected] = useState<string | null>(null);

  useEffect(() => {
    getSettings();
    getEmailSettings();
  }, []);

  const getEmailSettings = () => {
    setFetchingData(true);
    service
      .getEmailSettings()
      .then((value: EmailSettingsType) => {
        setEmailSettings(value);
        if (value.every((item) => !item.value && item.emailTypes.every((child) => !child.value))) {
          setEmailValue('nothing');
        } else if (value.every((item) => item.value && item.emailTypes.every((child) => child.value))) {
          setEmailValue('all');
        } else {
          setEmailValue('selected');
        }
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };
  const getSettings = () => {
    setFetchingData(true);
    service
      .getNotificationsSettings()
      .then((value: NotificationSettingsType) => {
        setNotificationSettings(value);
        if (value.every((item) => !item.value && item.childrens.every((child) => !child.value))) {
          setSiteValue('nothing');
        } else if (value.every((item) => item.value && item.childrens.every((child) => child.value))) {
          setSiteValue('all');
        } else {
          setSiteValue('selected');
        }
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const setSettings = (code: string, value?: boolean) => {
    let body: UpdatedNotificationSetting = {};
    if (code === 'all') {
      body = {
        value: true,
      };
    } else if (code === 'nothing') {
      body = {
        value: false,
      };
    } else {
      body = {
        value: !value,
        targetTypeCode: code,
      };
    }

    service.updateNotificationSettings(body).then(() => getSettings());
  };

  const setSettingsEmail = (code: string, value?: boolean, isParent?: boolean) => {
    let body: UpdatedEmailSetting = {};
    if (code === 'all') {
      body = {
        value: true,
      };
    } else if (code === 'nothing') {
      body = {
        value: false,
      };
    } else {
      if (isParent) {
        body = {
          value,
          emailCategory: code,
        };
      } else {
        body = {
          value,
          emailTypeCode: code,
        };
      }
    }

    service.updateEmailSettings(body).then(() => getEmailSettings());
  };

  return (
    <div className="notification-settings-content">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>{t('notification.bell.settings.site')}</span>
        <Radio.Group
          style={{ display: 'flex', flexDirection: 'column', marginTop: '0.5rem' }}
          onChange={(e) => setSiteValue(e.target.value)}
          value={siteValue}>
          <Radio
            style={{ marginTop: '0.5rem' }}
            value={'all'}
            disabled={fetchingData}
            onChange={() => setSettings('all')}>
            {t('notification.bell.settings.all')}
          </Radio>
          <Radio style={{ marginTop: '0.5rem' }} value={'selected'} disabled={fetchingData}>
            {t('notification.bell.settings.selected')}
          </Radio>
          {siteValue === 'selected' &&
            notificationSettings.map((item) => {
              return (
                <div style={{ marginTop: '0.5rem' }} key={item.code}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid #b7c2d3',
                    }}>
                    <span
                      className="notification-settings-parent"
                      onClick={() => setCurrentSelected(item.code === currentSelected ? null : item.code)}>
                      {item.description || ''}
                      <span>
                        {item.code === currentSelected ? (
                          <CaretUpOutlined style={{ fontSize: '12px' }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: '12px' }} />
                        )}
                      </span>
                    </span>
                    <Switch
                      defaultChecked={item.value}
                      onChange={() => {
                        setCurrentSelected(null);
                        setSettings(item.code, item.value);
                      }}
                    />
                  </div>
                  {item.code === currentSelected &&
                    item.childrens.map((value) => (
                      <div className="notification-settings-child-container">
                        <span className="notification-settings-child-title ">{value.description || ''}</span>
                        <Switch defaultChecked={value.value} onChange={() => setSettings(value.code, value.value)} />
                      </div>
                    ))}
                </div>
              );
            })}
          <Radio
            style={{ marginTop: '0.5rem' }}
            value={'nothing'}
            disabled={fetchingData}
            onChange={() => setSettings('nothing')}>
            {t('notification.bell.settings.nothing')}
          </Radio>
        </Radio.Group>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
        <span style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>{t('notification.bell.settings.email')}</span>
        <Radio.Group
          style={{ display: 'flex', flexDirection: 'column', marginTop: '0.5rem' }}
          onChange={(e) => setEmailValue(e.target.value)}
          value={emailValue}>
          <Radio
            style={{ marginTop: '0.5rem' }}
            value={'all'}
            disabled={fetchingData}
            onChange={() => setSettingsEmail('all')}>
            {t('notification.bell.settings.all')}
          </Radio>
          <Radio style={{ marginTop: '0.5rem' }} value={'selected'} disabled={fetchingData}>
            {t('notification.bell.settings.selected')}
          </Radio>
          {emailValue === 'selected' &&
            emailSettings.map((item) => {
              return (
                <div style={{ marginTop: '0.5rem' }} key={item.code}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid #b7c2d3',
                    }}>
                    <span
                      className="notification-settings-parent"
                      onClick={() => setCurrentEmailSelected(item.code === currentEmailSelected ? null : item.code)}>
                      {item.description || ''}
                      <span>
                        {item.code === currentEmailSelected ? (
                          <CaretUpOutlined style={{ fontSize: '12px' }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: '12px' }} />
                        )}
                      </span>
                    </span>
                    <Switch
                      defaultChecked={item.value}
                      onChange={(value) => {
                        setCurrentEmailSelected(null);
                        setSettingsEmail(item.code, value, true);
                      }}
                    />
                  </div>
                  {item.code === currentEmailSelected &&
                    item.emailTypes.map((value) => (
                      <div className="notification-settings-child-container">
                        <span className="notification-settings-child-title ">{value.description || ''}</span>
                        <Switch
                          defaultChecked={value.value}
                          onChange={(event) => {
                            setSettingsEmail(value.code, event, false);
                          }}
                        />
                      </div>
                    ))}
                </div>
              );
            })}
          <Radio
            style={{ marginTop: '0.5rem' }}
            value={'nothing'}
            disabled={fetchingData}
            onChange={() => setSettingsEmail('nothing')}>
            {t('notification.bell.settings.nothing')}
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

const defaultPageSize = 10;

const NotificationsBell = (props: any) => {
  const socket = useRef<any>(null);
  const history = useHistory();
  const { appDispatch } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isRinging, setIsRinging] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [options, setOptions] = useState<any>({
    current: 0,
    top: defaultPageSize,
  });
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [isOpenSettings, setIsOpenSettings] = useState(false);

  const getNotifications = (options: any, isLoadMore: boolean = false) => {
    service.getNotifications(options).then((res: any) => {
      const { items = [], count = 0, unreadCount = 0 } = res;
      if (isLoadMore) {
        setNotifications([...notifications, ...items]);
      } else {
        setNotifications(items);
        setCount(unreadCount);
      }
      setTotal(count);
    });
  };

  const setNotificationAsRead = (id: number, options: any[]) => {
    service.setNotificationsAsRead([id]).then(() => {
      options.forEach((notification: any) => {
        if (notification.id === id) {
          notification.recipients.forEach((item: { read: boolean }) => {
            item.read = true;
          });
        }
      });
      const unreadCount = count ? count - 1 : 0;
      setCount(unreadCount);
      setNotifications(options);
    });
  };

  const setNotificationsAsRead = (options: any[]) => {
    service
      .getNotifications({
        filter: {
          'recipients/read': {
            eq: false,
          },
        },
      })
      .then((value) => {
        if (value.items.length > 0) {
          const ids = value.items.map((notification: any) => notification.id);
          service.setNotificationsAsRead(ids).then(() => {
            options.forEach((notification: any) => {
              if (ids.includes(notification.id)) {
                notification.recipients.forEach((item: { read: boolean }) => {
                  item.read = true;
                });
              }
            });
            setCount(0);
            setNotifications(options);
          });
        }
      });
  };

  const initSocket = () => {
    socket.current = io(`${config.notificationSocket}`, { auth: { token: getStorageToken() } });

    socket?.current?.on('connect', () => {
      socket?.current?.on('notification:new-message', (res: any) => {
        if (!isVisible) {
          ringingBell();
        }
        getNotifications(options);
      });
    });
  };

  const checkConnectionSocket = () => {
    socket?.current?.on('connect_error', () => {
      appDispatch({ type: SET_NON_AUTHENTICATED });
      history.push('/login');
    });
  };

  const terminateSocket = () => {
    if (socket) {
      socket?.current?.disconnect();
    }
  };

  useEffect(() => {
    initSocket();
    getNotifications(options);
    checkConnectionSocket();

    return () => terminateSocket();
  }, []);

  const ringingBell = () => {
    setIsRinging(true);
    setTimeout(() => {
      setIsRinging(false);
    }, 1500);
  };

  const handleVisible = (visible: boolean) => {
    setIsVisible(visible);
    if (!visible) {
      setIsOpenSettings(false);
    }
  };

  const handleLoadMore = () => {
    const current = options.current + 1;
    const skip = options.top * current;
    const params = { top: options.top, current, skip };
    setOptions(params);
    getNotifications(params, true);
  };

  if (isOpenSettings) {
    return (
      <div>
        <Badge count={count} size="small" color="#e21315" offset={[0, 20]} overflowCount={9}>
          <Popover
            content={<SettingsNotifications />}
            title={
              <div className="notification-container">
                <Badge.Ribbon text="&beta;eta" className="notification-beta">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}>
                    {t('notification.bell.settings.title')}
                    <CloseOutlined className="notification-settings" onClick={() => setIsOpenSettings(false)} />
                  </div>
                </Badge.Ribbon>
              </div>
            }
            trigger="click"
            onVisibleChange={handleVisible}>
            <Button type="text" block shape="circle" /*  onClick={handleClick} */>
              <BellOutlined
                style={{ fontSize: '19px' }}
                className={`notification-bell-img ${isRinging ? 'notification-bell' : ''} ${
                  isVisible ? 'notification-bell-img-active' : ''
                }`}
              />
            </Button>
          </Popover>
        </Badge>
      </div>
    );
  }

  return (
    <div>
      <Badge count={count} size="small" color="#e21315" offset={[0, 20]} overflowCount={9}>
        <Popover
          content={
            <NotificationsList
              setNotificationAsRead={setNotificationAsRead}
              options={notifications}
              loadMore={total > options.top && notifications.length < total}
              onLoadMore={handleLoadMore}
            />
          }
          title={
            <div className="notification-container">
              <Badge.Ribbon text="&beta;eta" className="notification-beta">
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem' }}>
                  <div>
                    {t('notification.bell.title')}
                    <SettingOutlined className="notification-settings" onClick={() => setIsOpenSettings(true)} />
                  </div>
                  <Button className="notification-mark-all" onClick={() => setNotificationsAsRead(notifications)}>
                    {t('notification.mark.all')}
                  </Button>
                </div>
              </Badge.Ribbon>
            </div>
          }
          trigger="click"
          onVisibleChange={handleVisible}>
          <Button type="text" block shape="circle" /*  onClick={handleClick} */>
            <BellOutlined
              style={{ fontSize: '19px' }}
              className={`notification-bell-img ${isRinging ? 'notification-bell' : ''} ${
                isVisible ? 'notification-bell-img-active' : ''
              }`}
            />
          </Button>
        </Popover>
      </Badge>
    </div>
  );
};

export default NotificationsBell;
