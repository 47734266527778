import { Button, notification, Spin, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/details.module.css';
import ListPageServices from '../../../services/PropertiesService';
import { useParams } from 'react-router';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

interface DeleteConfirmFormProps {
  isOpen: boolean;
  setIsNeedUpdate?: (b: boolean) => void;
  setClose: (b: boolean) => void;
}

const service = new ListPageServices();

const CreatePlan = (props: DeleteConfirmFormProps) => {
  const { isOpen, setClose, setIsNeedUpdate } = props;
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const params = useParams<{ propertyId: string }>();

  const handleFile = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
  };

  const createPlan = () => {
    setFetchingData(true);
    if (fileList.length === 0) {
      return;
    }
    service
      .saveImages(+params.propertyId, fileList, 'plans')
      .then(() =>
        notification.success({
          message: t('properties.modal.create.success.message'),
        }),
      )
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => {
        setFetchingData(false);
        setIsNeedUpdate && setIsNeedUpdate(true);
        setClose(false);
      });
  };

  return (
    <Spin spinning={fetchingData}>
      <Modal
        className="common-modal"
        centered
        style={{ width: '10rem' }}
        visible={isOpen}
        footer={
          <div className="common-modal-footer">
            <Button className={`common-gray-border-btn common-secondary-btn`} onClick={() => setClose(false)}>
              {t('budget.modal.cancel.btn')}
            </Button>
            <Button className={`common-green-btn common-modal-btn`} onClick={() => createPlan()}>
              {t('budget.modal.save.btn')}
            </Button>
          </div>
        }
        closable={false}>
        <Upload
          className="common-modal-upload"
          listType="picture"
          maxCount={1}
          accept=".pdf, image/*"
          style={{ width: '100%' }}
          beforeUpload={() => false}
          onChange={handleFile}
          onRemove={() => true}
          fileList={fileList}>
          {fileList.length < 1 && (
            <div className={css['upload-item-details']}>
              <div className={css['upload-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
              <span style={{ color: '#e21315' }}>{t('consumers.modal.upload.file.btn')}</span>
            </div>
          )}
        </Upload>
      </Modal>
    </Spin>
  );
};

export default CreatePlan;
