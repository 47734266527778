import { CheckOutlined, ClockCircleOutlined, FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popover } from 'antd';
import moment from 'moment';
import React from 'react';
import { convertToPrice } from '../../../helpers/convertToPrice';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import css from '../../../assets/styles/file-management.module.css';
import { noPDFFormat } from '../../../helpers/noPDFFormat';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import archiveIcon from '../../../assets/images/ic-archive.svg';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import moreIcon from '../../../assets/images/ic-more.svg';
import { authDownloadFile } from '../../../helpers/authFileTools';
import icAttention from '../../../assets/images/ic-attention.svg';
import { TFunction } from 'react-i18next';

interface ManagementColumnsModel {
  t: TFunction<'translation'>;
  user: any;
  isDocuments: boolean;
  isQuotes: boolean;
  isAmendments: boolean;
  isInvoices: boolean;
  openUpload: (id: number) => void;
  openStatus: (id: number) => void;
  openArchive: (id: number) => void;
  openDelete: (id: number) => void;
  openPreview: (id: number) => void;
  openApprove: (id: number) => void;
  openSign: (id: number, signInfo: string) => void;
  openHistory: (id: number, title: string, item?: any) => void;
  amendmentDownloadFile: (file: any, id: number, fileName: string) => void;
}

export const getColumns = ({
  t,
  user,
  isDocuments,
  isQuotes,
  isInvoices,
  isAmendments,
  openUpload,
  openStatus,
  openArchive,
  openDelete,
  openPreview,
  openApprove,
  openSign,
  openHistory,
  amendmentDownloadFile,
}: ManagementColumnsModel) => [
  {
    title: () => <span className="documents-table-tr">{t('requests.view.card.info.property')}</span>,
    key: 'property',
    sorter: true,
    width: isAmendments || isQuotes ? '10%' : '14%',
    render: (cell: any) => <span>{cell.property.title}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('project.details.table.project')}</span>,
    key: 'project',
    sorter: true,
    width: isAmendments || isQuotes ? '10%' : '14%',
    render: (cell: any) => <span>{cell.property.project.title}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{isDocuments ? t('documents.table.column.name') : '№'}</span>,
    key: 'name',
    sorter: true,
    width: isAmendments || isQuotes ? '18%' : '20%',
    render: (cell: any) => <span>{isDocuments ? cell.title : cell.number}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    sorter: true,
    width: isAmendments || isQuotes ? '18%' : '22%',
    render: (cell: any) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () =>
      isQuotes || isInvoices || isAmendments ? (
        <span className="documents-table-tr">{t('budget.table.sum.total')}</span>
      ) : null,
    key: 'sumWithVat',
    sorter: isQuotes || isInvoices || isAmendments,
    width: isQuotes || isAmendments || isInvoices ? '14%' : 0,
    render: (cell: any) =>
      isQuotes || isInvoices || isAmendments
        ? `${cell.currency?.description || t('budget.currency')} ${
            isAmendments
              ? cell.deltaWithVat
                ? convertToPrice(cell.deltaWithVat)
                : 0
              : cell.sumWithVat
              ? convertToPrice(cell.sumWithVat)
              : 0
          }`
        : null,
  },
  {
    title: () =>
      isQuotes || isAmendments ? <span className="budget-table-tr">{t('fileManagement.table.deadline')}</span> : null,
    key: 'deadline',
    sorter: isQuotes || isAmendments,
    width: isQuotes || isAmendments ? '9%' : 0,
    render: (cell: any) =>
      isQuotes || isAmendments ? (!!cell.deadline ? moment(cell.deadline).format('DD.MM.YYYY') : '-') : null,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.status')}</span>,
    key: 'status',
    sorter: true,
    width: isAmendments || isQuotes ? '14%' : '20%',
    render: (cell: any) => (
      <>
        {cell.statusV2 ? (
          <span
            style={{
              color: setTextColorByStatus(cell.statusV2.code),
            }}>
            {cell.statusV2.description}
          </span>
        ) : null}
      </>
    ),
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: '110px',
    render: (cell: any) => {
      const isCanSignDocument =
        cell.signatories &&
        cell.signatories.findIndex(
          (signatory: any) =>
            signatory.consumerId === user.id &&
            (cell.step?.code === 'agent_sign_open' ||
              cell.step?.code === 'agent_sign_close' ||
              cell.step?.code === 'client_sign') &&
            signatory.stepCode === cell.step?.code &&
            !signatory.isDone,
        ) >= 0;

      const isCanApproveDocument =
        cell.signatories &&
        cell.signatories.findIndex(
          (signatory: any) =>
            signatory.consumerId === user.id &&
            (cell.step?.code === 'agent_approve_open' ||
              cell.step?.code === 'agent_approve_close' ||
              cell.step?.code === 'client_approve') &&
            signatory.stepCode === cell.step?.code &&
            !signatory.isDone,
        ) >= 0;

      const isDisabled = ['rejected'].includes(cell.statusV2?.code)
        ? true
        : cell.signatories.find((item: any) => item.stepCode === cell.step?.code && item.consumerId === user.id)
            ?.isDone ?? true;
      const menu = (
        <Menu className="update-menu-container" style={{ width: '180px' }}>
          {isCanSignDocument && !isDisabled && (
            <Menu.Item key="sign">
              <div className={css['update-menu-item']} onClick={() => openSign(cell.id, cell.comment || cell.signInfo)}>
                <FileDoneOutlined />
                <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                  {t('common.title.sign')}
                </span>
              </div>
            </Menu.Item>
          )}
          {isCanApproveDocument && !isDisabled && (
            <Menu.Item key="approve">
              <div className={css['update-menu-item']} onClick={() => openApprove(cell.id)}>
                <CheckOutlined style={{ color: '#e21315', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.approve.reject')}</span>
              </div>
            </Menu.Item>
          )}
          {!cell?.isSuppliers && (
            <Menu.Item key="edit">
              <div
                onClick={() =>
                  (isAmendments ? cell.quotes && cell.quotes.length > 0 : cell.items && cell.items.length > 0)
                    ? openStatus(cell.id)
                    : openUpload(cell.id)
                }
                className={css['update-menu-item']}>
                <Button
                  style={{
                    border: 'none',
                    backgroundColor: 'inherit',
                    boxShadow: 'none',
                    padding: 0,
                    marginRight: '0.5rem',
                  }}>
                  <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
                </Button>
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
              </div>
            </Menu.Item>
          )}
          <Menu.Item key="preview">
            <div
              className={css['update-menu-item']}
              onClick={() => {
                cell.file.slice(-3) === 'pdf' ? openPreview(cell.id) : noPDFFormat(t);
              }}>
              <FileSearchOutlined />
              <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                {t('common.title.preview')}
              </span>
            </div>
          </Menu.Item>
          <Menu.Item key="delete">
            <div onClick={() => openDelete(cell.id)} className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
            </div>
          </Menu.Item>
          <Menu.Item key="archive">
            <div onClick={() => openArchive(cell.id)} className={css['update-menu-item']}>
              <img src={archiveIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.archive')}</span>
            </div>
          </Menu.Item>
          {cell.file && (
            <Menu.Item key="upload">
              <div
                className={css['update-menu-item']}
                onClick={() =>
                  isAmendments
                    ? amendmentDownloadFile(cell.file, cell.id, cell.fileName)
                    : authDownloadFile(cell.file, cell.fileName)
                }>
                <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
              </div>
            </Menu.Item>
          )}
        </Menu>
      );
      const content = (
        <div style={{ width: '200px' }}>
          {!cell.file && <p>{t('common.popover.attention.file.content')}</p>}
          {moment(isInvoices ? cell.paymentDate : cell.deadline).isBefore(moment()) &&
            !['signed', 'rejected', 'done', 'canceled'].includes(cell.statusV2?.code) && (
              <p>{t('common.popover.attention.deadline')}</p>
            )}
        </div>
      );
      const title = (
        <div className="common-flex-column-style">
          {!cell.file && (
            <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
          )}
          {moment(isInvoices ? cell.paymentDate : cell.deadline).isBefore(moment()) &&
            !['signed', 'rejected', 'done', 'canceled'].includes(cell.statusV2?.code) && (
              <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.deadline.title')}</span>
            )}
        </div>
      );

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className={css['update-dropdown-btn']}>
              <img src={moreIcon} alt="" height={15} width={15} />
            </Button>
          </Dropdown>
          <ClockCircleOutlined
            style={{ marginLeft: '1rem', marginTop: '3px' }}
            onClick={() =>
              cell.flow
                ? openHistory(cell.id, isDocuments ? cell.title : cell.number, cell)
                : openHistory(cell.id, isDocuments ? cell.title : cell.number)
            }
          />
          {(!cell.file ||
            (moment(isInvoices ? cell.paymentDate : cell.deadline).isBefore(moment()) &&
              !['signed', 'rejected', 'done', 'canceled'].includes(cell.statusV2?.code))) && (
            <Popover content={content} title={title} placement="bottom" trigger="hover">
              <img src={icAttention} alt="" style={{ marginLeft: 10 }} />
            </Popover>
          )}
        </div>
      );
    },
  },
];
