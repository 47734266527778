import React from 'react';
import { Popover } from 'antd';

import moment from 'moment';
import { TFunction, useTranslation } from 'react-i18next';
import icIncoming from '../../../assets/images/ic-mail-incoming.svg';
import icOutgoing from '../../../assets/images/ic-mail-outgoing.svg';
import icAttention from '../../../assets/images/ic-attention.svg';

interface TicketsColumnsModel {
  getTickets: any;
  option: any;
  t: TFunction<'translation'>;
  types: any[];
  userId: number;
  isBroker: boolean;
}

interface Type {
  cell: any;
  userId: any;
  types: any[];
}

const Type = ({ cell, userId, types }: Type) => {
  const isOutgoing = cell.createdUser.id === userId;
  const codeType = isOutgoing ? 'outgoing' : 'incoming';
  const description = types.find((type) => type.code === codeType)?.description;
  return (
    <div style={{ minWidth: 100 }}>
      <img src={isOutgoing ? icOutgoing : icIncoming} alt={description} style={{ marginRight: 10 }} />
      {description}
    </div>
  );
};
interface Cell {
  cell: any;
}
const Priority = ({ cell }: Cell) => {
  const {
    priority: { code, description },
  } = cell;
  switch (code) {
    case 'low':
      return <span style={{ color: '#37B74B' }}>{description}</span>;
    case 'medium':
      return <span style={{ color: '#E8CA61' }}>{description}</span>;
    case 'high':
      return <span style={{ color: '#EC4E4E' }}>{description}</span>;
  }
  return <></>;
};

const Status = ({ cell }: Cell) => {
  const {
    status: { code, description },
  } = cell;
  const style = {
    width: 84,
    padding: 5,
    borderRadius: 8,
  };
  switch (code) {
    case 'closed':
      return (
        <div style={{ ...style, color: '#37B74B', backgroundColor: '#D7F1DB', textAlign: 'center' }}>{description}</div>
      );
    case 'opened':
      return (
        <div style={{ ...style, color: '#417CD6', backgroundColor: 'rgba(53, 110, 222, 0.2)', textAlign: 'center' }}>
          {description}
        </div>
      );
    case 'canceled':
      return (
        <div style={{ ...style, color: '#AAAAAA', backgroundColor: '#ebebeb', textAlign: 'center' }}>{description}</div>
      );
  }
  return <></>;
};

const DueDate = ({ cell }: Cell) => {
  const { t } = useTranslation();
  const { dueDate } = cell;

  const content = (
    <div style={{ width: '200px' }}>
      <p>{t('tickets.table.columns.duedate.popover.attention.content')}</p>
    </div>
  );

  const title = (
    <span style={{ color: '#EB3B3B', fontSize: '18px' }}>
      {t('tickets.table.columns.duedate.popover.attention.title')}
    </span>
  );

  const duedate = moment(dueDate);
  const today = moment();
  if (duedate.isBefore(today)) {
    return (
      <div style={{ minWidth: 100 }}>
        <Popover content={content} title={title} placement="bottom" trigger="hover">
          <span style={{ color: '#EB3B3B' }}>{duedate.format('DD.MM.YYYY')}</span>
          <img src={icAttention} alt="" style={{ marginLeft: 5 }} />
        </Popover>
      </div>
    );
  }
  return <>{`${duedate.format('DD.MM.YYYY')}`}</>;
};

interface CountAnswers {
  cell: any;
  userId: number;
}
const CountAnswers = ({ cell, userId }: CountAnswers) => {
  const answerMsgs = cell.messages.filter((msg: any) => !msg.isSystem && msg.createdUser.id !== userId);
  const notReadMsgs = answerMsgs.filter((msg: any) => !msg.isRead);
  if (notReadMsgs.length > 0) {
    const maxWidth = notReadMsgs.length > 2 ? '45px' : notReadMsgs.length > 1 ? '37px' : '30px';
    return (
      <div
        style={{
          color: 'white',
          backgroundColor: '#d99396',
          padding: 5,
          borderRadius: '20px',
          maxWidth,
          textAlign: 'center',
        }}>
        +{notReadMsgs.length}
      </div>
    );
  }
  return <>{`${answerMsgs.length || '-'}`}</>;
};

export const getColumns = ({ getTickets, option, t, types, userId, isBroker }: TicketsColumnsModel) => {
  const cells = [
    {
      title: () => (
        <span className="budget-table-tr" style={{ marginLeft: '8px' }}>
          {t('tickets.table.columns.id')}
        </span>
      ),
      width: '60px',
      key: 'id',
      render: (cell: any) => `#${cell.id}`,
      sorter: (a: any, b: any) => (b.id > a.id ? 1 : -1),
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.type')}</span>,
      width: '106px',
      key: 'type',
      render: (cell: any) => <Type cell={cell} userId={userId} types={types} />,
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.subject')}</span>,
      dataIndex: 'subject',
      width: '300px',
      key: 'subject',
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.acquirer')}</span>,
      width: '170px',
      key: 'acquirer',
      render: (cell: any) =>
        isBroker && cell.createdUser.id === userId
          ? `${cell.recipient.firstName} ${cell.recipient.lastName}`
          : `${cell.createdUser.firstName} ${cell.createdUser.lastName}`,
      sorter: (a: any, b: any) => {
        const first =
          isBroker && a.createdUser.id === userId
            ? `${a.recipient.firstName} ${a.recipient.lastName}`
            : `${a.createdUser.firstName} ${a.createdUser.lastName}`;
        const second =
          isBroker && b.createdUser.id === userId
            ? `${b.recipient.firstName} ${b.recipient.lastName}`
            : `${b.createdUser.firstName} ${b.createdUser.lastName}`;
        return first > second ? 1 : -1;
      },
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.project')}</span>,
      key: 'project',
      width: '100px',
      render: (cell: any) => `${cell.project.title}`,
      sorter: (a: any, b: any) => (a.project.title > b.project.title ? 1 : -1),
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.priority')}</span>,
      key: 'priority',
      width: '80px',
      render: (cell: any) => <Priority cell={cell} />,
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.status')}</span>,
      width: '92px',
      key: 'status',
      render: (cell: any) => <Status cell={cell} />,
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.duedate')}</span>,
      width: '100px',
      key: 'dueDate',
      // render: (cell: any) => (`${moment(cell.dueDate).format('DD.MM.YYYY')}`),
      render: (cell: any) => <DueDate cell={cell} />,
      sorter: (a: any, b: any) => (a.dueDate > b.dueDate ? 1 : -1),
    },
    {
      title: () => <span className="budget-table-tr">{t('tickets.table.columns.answer')}</span>,
      width: '70px',
      key: 'answer',
      render: (cell: any) => <CountAnswers cell={cell} userId={userId} />,
    },
  ];
  // Remove redundant cell for an acquirer
  if (!isBroker) cells.splice(3, 2);
  return cells;
};
