import { Button, Form, Input, notification, Select, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';
import css from '../../../assets/styles/faq.module.css';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import { QuestionModel, TopicModel, TopicsModel } from '../../../models/FAQModel';
import FAQService from '../../../services/FAQService';
import i18n from '../../../utils/i18n';
import TopicForm from './components/TopicsComponents/TopicForm';
import SunEditor from 'suneditor-react';

const service = new FAQService();

const { TabPane } = Tabs;
const { Option } = Select;

const ManageFAQModify = () => {
  const { id }: { id: string } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [question, setQuestion] = useState<QuestionModel | null>(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [topics, setTopics] = useState<TopicsModel>([]);
  const [isPublishedEn, setIsPublishedEn] = useState(false);
  const [isPublishedFr, setIsPublishedFr] = useState(false);
  const [isOpenTopicForm, setIsOpenTopicForm] = useState(false);

  useEffect(() => {
    getTopics();
    if (id) {
      setFetchingData(true);
      setIsLoading(true);
      service
        .getFAQById(+id)
        .then((value: QuestionModel) => {
          form.setFieldsValue({
            questionFr: value.questionFr,
            questionEn: value.questionEn,
            answerEn: value.answerEn,
            answerFr: value.answerFr,
            topic: value.topic.id,
          });
          setQuestion(value);
          setIsPublishedEn(value.isPublishedEn);
          setIsPublishedFr(value.isPublishedFr);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          setIsLoading(false);
        });
    }
  }, []);

  const getTopics = () => {
    service
      .getTopics({
        filter: {
          'type/code': {
            eq: null,
          },
        },
      })
      .then((value: TopicsModel) => setTopics(value))
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      });
  };

  const onCancel = () => {
    history.push('/manage_faq');
  };

  const onSubmit = (value: any) => {
    setFetchingData(true);
    const body = {
      answerEn: value.answerEn || question?.answerEn,
      answerFr: value.answerFr || question?.answerFr,
      questionEn: value.questionEn || question?.questionEn,
      questionFr: value.questionFr || question?.questionFr,
      topic: value.topic,
      isPublishedEn,
      isPublishedFr,
    };

    if (id) {
      service
        .updateFAQ({ ...body, id: Number(id) })
        .then(() => {
          notification.success({
            message: t('manage.success.updated.question'),
          });
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          history.push('/manage_faq');
        });
    } else {
      service
        .createFAQ(body)
        .then(() => {
          notification.success({
            message: t('manage.success.created.question'),
          });
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
          history.push('/manage_faq');
        });
    }
  };

  const closeTopicForm = () => {
    setIsOpenTopicForm(false);
    getTopics();
  };

  const selectTopic = (value: string) => {
    if (value === 'add') {
      setIsOpenTopicForm(true);
      form.setFieldsValue({
        topic: question ? question.topic.id : null,
      });
    }
  };

  return (
    <div className={css['manage-edit-container']}>
      {isOpenTopicForm && <TopicForm isOpen={isOpenTopicForm} setClose={closeTopicForm} />}
      <Spin spinning={fetchingData}>
        <Form autoComplete="off" onFinish={onSubmit} form={form}>
          <div className={css['manage-edit-control']}>
            <Button
              onClick={() => onCancel()}
              className={`common-blue-border-btn common-secondary-btn`}
              style={
                useLessThen801()
                  ? { width: '100%', marginRight: '0.5rem', height: '42px' }
                  : { marginRight: '0.5rem', height: '42px' }
              }>
              {t('common.return.btn')}
            </Button>
            <Button
              className={`common-blue-btn common-primary-btn`}
              htmlType="submit"
              style={
                useLessThen801()
                  ? { width: '100%', marginLeft: '0.5rem', height: '42px' }
                  : { width: 'auto', height: '42px' }
              }>
              {t('common.btn.save')}
            </Button>
          </div>
          <Tabs defaultActiveKey="1" className="faq-modify-tabs">
            <TabPane
              tab={
                <span>
                  EN:
                  <span className={css[isPublishedEn ? 'published-status' : 'draft-status']}>{` (${
                    isPublishedEn ? t('manage.faq.columns.statuses.published') : t('manage.faq.columns.statuses.draft')
                  })`}</span>
                </span>
              }
              key="1">
              <div className={css['edit-container']}>
                <div className={css['inputs-container']}>
                  <Form.Item
                    colon={false}
                    name="topic"
                    label={<span style={{ color: '#778dac' }}>{`${t('manage.faq.columns.topic')} *`}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    labelCol={{ span: 24 }}>
                    <Select
                      style={{ width: '100%' }}
                      className="faq-modify-select common-animation-primary"
                      placeholder={t('manage.faq.select.topic')}
                      onSelect={selectTopic}
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }>
                      {topics.length > 0 &&
                        topics
                          .sort((a: TopicModel, b: TopicModel) =>
                            a.topic.toLowerCase().localeCompare(b.topic.toLowerCase()),
                          )
                          .map((topic: TopicModel) => (
                            <Option value={topic.id} key={topic.id} className="budget-control-option">
                              <span style={{ fontSize: '14px' }}>
                                {i18n.language === 'fr-FR' ? topic.topicFr : topic.topicEn}
                              </span>
                            </Option>
                          ))}
                      <Option value="add">
                        <span style={{ color: '#e21315' }}>{t('manage.faq.add.new.topic.select')}</span>
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    name="questionEn"
                    label={<span style={{ color: '#778dac' }}>{`${t('manage.faq.columns.question')} *`}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    labelCol={{ span: 24 }}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    name="answerEn"
                    className="customSunEditor"
                    label={<span style={{ color: '#778dac' }}>{`${t('manage.faq.answer')} *`}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    labelCol={{ span: 24 }}>
                    {!isLoading && (
                      <SunEditor
                        defaultValue={question?.answerEn || ''}
                        setDefaultStyle="font-family: Arial"
                        setOptions={{
                          buttonList: [
                            ['undo', 'redo'],
                            ['fontSize', 'formatBlock'],
                            ['outdent', 'indent'],
                            ['bold', 'underline', 'italic', 'strike', 'list', 'link'],
                            ['fontColor', 'hiliteColor'],
                            ['removeFormat'],
                            ['fullScreen'],
                          ],
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className={css['status-container']}>
                  <div>
                    <span>{t('budget.control.filter.status.title')}:</span>
                    <span className={css[isPublishedEn ? 'published-status' : 'draft-status']}>{` ${
                      isPublishedEn
                        ? t('manage.faq.columns.statuses.published')
                        : t('manage.faq.columns.statuses.draft')
                    }`}</span>
                  </div>
                  <Button onClick={() => setIsPublishedEn(!isPublishedEn)} className={css['set-status-btn']}>
                    <span style={{ color: '#fff', fontWeight: 600 }}>
                      {isPublishedEn ? t('manage.faq.unpublish.btn') : t('manage.faq.publish.btn')}
                    </span>
                  </Button>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={
                <span>
                  FR:
                  <span className={css[isPublishedFr ? 'published-status' : 'draft-status']}>{` (${
                    isPublishedFr ? t('manage.faq.columns.statuses.published') : t('manage.faq.columns.statuses.draft')
                  })`}</span>
                </span>
              }
              key="2">
              <div className={css['edit-container']}>
                <div className={css['inputs-container']}>
                  <Form.Item
                    colon={false}
                    name="topic"
                    label={<span style={{ color: '#778dac' }}>{`${t('manage.faq.columns.topic')} *`}</span>}
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    labelCol={{ span: 24 }}>
                    <Select
                      style={{ width: '100%' }}
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }
                      placeholder={t('manage.faq.select.topic')}
                      onSelect={selectTopic}
                      className="faq-modify-select common-animation-primary">
                      {topics.length > 0 &&
                        topics
                          .sort((a: TopicModel, b: TopicModel) =>
                            a.topic.toLowerCase().localeCompare(b.topic.toLowerCase()),
                          )
                          .map((topic: TopicModel) => (
                            <Option value={topic.id} key={topic.id} className="budget-control-option">
                              <span style={{ fontSize: '14px' }}>
                                {i18n.language === 'fr-FR' ? topic.topicFr : topic.topicEn}
                              </span>
                            </Option>
                          ))}
                      <Option value="add">
                        <span style={{ color: '#e21315' }}>{t('manage.faq.add.new.topic.select')}</span>
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{`${t('manage.faq.columns.question')} *`}</span>}
                    name="questionFr"
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    labelCol={{ span: 24 }}>
                    <Input className="consumer-form-input common-animation-primary" />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label={<span style={{ color: '#778dac' }}>{`${t('manage.faq.answer')} *`}</span>}
                    name="answerFr"
                    className="customSunEditor"
                    rules={[{ required: true, message: t('common.validation.required') }]}
                    labelCol={{ span: 24 }}>
                    {!isLoading && (
                      <SunEditor
                        defaultValue={question?.answerFr || ''}
                        setDefaultStyle="font-family: Arial"
                        setOptions={{
                          buttonList: [
                            ['undo', 'redo'],
                            ['fontSize', 'formatBlock'],
                            ['outdent', 'indent'],
                            ['bold', 'underline', 'italic', 'strike', 'list', 'link'],
                            ['fontColor', 'hiliteColor'],
                            ['removeFormat'],
                            ['fullScreen'],
                          ],
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className={css['status-container']}>
                  <div>
                    <span>{t('budget.control.filter.status.title')}:</span>
                    <span className={css[isPublishedFr ? 'published-status' : 'draft-status']}>
                      {` ${
                        isPublishedFr
                          ? t('manage.faq.columns.statuses.published')
                          : t('manage.faq.columns.statuses.draft')
                      }`}
                    </span>
                  </div>
                  <Button onClick={() => setIsPublishedFr(!isPublishedFr)} className={css['set-status-btn']}>
                    <span style={{ color: '#fff', fontWeight: 600 }}>
                      {isPublishedFr ? t('manage.faq.unpublish.btn') : t('manage.faq.publish.btn')}
                    </span>
                  </Button>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Form>
      </Spin>
    </div>
  );
};

export default ManageFAQModify;
