import { Button, Dropdown, Menu, Popover } from 'antd';
import moment from 'moment';
import React from 'react';
import deleteIcon from '../../../assets/images/ic-delete-dark-gray.svg';
import editIcon from '../../../assets/images/ic-edit-dark.svg';
import css from '../../../assets/styles/budget.module.css';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import moreIcon from '../../../assets/images/ic-more.svg';
import icAttention from '../../../assets/images/ic-attention.svg';
import { CheckOutlined, ClockCircleOutlined, FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons';
import { noPDFFormat } from '../../../helpers/noPDFFormat';
import { authDownloadFile } from '../../../helpers/authFileTools';
import { convertToPrice } from '../../../helpers/convertToPrice';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import { TFunction } from 'react-i18next';
import { IAmendmentModel } from '../../../models/AmendmentModel';
import { IContractModel } from '../../../models/DocumentsModel';

interface IColumns {
  t: TFunction<'translation'>;
  isConsumer: boolean;
  consumerId: number;
  openPreview: (id?: number) => void;
  setOpenDelete: (id: number, amendmentNumber: string) => void;
  setOpenStatus: (id?: number) => void;
  openSigningConfirm: (id?: number, signInfo?: string) => void;
  authDownloadFile: (file: string, id: number, fileName?: string) => void;
  setOpen: (id: number) => void;
  openApprove: (id: number) => void;
  openHistory: (id: number, title: string, item?: IAmendmentModel) => void;
}

export const getColumns = ({
  t,
  isConsumer,
  consumerId,
  openPreview,
  setOpenDelete,
  setOpenStatus,
  openSigningConfirm,
  setOpen,
  authDownloadFile,
  openApprove,
  openHistory,
}: IColumns) => [
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.name')}</span>,
    key: 'number',
    width: '22%',
    render: (cell: IAmendmentModel) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '22%',
    render: (cell: IAmendmentModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum.total')}</span>,
    key: 'sumWithVat',
    sorter: true,
    width: '16%',
    render: (cell: IAmendmentModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        cell.deltaWithVat !== null ? convertToPrice(cell.deltaWithVat) : '-'
      }`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.form.deadline')}</span>,
    key: 'deadline',
    sorter: true,
    width: '14%',
    render: (cell: IAmendmentModel) => (!!cell.deadline ? moment(cell.deadline).format('DD.MM.YYYY') : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'statusV2',
    width: '20%',
    render: (cell: IAmendmentModel) => (
      <span style={{ color: setTextColorByStatus(cell.statusV2.code) }}>{cell.statusV2.description}</span>
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    fixed: 'right' as any,
    width: isConsumer ? 330 : 110,
    render: (cell: IAmendmentModel) => {
      const isCanSign =
        cell.signatories &&
        cell.signatories.findIndex(
          (signatory) =>
            signatory.consumerId === consumerId &&
            (cell.step?.code === 'agent_sign_open' ||
              cell.step?.code === 'agent_sign_close' ||
              cell.step?.code === 'client_sign') &&
            signatory.stepCode === cell.step?.code &&
            !signatory.isDone,
        ) >= 0;

      const isCanApprove =
        cell.signatories &&
        cell.signatories.findIndex(
          (signatory) =>
            signatory.consumerId === consumerId &&
            (cell.step?.code === 'agent_approve_open' ||
              cell.step?.code === 'agent_approve_close' ||
              cell.step?.code === 'client_approve') &&
            signatory.stepCode === cell.step?.code &&
            !signatory.isDone,
        ) >= 0;

      const isDisabled = ['rejected'].includes(cell.statusV2.code)
        ? true
        : cell.signatories.find((item) => item.stepCode === cell.step?.code && item.consumerId === consumerId)
            ?.isDone ?? true;

      const menu = (
        <Menu className="update-menu-container" style={{ width: '180px' }}>
          <Menu.Item key="edit">
            <div
              onClick={() => (cell.quotes.length > 0 ? setOpenStatus(cell.id) : setOpen(cell.id))}
              className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
            </div>
          </Menu.Item>
          {isCanSign && !isDisabled && (
            <Menu.Item key="sign">
              <div
                className={css['update-menu-item']}
                onClick={() => openSigningConfirm(cell.id, cell.comment || cell.signInfo || undefined)}>
                <FileDoneOutlined />
                <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                  {t('common.title.sign')}
                </span>
              </div>
            </Menu.Item>
          )}
          {isCanApprove && !isDisabled && (
            <Menu.Item key="approve">
              <div className={css['update-menu-item']} onClick={() => openApprove(cell.id)}>
                <CheckOutlined style={{ color: '#e21315', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.approve.reject')}</span>
              </div>
            </Menu.Item>
          )}
          {cell.file && (
            <Menu.Item key="preview">
              <div
                className={css['update-menu-item']}
                onClick={() => {
                  cell.file.slice(-3) === 'pdf' ? openPreview(cell.id) : noPDFFormat(t);
                }}>
                <FileSearchOutlined />
                <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                  {t('common.title.preview')}
                </span>
              </div>
            </Menu.Item>
          )}
          <Menu.Item key="delete">
            <div onClick={() => setOpenDelete(cell.id, cell.number)} className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
            </div>
          </Menu.Item>
          {/* <Menu.Item key="archive">
            <div onClick={() => openArchiveConfirm(cell.id)} className={css['update-menu-item']}>
              <img src={archiveIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.archive')}</span>
            </div>
          </Menu.Item> */}
          {cell.file && (
            <Menu.Item key="upload">
              <div
                className={css['update-menu-item']}
                onClick={() => authDownloadFile(cell.file, cell.id, cell.fileName)}>
                <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
              </div>
            </Menu.Item>
          )}
        </Menu>
      );
      const content = (
        <div style={{ width: '200px' }}>
          {!cell.file && <p>{t('common.popover.attention.file.content')}</p>}
          {moment(cell.deadline).isBefore(moment()) &&
            !['signed', 'rejected', 'done', 'canceled'].includes(cell.statusV2?.code) && (
              <p>{t('common.popover.attention.deadline')}</p>
            )}
        </div>
      );
      const title = (
        <div className="common-flex-column-style">
          {!cell.file && (
            <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
          )}
          {moment(cell.deadline).isBefore(moment()) &&
            !['signed', 'rejected', 'done', 'canceled'].includes(cell.statusV2?.code) && (
              <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.deadline.title')}</span>
            )}
        </div>
      );

      return (
        <>
          {isConsumer && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {cell.file && (
                <div
                  className={`${css['update-menu-item']} common-animation-secondary`}
                  onClick={() => authDownloadFile(cell.file, cell.id, cell.fileName)}
                  style={{ backgroundColor: '#eef0f4', borderRadius: '6px', cursor: 'pointer', padding: '9px' }}>
                  <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
                  <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
                </div>
              )}
              {cell.file && (
                <Button
                  className={`common-blue-border-btn common-secondary-btn`}
                  onClick={() => {
                    cell.file.slice(-3) === 'pdf' ? openPreview(cell.id) : noPDFFormat(t);
                  }}
                  style={{ marginLeft: '1rem' }}>
                  <span style={{ color: '#e21315' }}>{t('common.preview.btn')}</span>
                </Button>
              )}
              {cell.file && (
                <Button
                  disabled={isDisabled}
                  className={`${css['add-invoce-btn']} common-primary-btn`}
                  style={{ marginLeft: '1rem' }}
                  onClick={() =>
                    isCanApprove
                      ? openApprove(cell.id)
                      : openSigningConfirm(cell.id, cell.comment || cell.signInfo || undefined)
                  }>
                  <span style={{ color: '#fff' }}>
                    {isCanApprove ? t('common.title.approve.reject') : t('common.title.sign.reject')}
                  </span>
                </Button>
              )}
              {(!cell.file ||
                (moment(cell.deadline).isBefore(moment()) &&
                  !['signed', 'rejected', 'done', 'canceled'].includes(cell.statusV2?.code))) &&
                isConsumer && (
                  <Popover content={content} title={title} placement="bottom" trigger="hover">
                    <img src={icAttention} alt="" style={{ marginLeft: 10 }} />
                  </Popover>
                )}
            </div>
          )}
          {!isConsumer && (
            <div style={{ display: 'flex' }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className={css['update-dropdown-btn']}>
                  <img src={moreIcon} alt="" height={15} width={15} />
                </Button>
              </Dropdown>
              <ClockCircleOutlined
                style={{ marginLeft: '1rem', marginTop: '3px' }}
                onClick={() =>
                  cell.flow ? openHistory(cell.id, cell.number, cell) : openHistory(cell.id, cell.number)
                }
              />
              {(!cell.file ||
                (moment(cell.deadline).isBefore(moment()) &&
                  !['signed', 'rejected', 'done', 'canceled'].includes(cell.statusV2?.code))) && (
                <Popover content={content} title={title} placement="bottom" trigger="hover">
                  <img src={icAttention} alt="" style={{ marginLeft: 10 }} />
                </Popover>
              )}
            </div>
          )}
        </>
      );
    },
  },
];

interface IColumnsDoc {
  t: TFunction<'translation'>;
  isConsumer: boolean;
  openPreview: (id?: number) => void;
  setOpenDelete: (id: number, invoiceNumber: string) => void;
  setOpenDoc: (id: number) => void;
  userId: number;
  openSigningConfirm: (id?: number, signInfo?: string) => void;
  openApprove: (id: number) => void;
  openHistory: (id: number, title: string, item?: IContractModel) => void;
}

export const getDocumentsColumns = ({
  t,
  userId,
  openSigningConfirm,
  isConsumer,
  setOpenDoc,
  setOpenDelete,
  openPreview,
  openApprove,
  openHistory,
}: IColumnsDoc) => [
  {
    title: () => <span className="documents-table-tr">{t('documents.table.column.name')}</span>,
    key: 'name',
    width: '30%',
    render: (cell: IContractModel) => <span>{cell.title}</span>,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '30%',
    render: (cell: IContractModel) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum.total')}</span>,
    key: 'sumWithVat',
    width: '20%',
    render: (cell: IContractModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        cell.sumWithVat !== null ? convertToPrice(cell.sumWithVat) : '-'
      }`,
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.status')}</span>,
    key: 'status',
    width: '20%',
    render: (cell: IContractModel) => (
      <span style={{ color: setTextColorByStatus(cell.statusV2?.code) }}>{cell.statusV2.description}</span>
    ),
  },
  {
    title: () => <span className="documents-table-tr">{t('documents.table.action')}</span>,
    key: 'action',
    width: isConsumer ? 330 : 110,
    fixed: 'right' as any,
    render: (cell: IContractModel) => {
      const isCanSign =
        cell.signatories &&
        cell.signatories.findIndex(
          (signatory) =>
            signatory.consumerId === userId &&
            (cell.step?.code === 'agent_sign_open' ||
              cell.step?.code === 'agent_sign_close' ||
              cell.step?.code === 'client_sign') &&
            signatory.stepCode === cell.step?.code &&
            !signatory.isDone,
        ) >= 0;

      const isCanApprove =
        cell.signatories &&
        cell.signatories.findIndex(
          (signatory) =>
            signatory.consumerId === userId &&
            (cell.step?.code === 'agent_approve_open' ||
              cell.step?.code === 'agent_approve_close' ||
              cell.step?.code === 'client_approve') &&
            signatory.stepCode === cell.step?.code &&
            !signatory.isDone,
        ) >= 0;

      const isDisabled = ['rejected'].includes(cell.statusV2.code)
        ? true
        : cell.signatories.find((item) => item.stepCode === cell.step?.code && item.consumerId === userId)?.isDone ??
          true;

      const menu = (
        <Menu className="update-menu-container" style={{ width: '180px' }}>
          <Menu.Item key="edit">
            <div onClick={() => setOpenDoc(cell.id)} className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={editIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.edit')}</span>
            </div>
          </Menu.Item>
          {cell.hasSignature && isCanSign && !isDisabled && (
            <Menu.Item key="sign">
              <div
                className={css['update-menu-item']}
                onClick={() => openSigningConfirm(cell.id, cell.comment || cell.signInfo || undefined)}>
                <FileDoneOutlined />
                <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                  {t('common.title.sign')}
                </span>
              </div>
            </Menu.Item>
          )}
          {isCanApprove && !isDisabled && (
            <Menu.Item key="approve">
              <div className={css['update-menu-item']} onClick={() => openApprove(cell.id)}>
                <CheckOutlined style={{ color: '#e21315', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.approve.reject')}</span>
              </div>
            </Menu.Item>
          )}
          {cell.file && (
            <Menu.Item key="preview">
              <div
                className={css['update-menu-item']}
                onClick={() => {
                  cell.file.slice(-3) === 'pdf' ? openPreview(cell.id) : noPDFFormat(t);
                }}>
                <FileSearchOutlined />
                <span style={{ color: '#00122d', fontWeight: 600, marginLeft: '0.6rem' }}>
                  {t('common.title.preview')}
                </span>
              </div>
            </Menu.Item>
          )}
          <Menu.Item key="delete">
            <div onClick={() => setOpenDelete(cell.id, cell.title)} className={css['update-menu-item']}>
              <Button
                style={{
                  border: 'none',
                  backgroundColor: 'inherit',
                  boxShadow: 'none',
                  padding: 0,
                  marginRight: '0.5rem',
                }}>
                <img src={deleteIcon} alt="" style={{ height: '15px', width: '15px' }} />
              </Button>
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.delete')}</span>
            </div>
          </Menu.Item>
          {/* <Menu.Item key="archive">
            <div onClick={() => openArchiveConfirm(cell.id)} className={css['update-menu-item']}>
              <img src={archiveIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('common.title.archive')}</span>
            </div>
          </Menu.Item> */}
          {cell.file && (
            <Menu.Item key="upload">
              <div className={css['update-menu-item']} onClick={() => authDownloadFile(cell.file, cell.fileName)}>
                <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
                <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
              </div>
            </Menu.Item>
          )}
        </Menu>
      );
      const content = (
        <div style={{ width: '200px' }}>
          <p>{t('common.popover.attention.file.content')}</p>
        </div>
      );
      const title = (
        <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
      );
      return (
        <>
          <div style={{ display: 'flex', backgroundColor: 'inherit' }}>
            {isConsumer && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  className={`${css['update-menu-item']} common-animation-secondary`}
                  onClick={() => authDownloadFile(cell.file, cell.fileName)}
                  style={{ backgroundColor: '#eef0f4', borderRadius: '6px', cursor: 'pointer', padding: '9px' }}>
                  <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
                  <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
                </div>
                <Button
                  className={`common-blue-border-btn common-secondary-btn`}
                  onClick={() => {
                    cell.file.slice(-3) === 'pdf' ? openPreview(cell.id) : noPDFFormat(t);
                  }}
                  style={{ marginLeft: '1rem' }}>
                  <span style={{ color: '#e21315' }}>{t('common.preview.btn')}</span>
                </Button>
                <Button
                  disabled={isDisabled}
                  className={`${css['add-invoce-btn']} common-primary-btn`}
                  style={{ marginLeft: '1rem' }}
                  onClick={() =>
                    isCanApprove
                      ? openApprove(cell.id)
                      : openSigningConfirm(cell.id, cell.comment || cell.signInfo || undefined)
                  }>
                  <span style={{ color: '#fff' }}>
                    {isCanApprove ? t('common.title.approve.reject') : t('common.title.sign.reject')}
                  </span>
                </Button>
              </div>
            )}
          </div>
          {!isConsumer && (
            <div style={{ display: 'flex' }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className={css['update-dropdown-btn']}>
                  <img src={moreIcon} alt="" height={15} width={15} />
                </Button>
              </Dropdown>
              <ClockCircleOutlined
                style={{ marginLeft: '1rem', marginTop: '3px' }}
                onClick={() => (cell.flow ? openHistory(cell.id, cell.title, cell) : openHistory(cell.id, cell.title))}
              />
              {!cell.file && (
                <Popover content={content} title={title} placement="bottom" trigger="hover">
                  <img src={icAttention} alt="" style={{ marginLeft: 10 }} />
                </Popover>
              )}
            </div>
          )}
        </>
      );
    },
  },
];
