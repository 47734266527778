import { Button, Form, FormInstance, Radio, Upload, notification } from 'antd';
import React, { useContext, useState } from 'react';
import css from '../../../../assets/styles/consumers.module.css';
import { useTranslation } from 'react-i18next';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import ConsumersService from '../../../../services/ConsumersService';
import { IUserExport } from '../../../../models/ConsumerModel';
import { IAppContext } from '../../../../typings/IApp';
import { AppContext } from '../../../../contexts/AppContextProvider';

interface IProps {
  form: FormInstance;
  setStage: (n: number) => void;
  setUsers: (users: IUserExport[]) => void;
  setProjectId: (n: number) => void;
  setFetchingData: (b: boolean) => void;
}

const consumersService = new ConsumersService();

const ImportConsumersFirstStage = ({ form, setFetchingData, setStage, setProjectId, setUsers }: IProps) => {
  const { t } = useTranslation();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isHaveFile, setIsHaveFile] = useState(true);

  const handleFile = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
    setIsHaveFile(true);
  };

  const downloadExampleImport = () => {
    const projectId = form.getFieldValue('projectId');
    if (!projectId) {
      return notification.warning({ message: t('properties.notification.select.project') });
    }
    setFetchingData(true);
    const project = user.projects.find((p) => p.id === projectId);
    consumersService
      .exampleImportConsumers(project!.key)
      .then((res) => {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([res]));
        a.href = url;
        a.download = `${project!.title}.csv`;
        a.click();
      })
      .catch((e) => {
        notification.error({
          message: e.message || t('common.error.internalServerError'),
        });
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  const onSubmit = (value: any) => {
    if (fileList.length < 1) {
      setIsHaveFile(false);
      return;
    }

    setFetchingData(true);

    setProjectId(value.projectId);
    const projectKey = user.projects.find((p) => p.id === value.projectId)?.key || '';
    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }

    filesData.append('projectId', value.projectId);

    if (filesData.getAll('file').length > 0) {
      consumersService
        .importConsumersParse(filesData, projectKey)
        .then((res: IUserExport[]) => {
          setUsers(res);
          setStage(2);
        })
        .catch((e) => {
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setFetchingData(false);
        });
    }
  };
  return (
    <Form autoComplete="off" form={form} onFinish={onSubmit}>
      <div className="consumers-import-upload-container">
        <Form.Item
          colon={false}
          labelCol={{ span: 24 }}
          className="consumers-import-upload"
          name="file"
          label="File to import*">
          <Upload
            fileList={fileList}
            maxCount={1}
            style={{ width: '100%' }}
            className="consumers-import-upload-item"
            beforeUpload={() => false}
            onChange={handleFile}
            accept=".csv">
            {fileList.length < 1 && (
              <div className={css['upload-item-consumers']}>
                <div
                  className={css['upload-img']}
                  style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
                <span style={{ color: '#e21315' }}>{t('consumers.modal.upload.file.btn')}</span>
              </div>
            )}
          </Upload>
          {!isHaveFile && <span style={{ color: '#ff4d4f' }}>{t('common.validation.required')}</span>}
        </Form.Item>
        <div>{t('consumers.import.warning2')}</div>
        <Button type="link" onClick={downloadExampleImport}>
          {t('properties.export.btn')}
        </Button>
      </div>
      <Form.Item
        colon={false}
        name="projectId"
        style={{ padding: '0.5rem 3rem', height: '20rem', overflowY: 'auto' }}
        rules={[{ required: true, message: t('common.validation.required') }]}
        labelCol={{ span: 24 }}
        label="Select a project to upload clients to*">
        <Radio.Group style={{ display: 'flex', flexDirection: 'column' }} className="consumers-import-checkbox-group">
          {user.projects &&
            user.projects
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0,
              )
              .map((project) => (
                <Radio key={project.id} value={project.id} className="consumers-import-checkbox">
                  {project.title}
                </Radio>
              ))}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

export default ImportConsumersFirstStage;
