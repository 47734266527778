import React from 'react';
import { Form, Button, Input, Card, Row, Col, notification } from 'antd';
import logo from '../../assets/images/logo_dark.svg';
import logo_big from '../../assets/images/login_big.jpg';
import css from './../../assets/styles/changePassword.module.css';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import RegisterPageServices from '../../services/RegisterPageService';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const service = new RegisterPageServices();

const ConfirmNewPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const currentToken = location.search.slice(1);

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(t('change.password.invalid'));
    } else {
      callback();
    }
  };

  const handleSubmit = (values: any) => {
    service
      .confirmNewPass({ password: values.newPassword, confirmPassword: values.confirmNewPassword }, currentToken)
      .then(() => {
        notification.success({
          message: t('common.password.changed'),
        });
        history.push('/login');
      })
      .catch((e: any) => {
        notification.error({
          message: t('common.confirm.pass.error'),
        });
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: '100vh', background: '#f0f2f5' }}
      className={css['changePassword-container']}>
      <Col>
        <div style={{ background: '#fff', width: '351px' }}>
          <img src={logo_big} alt="" className={css['changePassword-img']} />
        </div>
      </Col>
      <Col>
        <Card style={{ width: 400, height: 500, border: 'none' }} className={css['changePassword-body']}>
          <img src={logo} alt="Logo" className={css['logo-changePassword']} />
          <p className={css['changePassword-form-title']}>{t('change.password.title')}</p>
          <Form form={form} onFinish={handleSubmit} className={css['changePassword-form']}>
            <p className={css['changePassword-title']}>{t('change.password.newPassword.placeholder')}</p>
            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: t('change.password.newPassword.validation') },
                {
                  min: 6,
                  message: t('register.password.validation.min'),
                },
                {
                  pattern: /^(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*)$/,
                  message: t('register.password.validation.pattern'),
                },
              ]}>
              <Input.Password
                className="common-animation-primary"
                type="password"
                style={{ borderRadius: '6px' }}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <p className={css['changePassword-title']}>{t('change.password.confirmNewPassword.placeholder')}</p>
            <Form.Item
              name="confirmNewPassword"
              rules={[
                { required: true, message: t('change.password.confirmNewPassword.validation') },
                { validator: compareToFirstPassword },
                {
                  min: 6,
                  message: t('register.password.validation.min'),
                },
                {
                  pattern: /^(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*)$/,
                  message: t('register.password.validation.pattern'),
                },
              ]}>
              <Input.Password
                className="common-animation-primary"
                type="password"
                style={{ borderRadius: '6px' }}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div style={{ height: '100%' }}>
              <Button
                type="primary"
                htmlType="submit"
                className={`${css['changePassword-form-button']} common-primary-btn`}
                block
                shape="round">
                {t('change.password.btn')}
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ConfirmNewPasswordPage;
