import { Carousel, Input, notification, Progress, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import css from '../../../../assets/styles/calendar.module.css';
import doneIcon from '../../../../assets/images/ic-check-mark-gray-copy.svg';
import arrowIcon from '../../../../assets/images/ic-arrow-back.svg';
import houseIcon from '../../../../assets/images/ic-house.svg';
import { useTranslation } from 'react-i18next';
import TimelineService from '../../../../services/TimelineService';
import { StatusesModel, StatusModel, StepModel, StepsModel, UpdatedStep } from '../../../../models/TimelineModel';
import { SettingOutlined } from '@ant-design/icons';
import StepsSettings from './StepsSettings';
import { useLessThen801 } from '../../../../helpers/mediaDetect';

interface ProgressBrokerProps {
  propertyId: number;
}
interface PickerProps {
  step: StepModel;
}
const service = new TimelineService();

const ProgressBrokerComponent = (props: ProgressBrokerProps) => {
  const { propertyId } = props;
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<StatusesModel>([]);
  const [steps, setSteps] = useState<StepsModel>([]);
  const stepsLength = steps && steps.length;
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const stepsDoneLength = steps && steps.filter((step: StepModel) => step.status.code === 'done').length;
  const progressPercent = stepsLength && stepsDoneLength ? ((stepsDoneLength / stepsLength) * 100).toFixed(0) : 0;

  useEffect(() => {
    service
      .getStatuses()
      .then((value: StatusesModel) => setStatuses(value))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    getSteps();
  }, []);

  const getSteps = () => {
    service
      .getTimeline(propertyId)
      .then((value) => setSteps(value))
      .catch((e) => console.log(e));
  };

  const updateStep = (body: UpdatedStep) => {
    service
      .updateTimeline(body)
      .then(() => {
        getSteps();
        notification.success({
          message: t('calendar.timeline.update.notification'),
        });
      })
      .catch((e) => console.log(e));
  };

  const changeStatus = (event: string, step: StepModel) => {
    const updatedStep = {
      id: step.id,
      description: step.description,
      deadline: step.deadline,
      status: { code: event },
    };
    updateStep(updatedStep);
  };

  const changeDateDesc = (dateDesc: string, step: StepModel) => {
    if (dateDesc !== step.dateDescription) {
      const updatedStep = {
        id: step.id,
        dateDescription: dateDesc,
        description: step.description,
        deadline: step.deadline,
        status: {
          code: step.status.code,
        },
      };
      updateStep(updatedStep);
    }
  };

  const stepByStatus = (step: StepModel, index: number) => {
    switch (step.status.code) {
      case 'to_do':
        return StepTodo(step, index);
      case 'in_progress':
        return StepInProgress(step, index);
      default:
        return StepDone(step, index);
    }
  };

  const StepTodo = (step: StepModel, index: number) => {
    return (
      <>
        <div className={css['step-block-todo']}>
          <span className={css['step-date']}>
            <span>{`${t('calendar.step')} ${index + 1}`}</span>
            <span>
              {index + 1 === stepsLength ? (
                <img src={houseIcon} alt="" className={css['house-icon']} />
              ) : (
                <span className={css['arrow-icon-todo-container']}>
                  <img src={arrowIcon} alt="" className={css['arrow-icon']} />
                </span>
              )}
            </span>
          </span>
          <span className={css['step-desc']}>{step?.description ?? ''}</span>
          <br />
          <Picker step={step} />
        </div>
        <div className={css['step-block-hover']}>
          <span>{step.description}</span>
        </div>
      </>
    );
  };

  const StepInProgress = (step: StepModel, index: number) => {
    return (
      <>
        <div className={css['step-block-current']}>
          <span className={css['step-date']}>
            <span style={{ color: '#e21315' }}>{`${t('calendar.step')} ${index + 1}`}</span>
            <span className={css['arrow-icon-progress-container']}>
              <img src={arrowIcon} alt="" className={css['arrow-icon']} />
            </span>
          </span>
          <span className={css['step-desc']}>{step?.description ?? ''}</span>
          <br />
          <Picker step={step} />
        </div>
        <div className={css['step-block-hover']}>
          <span>{step.description}</span>
        </div>
      </>
    );
  };

  const StepDone = (step: StepModel, index: number) => {
    return (
      <>
        <div className={css['step-block']}>
          <span className={css['step-date']}>
            <span style={{ color: '#778dac' }}>{`${t('calendar.step')} ${index + 1}`}</span>
            <span>
              <img src={doneIcon} alt="" className={css['done-icon']} />
            </span>
          </span>
          <span className={css['step-desc']}>{step?.description ?? ''}</span>
          <br />
          <Picker step={step} />
        </div>
        <div className={css['step-block-hover']}>
          <span>{step.description}</span>
        </div>
      </>
    );
  };

  const Picker = (props: PickerProps) => {
    const { step } = props;

    const [dateDesc, setDateDesc] = useState<string>(step.dateDescription);

    return (
      <div className={css['calendar-status-container']}>
        <span className={css['calendar-status-title']}>{t('calendar.status.title').toUpperCase()}</span>
        <Input
          className={`${css['calendar-date-input']} common-animation-primary`}
          placeholder={t('calendar.data.placeholder')}
          type="text"
          value={dateDesc}
          onChange={(e) => setDateDesc(e.target.value)}
          onPressEnter={() => changeDateDesc(dateDesc, step)}
          onBlur={() => changeDateDesc(dateDesc, step)}
        />
        <Select
          className={step.status.code === 'done' ? 'status-select-done' : 'status-select'}
          defaultValue={step.status.code}
          suffixIcon={
            <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
          }
          onSelect={(event) => changeStatus(event, step)}>
          {statuses.map((status: StatusModel) => (
            <Select.Option key={status.code} selected={status.code === step.status.code} value={status.code}>
              {status.description}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  if (useLessThen801()) {
    return (
      <div className={css['progress-container-broker']}>
        <div
          className={css['global-stage-settings']}
          style={{ marginLeft: 0, marginTop: '0.6rem' }}
          onClick={() => setIsOpenSettings(true)}>
          <SettingOutlined style={{ color: '#e21315', marginRight: '0.2rem' }} />
          <span style={{ color: '#e21315' }}>{t('calendar.settings.title')}</span>
        </div>
        <Progress
          percent={progressPercent as number}
          trailColor={'#d99396'}
          strokeColor={'#e21315'}
          className={css['progress-line']}
          style={{ height: '1px' }}
        />
        <Carousel>
          {steps.length > 0 &&
            steps.map((step: StepModel, index: number) => <div key={step.id}>{stepByStatus(step, index)}</div>)}
        </Carousel>
        {isOpenSettings && (
          <StepsSettings isOpen={isOpenSettings} id={propertyId} setIsOpen={setIsOpenSettings} getSteps={getSteps} />
        )}
      </div>
    );
  }

  return (
    <div className={css['progress-container']}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>{`${progressPercent} % ready`}</span>
        <Progress
          percent={progressPercent as number}
          trailColor={'#d99396'}
          showInfo={false}
          strokeColor={'#e21315'}
          style={{ width: '75%' }}
        />
        <div className={css['global-stage-settings']} onClick={() => setIsOpenSettings(true)}>
          <SettingOutlined style={{ color: '#e21315', marginRight: '0.2rem' }} />
          <span style={{ color: '#e21315' }}>{t('calendar.settings.title')}</span>
        </div>
      </div>
      <div className={css['steps-container']}>
        {steps.length > 0 &&
          steps.map((step: StepModel, index: number) => <div key={step.id}>{stepByStatus(step, index)}</div>)}
      </div>
      {isOpenSettings && (
        <StepsSettings isOpen={isOpenSettings} id={propertyId} setIsOpen={setIsOpenSettings} getSteps={getSteps} />
      )}
    </div>
  );
};

export default ProgressBrokerComponent;
