import { Carousel, Progress } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContextProvider';
import { StepModel, StepsModel } from '../../../../models/TimelineModel';
import TimelineService from '../../../../services/TimelineService';
import css from '../../../../assets/styles/dashboard.module.css';
import { useTranslation } from 'react-i18next';
import houseIcon from '../../../../assets/images/ic-house.svg';
import doneIcon from '../../../../assets/images/ic-check-mark-gray-copy.svg';
import arrowIcon from '../../../../assets/images/ic-arrow-back.svg';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import DashboardLastPosts from '../BrokerDashboard/DashboardLastPosts';
import { IAppContext } from '../../../../typings/IApp';

const service = new TimelineService();

interface DeadlineProps {
  step: StepModel;
}

const ConsumerDashboardInfo = () => {
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const [steps, setSteps] = useState<StepsModel>([]);
  const [stepsInProgress, setStepsInProgress] = useState<StepsModel | null>(null);
  const stepsLength = (steps && steps.length) || 0;
  const stepsDoneLength = steps && steps.filter((step: StepModel) => step.status.code === 'done').length;
  const progressPercent =
    stepsLength && stepsDoneLength ? Number(((stepsDoneLength / stepsLength) * 100).toFixed(0)) : 0;

  useEffect(() => {
    getSteps();
  }, [currentProperty]);

  const getSteps = () => {
    service
      .getTimeline(currentProperty.id)
      .then((value) => {
        setSteps(value);
        setStepsInProgress(shownSteps(value));
      })
      .catch((e) => console.log(e));
  };

  const stepByStatus = (step: StepModel, index: number) => {
    switch (step.status.code) {
      case 'to_do':
        return StepTodo(step, index);
      case 'in_progress':
        return StepInProgress(step, index);
      default:
        return StepDone(step, index);
    }
  };

  const StepTodo = (step: StepModel, index: number) => {
    return (
      <div className={css['step-block-todo']}>
        <span className={css['step-date']}>
          <span className={css['step-date-active']}>{`${t('calendar.step')} ${index + 1}`}</span>
          <span>
            {index + 1 === stepsLength ? (
              <img src={houseIcon} alt="" className={css['house-icon']} />
            ) : (
              <span className={css['arrow-icon-todo-container']}>
                <img src={arrowIcon} alt="" className={css['arrow-icon']} />
              </span>
            )}
          </span>
        </span>
        <span>{step?.description ?? ''}</span>
        <br />
        <Deadline step={step} />
      </div>
    );
  };

  const StepInProgress = (step: StepModel, index: number) => {
    return (
      <div className={css['step-block-current']}>
        <span className={css['step-date']}>
          <span className={css['step-index']}>{`${t('calendar.step')} ${index + 1}`}</span>
          <span className={css['arrow-icon-progress-container']}>
            <img src={arrowIcon} alt="" className={css['arrow-icon']} />
          </span>
        </span>
        <span style={{ color: '#e21315' }}>{step?.description ?? ''}</span>
        <br />
        <Deadline step={step} />
      </div>
    );
  };

  const StepDone = (step: StepModel, index: number) => {
    return (
      <div className={css['step-block']}>
        <span className={css['step-date']}>
          <span className={css['step-index']}>{`${t('calendar.step')} ${index + 1}`}</span>
          <span>
            <img src={doneIcon} alt="" className={css['done-icon']} />
          </span>
        </span>
        <span style={{ color: '#778dac' }}>{step?.description ?? ''}</span>
        <Deadline step={step} />
      </div>
    );
  };

  const Deadline = (props: DeadlineProps) => {
    const { step } = props;

    return (
      <div className={css['dashboard-deadline']}>
        <span style={{ color: '#778dac' }}>{t('calendar.indicative.deadline')}</span>
        <br />
        <span style={{ color: '#e21315', fontWeight: 600 }}>{step.dateDescription}</span>
      </div>
    );
  };

  const shownSteps = (steps: StepsModel) => {
    const stepsInProgress = steps.filter((step) => step.status.code === 'in_progress');
    if (stepsInProgress.length > 3) {
      return stepsInProgress.slice(0, 3);
    }
    return stepsInProgress;
  };
  if (useLessThen801()) {
    return (
      <div className={css['progress-container-consumer']}>
        <span className={css['progress-title']}>{`${t('dashboard.progress.title')} ${progressPercent} %`}</span>
        <Progress
          percent={progressPercent}
          trailColor={'#d99396'}
          showInfo={false}
          strokeColor={'#e21315'}
          className={css['progress-line']}
          style={{ height: '1px' }}
        />
        <Carousel>
          {steps.length > 0 &&
            steps.map((step: StepModel, index: number) => <div key={step.id}>{stepByStatus(step, index)}</div>)}
        </Carousel>
        <DashboardLastPosts />
      </div>
    );
  }

  return (
    <div className={css['consumer-info-container']}>
      <div className={css['consumer-info-progress']}>
        <span className={css['progress-title']}>{`${t('dashboard.progress.title')} ${progressPercent} %`}</span>
        <Progress
          percent={progressPercent}
          trailColor={'#d99396'}
          strokeColor={'#e21315'}
          className={css['progress-line']}
        />
      </div>
      <div
        className={css['consumer-info-steps']}
        style={{
          justifyContent: stepsInProgress && stepsInProgress?.length < 3 ? 'center' : 'space-between',
          gap: stepsInProgress && stepsInProgress?.length < 3 ? '1rem' : '0',
        }}>
        {stepsInProgress &&
          stepsInProgress.length > 0 &&
          stepsInProgress.map((step: StepModel) => {
            return (
              <div key={step.id} style={{ width: '32%', flex: '0 0 32%' }}>
                {stepByStatus(step, +step.ord - 1)}
              </div>
            );
          })}
      </div>
      <DashboardLastPosts />
    </div>
  );
};

export default ConsumerDashboardInfo;
