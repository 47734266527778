import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import css from '../../../assets/styles/documents.module.css';
import { Table, Spin, notification, Button, TableProps, TablePaginationConfig } from 'antd';
import DocumentsService from '../../../services/DocumentsService';
import { getDocumentsColumns, getDocumentsGeneralColumns } from './DocumentsColumns';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/AppContextProvider';
import { DocumentsInfoModel, DocumentsModel } from '../../../models/DocumentsModel';
import DocumentsControl from './DocumetsControl';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import DocumentsForm from './DocumentForm';
import DeleteDocumensForm from './DeleteDocumentForm';
import PreviewPDF from './PreviewPDF';
import ArchivesDocuments from './ArchivesDocuments/ArchivesDocuments';
import ArchiveConfirmForm from './ArchivesDocuments/ArchiveConfirmForm';
import SigningConfirmForm from '../../common/SigningConfirmForm';
import { getStorageEnvelopeId, setStorageEnvelopeId } from '../../../helpers/envelopeStorage';
import { getUriSearchValue } from '../../../helpers/proccessUri';
import { docusignEventStatuses } from '../../../constants/docusignEventStatuses';
import { useLessThen801 } from '../../../helpers/mediaDetect';
import TableMobile from '../../common/TableMobile';
import { FiltersContext } from '../../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../../constants/filterTypes';
import filterIcon from '../../../assets/images/ic-filter.svg';
import filterActiveIcon from '../../../assets/images/ic-filter-active.svg';
import UploadFileLoader from '../../common/UploadFileLoader';
import CalendarService from '../../../services/CalendarServices';
import { CalendarTypes, ICalendarDocument } from '../../../models/CalendarModel';
import PostModalForm from '../../Posts/components/PostModalForm';
import PostConfirmModal from '../../Posts/components/PostConfirmModal';
import { getSearchParam } from '../../../helpers/customUrlSearchParams';
import CommonApproveForm from '../../common/CommonApproveForm';
import CommonHistoryModal from '../../common/CommonHistoryModal';
import { SET_DOCUMENTS_FILTERS } from '../../../constants/actionTypes/filtersConstants';
import { IHistoryModel } from '../../../models/CommonDocumnetsModel';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { IAppContext } from '../../../typings/IApp';
import { IUserEasy } from '../../../models/UserModel';
import { authDownloadFile } from '../../../helpers/authFileTools';

const service = new DocumentsService();
const calendarService = new CalendarService();

interface DocumentsListInfoProps {
  propertyId: number;
  projectId: number;
  signatories?: IUserEasy[];
}

const DocumentsListInfo = (props: DocumentsListInfoProps) => {
  const {
    filters: { documentsFilter },
    filtersDispatch,
  } = useContext(FiltersContext);
  const { propertyId, signatories = [], projectId } = props;
  const location = useLocation();
  const tabIndex = getSearchParam(location.search, 'tabIndex');
  const history = useHistory();
  const [isOpenModalPost, setIsOpenModaPost] = useState(false);
  const [isOpenModalDeletePost, setIsOpenModalDeletePost] = useState(false);
  const [documentsInfo, setDocumentsInfo] = useState<DocumentsInfoModel | ICalendarDocument[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  //history
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [historyArray, setHistoryArray] = useState<IHistoryModel>([]);
  const [currentItem, setCurrentItem] = useState<DocumentsModel | ICalendarDocument | string | null>(null);

  const [currentFilters, setCurrentFilters] = useState({
    'status_v2/code': {
      type: FilterTypes.SELECT,
      value: documentsFilter.status,
    },
    createdDate: {
      type: FilterTypes.DATE_RANGE,
      value: documentsFilter.dateRange,
    },
  });
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isOpenArchives, setIsOpenArchives] = useState(false);
  const [isOpenArchiveConfirm, setIsOpenArchiveConfirm] = useState(false);
  const [currentDocumentNumber, setCurrentDocumentNumber] = useState<string | null>(null);
  const { height: windowHeight } = useWindowDimensions();
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenSigningConfirm, setIsOpenSigningConfirm] = useState(false);
  const [isOpenUploadLoader, setIsOpenUploadLoader] = useState(false);
  const [signInfo, setSignInfo] = useState<string | null | undefined>(null);
  const {
    app: { isConsumer, isSupplier, user },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingDataSign, setFetchingDataSign] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    skip: 0,
  });
  const event = getUriSearchValue(location.search, 'event');
  const [isDocusignConfirm, setIsDocusignConfirm] = useState(
    !!event && event === docusignEventStatuses.signingComplete,
  );
  const [isDocusignDecline, setIsDocusignDecline] = useState(!!event && event === docusignEventStatuses.decline);

  const [activeTab, setActiveTab] = useState(tabIndex ? +tabIndex : 1);
  const tabsTableDocumnet = [
    { id: 1, title: t('document.tab.property') },
    { id: 2, title: t('document.tab.general') },
  ];

  const option = {
    count: true,
    top: pagination.pageSize,
    current: pagination.current,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
    orderBy: ['createdDate desc'],
  };

  useEffect(() => {
    if (activeTab !== 1) {
      onChangeTabDocuments(activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    const envelopeId = getStorageEnvelopeId();
    if (isDocusignConfirm) {
      setFetchingDataSign(true);
      if (!envelopeId) {
        setFetchingDataSign(false);
        setIsDocusignConfirm(false);
      } else {
        service
          .confirmStep(envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignConfirm(false);
            setStorageEnvelopeId('');
            setFetchingDataSign(false);
            history.replace({ search: '' });
          });
      }
    } else if (isDocusignDecline) {
      setFetchingDataSign(true);
      if (!envelopeId) {
        setFetchingDataSign(false);
        setIsDocusignDecline(false);
      } else {
        service
          .rejectDocument(null, '', envelopeId)
          .catch((e) => console.error(e))
          .finally(() => {
            setIsDocusignDecline(false);
            setStorageEnvelopeId('');
            setFetchingDataSign(false);
            history.replace({ search: '' });
          });
      }
    }
    if (location.search.includes('event')) {
      history.replace({ search: '' });
    }
  }, []);

  useEffect(() => {
    if (activeTab === 1) {
      getLocalFilters();
      getDocumentsInfo(option);
    }
  }, [isDocusignConfirm, propertyId]);

  const getLocalFilters = () => {
    const localFilters = localStorage.getItem('documentsFilter');
    const filters = localFilters && JSON.parse(localFilters);
    if (filters) {
      option.filters = generateFilterfromObj(
        applyNewFilter({
          'status_v2/code': {
            type: FilterTypes.SELECT,
            value: filters.status,
          },
        }),
      );
      filtersDispatch({
        type: SET_DOCUMENTS_FILTERS,
        filters: {
          status: filters.status,
        },
      });
    }
  };

  const checkIsDelete = (items: DocumentsInfoModel) => {
    if (location.search && items.length === 0) {
      notification.warning({
        message: t('common.warning.notification.file.deleted'),
      });
      history.push(location.pathname);
    }
  };

  const getDocumentsInfo = (option: { [name: string]: any }) => {
    setFetchingData(true);
    service
      .getDocumentsNoArchive(propertyId, option)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setDocumentsInfo(items);
        checkIsDelete(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const loadMore = (option: { [name: string]: any }) => {
    setFetchingData(true);
    option.skip = option.skip ? option.skip + 10 : 10;
    const { filters, ...rest } = option;
    const params = {
      ...rest,
      filter: {
        and: [
          {
            'type/code': {
              eq: CalendarTypes.document,
            },
          },
        ],
      },
    };
    activeTab === 1
      ? service
          .getDocumentsNoArchive(propertyId, option)
          .then((res) => {
            const { count, items } = res;
            const { current, top } = option;
            setPagination({ ...pagination, current, total: count, pageSize: top });
            setDocumentsInfo([...documentsInfo, ...items]);
          })
          .catch((e) => console.log(e))
          .finally(() => setFetchingData(false))
      : calendarService
          .getCalendarUpdates(params)
          .then((res) => {
            const { count, items } = res;
            const { current, top } = option;
            setPagination({ ...pagination, current, total: count, pageSize: top });
            setDocumentsInfo([...documentsInfo, ...items]);
          })
          .catch((e) => console.log(e))
          .finally(() => setFetchingData(false));
  };

  const setOpen = (id?: number) => {
    setIsOpenModal(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const setClose = () => {
    setIsOpenModal(false);
    setCurrentId(null);
  };

  const setOpenDelete = (id?: number, documnetNumber?: string) => {
    setIsOpenDeleteModal(true);
    if (id) {
      setCurrentId(id);
    }
    if (documnetNumber) {
      setCurrentDocumentNumber(documnetNumber);
    }
  };

  const setCloseDelete = () => {
    setIsOpenDeleteModal(false);
    setCurrentId(null);
    setCurrentDocumentNumber(null);
  };

  const openPreview = (id?: number) => {
    setIsOpenPreview(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setCurrentId(null);
    activeTab === 1 ? getDocumentsInfo(option) : getCalendarDocuments(option);
  };

  const openArchiveConfirm = (id?: number, documentNumber?: string) => {
    setIsOpenArchiveConfirm(true);
    if (id) {
      setCurrentId(id);
    }
    if (documentNumber) {
      setCurrentDocumentNumber(documentNumber);
    }
  };

  const closeArchiveConfirm = () => {
    setIsOpenArchiveConfirm(false);
    setCurrentId(null);
    getDocumentsInfo(option);
  };

  const applyNewFilter = (filters: { [name: string]: any }) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange: TableProps<DocumentsModel | ICalendarDocument>['onChange'] = (pagination, filters, sorter) => {
    let params: { [name: string]: any } = {
      ...option,
    };

    if (filters && !isEmptyObject(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination && pagination.pageSize && pagination.current) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    } else {
      params = {
        ...params,
        skip: 0,
        current: 1,
      };
    }
    if (sorter && !Array.isArray(sorter) && sorter.order) {
      const { order, columnKey } = sorter;
      switch (columnKey) {
        case 'createdDate': {
          params = {
            ...params,
            orderBy: `createdDate ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'date': {
          params = {
            ...params,
            orderBy: `date ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        case 'lastSignDateTime': {
          params = {
            ...params,
            orderBy: `lastSignDateTime ${order === 'ascend' ? 'asc' : 'desc'}`,
          };
          break;
        }
        default: {
          return;
        }
      }
    }

    // TODO: need to be refactored, it's made double document request
    activeTab === 1 ? getDocumentsInfo(params) : getCalendarDocuments(params);
  };

  const openSigningConfirm = (id?: number, signInfo?: string) => {
    setIsOpenSigningConfirm(true);
    if (id) {
      setCurrentId(id);
      setSignInfo(signInfo);
    }
  };

  const closeSigningConfirm = () => {
    setIsOpenSigningConfirm(false);
    setCurrentId(null);
    setSignInfo(null);
    getDocumentsInfo(option);
  };

  const closeUploadLoader = () => {
    setIsOpenUploadLoader(false);
    getDocumentsInfo(option);
  };

  const signDocument = () => {
    localStorage.setItem('documentsFilter', JSON.stringify(documentsFilter));
    if (currentId) {
      setFetchingData(true);
      service
        .getSigningUrl(currentId, window.location.href, user.id)
        .then((res) => {
          const { alreadySigned = false, declined = false } = res;
          if (!alreadySigned && !declined) {
            setStorageEnvelopeId(res.envelopeId);
            window.location.href = res.redirectUrl;
          } else {
            if (alreadySigned) {
              notification.warning({
                message: t('documents.modal.already.signed.message'),
              });
            }
            if (declined) {
              notification.warning({
                message: t('documents.modal.already.decline.message'),
              });
            }
            setFetchingData(false);
          }
        })
        .catch((e) => {
          notification.error({
            message: e.message,
          });
          setFetchingData(false);
        })
        .finally(() => {
          setClose();
        });
    }
  };

  const rejectDocument = (iD: number | null, comment: string, isApprove: boolean = false) => {
    const id = iD || currentId;
    if (id) {
      service
        .rejectDocument(id, comment)
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          isApprove ? closeApprove() : closeSigningConfirm();
        });
    }
  };

  const openApprove = (id: number) => {
    setCurrentId(id);
    setIsOpenApprove(true);
  };

  const closeApprove = () => {
    setCurrentId(null);
    setIsOpenApprove(false);
    getDocumentsInfo(option);
  };

  const approveItem = () => {
    setFetchingData(true);
    if (currentId) {
      service
        .confirmStep(undefined, currentId)
        .then(() => {
          notification.success({
            message: t('fileManagement.document.approved'),
          });
          closeApprove();
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => setFetchingData(false));
    }
  };

  const data =
    documentsInfo.length > 0 && activeTab === 1
      ? ((documentsInfo as DocumentsInfoModel).map((item) => [
          {
            title: t('documents.table.column.name'),
            description: item.title,
            warning: {
              title: (
                <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
              ),
              content: (
                <div style={{ width: '200px' }}>
                  <p>{t('common.popover.attention.file.content')}</p>
                </div>
              ),
            },
            type: !item.file ? 'warning' : 'string',
          },
          { title: t('documents.table.column.description'), description: item.description, type: 'string' },
          { title: t('documents.table.status'), description: item.statusV2, type: 'status' },
          {
            title: t('budget.table.action'),
            description: item.file,
            type: 'button',
            previewAction: !!item.file && openPreview,
            id: item.id,
            comment: item.comment || item.signInfo,
            status: item.statusV2,
            openByFunction: true,
            btnsInfo: [
              {
                function: () => setOpen(item.id),
                functionBtnTitle: t('budget.table.edit'),
                isNotShow: isSupplier || isConsumer,
              },
              {
                function: () => openApprove(item.id),
                functionBtnTitle: t('common.title.approve.reject'),
                isNotShow: !(
                  item.signatories &&
                  item.signatories.findIndex(
                    (signatory) =>
                      signatory.consumerId === user.id &&
                      (item.step?.code === 'agent_approve_open' ||
                        item.step?.code === 'agent_approve_close' ||
                        item.step?.code === 'client_approve') &&
                      signatory.stepCode === item.step?.code &&
                      !signatory.isDone,
                  ) >= 0 &&
                  !['rejected'].includes(item.statusV2.code)
                ),
              },
              {
                function: () => openSigningConfirm(item.id),
                functionBtnTitle: t('common.title.sign'),
                isNotShow: !(
                  item.signatories &&
                  item.signatories.findIndex(
                    (signatory) =>
                      signatory.consumerId === user.id &&
                      (item.step?.code === 'agent_sign_open' ||
                        item.step?.code === 'agent_sign_close' ||
                        item.step?.code === 'client_sign') &&
                      signatory.stepCode === item.step?.code &&
                      !signatory.isDone,
                  ) >= 0 &&
                  !['rejected'].includes(item.statusV2.code)
                ),
              },
              {
                function: () => (item.flow ? openHistory(item.id, item.title, item) : openHistory(item.id, item.title)),
                functionBtnTitle: t('fileManagement.history.title'),
                isNotShow: isConsumer,
              },
              {
                function: () => authDownloadFile(item.file, item.fileName),
                functionBtnTitle: t('budget.table.download'),
              },
            ],
          },
        ]) as any)
      : ((documentsInfo as ICalendarDocument[]).map((item) => [
          {
            title: t('documents.table.column.name'),
            description: item.name,
            warning: {
              title: (
                <span style={{ color: '#EB3B3B', fontSize: '18px' }}>{t('common.popover.attention.file.title')}</span>
              ),
              content: (
                <div style={{ width: '200px' }}>
                  <p>{t('common.popover.attention.file.content')}</p>
                </div>
              ),
            },
            type: item.files?.length === 0 ? 'warning' : 'string',
          },
          { title: t('documents.table.column.description'), description: item.description, type: 'string' },
          { title: t('documents.table.date'), description: item.date, type: 'date' },
          {
            title: t('budget.table.action'),
            type: 'button',
            description: item?.files && item.files.length > 0 ? item.files[0].fileName : '',
            previewAction: item.files?.length > 0 && openPreview,
            id: item.id,
            openByFunction: true,
            btnsInfo: [
              {
                function: () => {
                  setCurrentId(item.id);
                  // setIsDelete(false);
                  setIsOpenModaPost(true);
                },
                isNotShow: isSupplier || isConsumer,
                functionBtnTitle: t('budget.table.edit'),
              },
              {
                function: () => authDownloadFile(item.files[0].file, item.files[0].fileName),
                functionBtnTitle: t('budget.table.download'),
                isNotShow: !(item.files?.length > 0),
              },
            ],
          },
        ]) as any);

  const changeOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const getCalendarDocuments = async (option: { [name: string]: any }) => {
    const { filters, ...rest } = option;
    const params = {
      ...rest,
      filter: {
        and: [
          {
            'type/code': {
              eq: CalendarTypes.document,
            },
          },
          {
            'properties/id': {
              eq: propertyId,
            },
          },
        ],
      },
    };
    setFetchingData(true);
    await calendarService
      .getCalendarUpdates(params)
      .then((res) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setDocumentsInfo(items);
      })
      .catch((e) => console.log(e))
      .finally(() => setFetchingData(false));
  };

  const onChangeTabDocuments = (value: number) => {
    history.push(location.pathname.split('/').pop() as string);
    setDocumentsInfo([]);
    setActiveTab(value);
    if (value === 1) {
      return getDocumentsInfo(option);
    }
    if (value === 2) {
      return getCalendarDocuments(option);
    }
  };

  const openHistory = (id: number, title?: string, item?: DocumentsModel) => {
    if (item) {
      if (item.statusV2.code === 'rejected') {
        setFetchingData(true);
        service
          .getDocumentHistory(id)
          .then((value) => {
            setIsOpenHistory(true);
            setCurrentId(id);
            setHistoryArray(value);
            setCurrentItem(item);
          })
          .catch((e) =>
            notification.error({
              message: e.message,
            }),
          )
          .finally(() => setFetchingData(false));
        return;
      } else {
        setIsOpenHistory(true);
        setCurrentItem(item);
        return;
      }
    }
    setFetchingData(true);
    service
      .getDocumentHistory(id)
      .then((value) => {
        setIsOpenHistory(true);
        setCurrentId(id);
        setHistoryArray(value);
        setCurrentItem(title || null);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const closeHistory = () => {
    setCurrentId(null);
    setIsOpenHistory(false);
    setCurrentItem(null);
  };

  if (useLessThen801()) {
    return (
      <>
        <div className="common-mobile-title-container">
          <span style={{ fontSize: '20px' }}>{t('consumers.manage.tabs.documents')}</span>
          {activeTab === 1 && (
            <div className="common-modbile-filter-container" onClick={changeOpenFilter}>
              <img src={isOpenFilter ? filterActiveIcon : filterIcon} width={16} />
              <span className="filter-title">{isOpenFilter ? t('common.filter.close') : t('common.filter.open')}</span>
            </div>
          )}
        </div>
        {!isConsumer && activeTab === 1 && (
          <Button
            className={`common-blue-btn common-primary-btn`}
            onClick={() => setIsOpenModal(true)}
            style={{ width: '100%', marginBottom: isOpenFilter ? '0' : '1rem' }}>
            <span>{t('documents.table.upload.invoce')}</span>
          </Button>
        )}
        {isOpenFilter && (
          <DocumentsControl
            onTableChange={
              onTableChange as (
                pagination?: TablePaginationConfig | undefined,
                filters?: Record<string, any> | undefined,
              ) => void
            }
            setIsOpenModal={setIsOpenModal}
            propertyId={propertyId}
            isConsumer={isConsumer}
          />
        )}
        <div className={css['container-table-document-control']}>
          {tabsTableDocumnet.map((el) => (
            <Button
              key={el.id}
              className={`${activeTab === el.id ? css['active'] : ''}`}
              onClick={() => onChangeTabDocuments(el.id)}>
              {el.title}
            </Button>
          ))}
        </div>
        {activeTab === 1 && (
          <div className="common-flex-end-style" style={{ marginBottom: '1rem' }}>
            <span
              onClick={() => setIsOpenArchives(true)}
              className={`common-archive-icon-item common-animation-primary`}>
              <div
                className={css['archive-img']}
                style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
              <span style={{ color: '#e21315', marginRight: '0.5rem', textDecoration: 'underline' }}>
                {t('common.title.archive')}
              </span>
            </span>
          </div>
        )}
        <TableMobile
          data={data}
          isLoading={fetchingData || fetchingDataSign}
          loadMore={loadMore}
          option={option}
          itemsLength={documentsInfo.length}
          totalItems={pagination.total}
          userId={user.id}
        />
        {isOpenPreview && (
          <PreviewPDF
            id={currentId}
            isOpen={isOpenPreview}
            setClose={closePreview}
            isGeneralDocument={activeTab === 2}
          />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signDocument}
            reject={rejectDocument}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenModal && (
          <DocumentsForm
            setClose={setClose}
            propertyId={propertyId}
            getDocuments={() => getDocumentsInfo(option)}
            currentId={currentId}
            signatories={signatories}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
            projectId={projectId}
          />
        )}
        {isOpenArchives && (
          <ArchivesDocuments
            isOpen={isOpenArchives}
            setIsOpen={setIsOpenArchives}
            propertyId={propertyId}
            getDocuments={() => getDocumentsInfo(option)}
            isConsumer={isConsumer}
          />
        )}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenModalPost && (
          <PostModalForm
            isOpenModal={isOpenModalPost}
            defaultType={CalendarTypes.image}
            postId={currentId}
            getCalendarUpdates={() => getCalendarDocuments(option)}
            closeModal={() => {
              setIsOpenModaPost(false);
            }}
          />
        )}
        {isOpenModalDeletePost && (
          <PostConfirmModal
            currentId={currentId}
            isOpen={isOpenModalDeletePost}
            setClose={() => {
              setIsOpenModalDeletePost(false);
              getCalendarDocuments(option);
            }}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectDocument(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'document'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </>
    );
  }

  return (
    <div className={css['table-container']}>
      <Spin spinning={fetchingData || fetchingDataSign}>
        {activeTab === 1 && (
          <DocumentsControl
            onTableChange={
              onTableChange as (
                pagination?: TablePaginationConfig | undefined,
                filters?: Record<string, any> | undefined,
              ) => void
            }
            setIsOpenModal={setIsOpenModal}
            propertyId={propertyId}
            isConsumer={isConsumer}
          />
        )}
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div className={css['container-table-document-control']}>
            {tabsTableDocumnet.map((el) => (
              <Button
                key={el.id}
                className={`${activeTab === el.id ? css['active'] : ''}`}
                onClick={() => onChangeTabDocuments(el.id)}>
                {el.title}
              </Button>
            ))}
          </div>
          {activeTab === 1 ? (
            <Table
              columns={getDocumentsColumns({
                t,
                setOpen,
                isConsumer,
                setOpenDelete,
                openPreview,
                openArchiveConfirm,
                userId: user.id,
                openSigningConfirm,
                openApprove,
                openHistory,
              })}
              size="small"
              className={`list-info-table ${css['custom-table']}`}
              rowClassName={(record) => `common-table-row--pointer ${!record.file && 'common-table-row--error'}`}
              dataSource={documentsInfo as DocumentsInfoModel}
              showHeader={true}
              pagination={pagination}
              scroll={isConsumer ? { y: windowHeight - 330 } : { y: windowHeight - 360 }}
              onChange={onTableChange as TableProps<DocumentsModel>['onChange']}
              rowKey="id"
            />
          ) : (
            <Table
              columns={getDocumentsGeneralColumns({
                t,
                openPreview,
                isConsumer,
                setCurrentId,
                setIsOpenModalDeletePost,
                setIsOpenModaPost,
              })}
              size="small"
              className={`list-info-table ${css['custom-table']}`}
              rowClassName={(record) => `common-table-row--pointer ${!record.files && 'common-table-row--error'}`}
              dataSource={documentsInfo as ICalendarDocument[]}
              showHeader={true}
              pagination={pagination}
              scroll={{ y: isConsumer ? windowHeight - 330 : windowHeight - 360 }}
              onChange={onTableChange as TableProps<ICalendarDocument>['onChange']}
              rowKey="id"
            />
          )}
        </div>
        {activeTab === 1 && (
          <div className="common-flex-end-style">
            <span
              onClick={() => setIsOpenArchives(true)}
              className={`common-archive-icon-item common-animation-primary`}>
              <div
                className={css['archive-img']}
                style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
              <span style={{ color: '#e21315', marginRight: '0.5rem', textDecoration: 'underline' }}>
                {t('common.title.archive')}
              </span>
            </span>
          </div>
        )}
        {isOpenModal && (
          <DocumentsForm
            setClose={setClose}
            propertyId={propertyId}
            getDocuments={() => getDocumentsInfo(option)}
            currentId={currentId}
            signatories={signatories}
            isOpenModal={isOpenModal}
            setIsOpenUploadLoader={setIsOpenUploadLoader}
            setIsUploadingFile={setIsUploadingFile}
            projectId={projectId}
          />
        )}
        {isOpenDeleteModal && currentId && (
          <DeleteDocumensForm
            setClose={setCloseDelete}
            documentNumber={currentDocumentNumber}
            getDocuments={() => getDocumentsInfo(option)}
            currentId={currentId}
            isOpen={isOpenDeleteModal}
          />
        )}
        {isOpenArchives && (
          <ArchivesDocuments
            isOpen={isOpenArchives}
            setIsOpen={setIsOpenArchives}
            propertyId={propertyId}
            getDocuments={() => getDocumentsInfo(option)}
            isConsumer={isConsumer}
          />
        )}
        {isOpenArchiveConfirm && (
          <ArchiveConfirmForm
            id={currentId}
            documentNumber={currentDocumentNumber}
            isOpen={isOpenArchiveConfirm}
            setClose={closeArchiveConfirm}
          />
        )}
        {isOpenPreview && (
          <PreviewPDF
            id={currentId}
            isOpen={isOpenPreview}
            setClose={closePreview}
            isGeneralDocument={activeTab === 2}
          />
        )}
        {isOpenUploadLoader && (
          <UploadFileLoader isOpen={isOpenUploadLoader} isLoading={isUploadingFile} setClose={closeUploadLoader} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            id={currentId}
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signDocument}
            reject={rejectDocument}
            fetchingData={fetchingData}
            signInfo={signInfo}
          />
        )}
        {isOpenModalPost && (
          <PostModalForm
            // setIsOpenUploadMessageModal={setIsOpenUploadMessageModal}
            setIsOpenUploadMessageModal={() => ''}
            isOpenModal={isOpenModalPost}
            defaultType={CalendarTypes.image}
            postId={currentId}
            getCalendarUpdates={() => getCalendarDocuments(option)}
            closeModal={() => {
              setIsOpenModaPost(false);
            }}
          />
        )}
        {isOpenModalDeletePost && (
          <PostConfirmModal
            currentId={currentId}
            isOpen={isOpenModalDeletePost}
            setClose={() => {
              setIsOpenModalDeletePost(false);
              getCalendarDocuments(option);
            }}
          />
        )}
        {isOpenApprove && (
          <CommonApproveForm
            approve={approveItem}
            isOpen={isOpenApprove}
            reject={(id, comment) => rejectDocument(id, comment, true)}
            setClose={closeApprove}
            fetchingData={fetchingData}
            type={'document'}
          />
        )}
        {isOpenHistory && (
          <CommonHistoryModal
            fetchingData={fetchingData}
            setFetchingData={setFetchingData}
            isOpen={isOpenHistory}
            items={historyArray}
            setClose={closeHistory}
            currentItem={currentItem}
          />
        )}
      </Spin>
    </div>
  );
};

export default DocumentsListInfo;
