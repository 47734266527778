import { Button, Modal, notification, Upload } from 'antd';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/budget.module.css';
import ListPageServices from '../../../services/PropertiesService';

interface IProps {
  isVisible: boolean;
  handleClose: () => void;
  projectKey: string;
}

const service = new ListPageServices();

const ModalImportProperties = ({ isVisible, handleClose, projectKey }: IProps) => {
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isHaveFile, setIsHaveFile] = useState(true);

  const handleFile: UploadProps<any>['onChange'] = (info) => {
    setFileList(info.fileList);
    setIsHaveFile(true);
  };

  const importProperties = async () => {
    if (fileList.length < 1) {
      notification.error({
        message: t('consumers.modal.import.error.noItems'),
      });
      setIsHaveFile(false);
      return;
    }

    setFetchingData(true);
    const filesData = new FormData();
    for (const file of fileList) {
      if (file.originFileObj) {
        filesData.append('file', file.originFileObj);
      }
    }

    await service
      .importProperties(projectKey, filesData)
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        notification.error({
          message: e.message,
        });
      })
      .finally(() => setFetchingData(false));
  };

  return (
    <Modal
      visible={isVisible}
      closable={false}
      centered
      footer={
        <div className="common-modal-footer">
          <Button
            className={`common-gray-border-btn common-secondary-btn`}
            disabled={fetchingData}
            onClick={() => handleClose()}>
            {t('budget.modal.cancel.btn')}
          </Button>
          <Button className={`common-green-btn common-modal-btn`} disabled={fetchingData} onClick={importProperties}>
            {t('consumers.modal.import.btn').toUpperCase()}
          </Button>
        </div>
      }
      maskClosable={false}>
      <Upload
        className="consumers-import-upload-item file-management-upload"
        style={{ width: '100%' }}
        maxCount={1}
        beforeUpload={(file) => {
          const isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
          if (!isCSV) {
            notification.error({
              message: `${file.name} ${t('consumers.modal.import.error.csv')}`,
            });
            return isCSV || Upload.LIST_IGNORE;
          }
          return false;
        }}
        onChange={handleFile}
        accept=".csv"
        fileList={fileList}
        disabled={fetchingData}>
        {fileList.length < 1 && (
          <div className={css['upload-item-budget']}>
            <div className={css['upload-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#e21315' }}>{t('consumers.modal.import.btn.title')}</span>
          </div>
        )}
      </Upload>
      {!isHaveFile && <span style={{ color: '#ff4d4f' }}>{t('common.validation.required')}</span>}
      <div style={{ marginTop: '1rem' }}>{t('properties.import.warning')}</div>
    </Modal>
  );
};

export default ModalImportProperties;
