import { Button, Modal } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../../assets/styles/meetings.module.css';
import { MeetingModel } from '../../../models/MeetingsModel';
import i18n from '../../../utils/i18n';
import calendarOutlookICalIcon from '../../../assets/images/export-outlook-ical.svg';
import MeetingsService from '../../../services/MeetingsService';

interface IModal {
  isOpen: boolean;
  meeting: MeetingModel | null;
  cancel: () => void;
}

const meetingService = new MeetingsService();

const ModalExportCalendar = ({ isOpen, meeting, cancel }: IModal) => {
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(false);

  const exportICal = () => {
    if (meeting) {
      setFetchingData(true);
      meetingService
        .exportICal(meeting.id, meeting.name)
        .catch((e) => console.log(e))
        .finally(() => {
          setFetchingData(false);
          cancel();
        });
    }
  };

  return (
    <Modal
      className="common-modal"
      centered
      width={'28rem'}
      onCancel={cancel}
      title={<span className={css['export-modal-title']}>{t('meetings.modal.export.title')}</span>}
      visible={isOpen}
      footer={
        <div className="common-modal-footer" style={{ justifyContent: 'center' }}>
          <Button
            className={`common-gray-border-btn common-secondary-btn`}
            icon={<img src={calendarOutlookICalIcon} width={18} height={18} style={{ marginRight: '0.5rem' }} />}
            onClick={exportICal}
            disabled={fetchingData}>
            <span style={{ color: '#d99396', fontWeight: 600 }}>Outlook/iCal</span>
          </Button>
          {/* <Button
            className={`common-gray-border-btn common-secondary-btn`}
            style={{ marginLeft: '0' }}
            icon={<img src={calendarGoogleIcon} width={18} height={18} style={{ marginRight: '0.5rem' }} />}
            onClick={() => ''}
            disabled={fetchingData}>
            <span style={{ color: '#d99396', fontWeight: 600 }}>Google calendar</span>
          </Button> */}
        </div>
      }
      closable={true}>
      <div className={css['export-modal-container']}>
        <div className={css['export-modal-date']}>
          {moment(meeting?.datetime).locale(i18n.language).format('dddd, D MMMM (hh:mma)')}
        </div>
        <div className={css['export-modal-description']}>{meeting?.name}</div>
      </div>
    </Modal>
  );
};

export default ModalExportCalendar;
