import React from 'react';
import { Button } from 'antd';
import css from '../../../assets/styles/budget.module.css';
import downloadIcon from '../../../assets/images/ic-download-dark.svg';
import { TFunction } from 'react-i18next';
import { IChangeOrder } from '../../../models/ChangeOrdersModel';
import { convertToLocalDateTime } from '../../../helpers/converToLocalDate';
import { ColumnsType } from 'antd/lib/table';
import getProcoreStatusTag from '../../../helpers/getProcoreStatusTag';

interface IProps {
  t: TFunction<'translation'>;
  isConsumer: boolean;
  openPreview: (item: IChangeOrder) => void;
  downloadFile: (item: IChangeOrder) => void;
}

export const getChangeOrdersColumns = ({
  t,
  isConsumer,
  downloadFile,
  openPreview,
}: IProps): ColumnsType<IChangeOrder> => [
  {
    title: () => <span className="budget-table-tr">{t('projects.create.title')}</span>,
    key: 'title',
    width: isConsumer ? '55%' : '35%',
    render: (cell: IChangeOrder) => <span>{cell.title}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{isConsumer ? '' : t('properties.table.columns.consumers')}</span>,
    key: 'recipients',
    width: isConsumer ? '0px' : '20%',
    render: (cell: IChangeOrder) => (
      <span>{cell.recipients?.map((user) => (isConsumer ? '' : `${user.firstName} ${user.lastName}`)).join(', ')}</span>
    ),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: 210,
    render: (cell: IChangeOrder) => getProcoreStatusTag(cell.status, t),
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.createdDate')}</span>,
    key: 'created_at',
    width: '15%',
    sorter: true,
    render: (cell: IChangeOrder) => (cell.created_at ? convertToLocalDateTime(cell.created_at) : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('projects.table.columns.updatedDate')}</span>,
    key: 'updated_at',
    width: '15%',
    render: (cell: IChangeOrder) => (cell.updated_at ? convertToLocalDateTime(cell.updated_at) : ''),
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.action')}</span>,
    key: 'action',
    width: '220px',
    render: (cell: any) => {
      return (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className={`${css['update-menu-item']} common-animation-secondary`}
              onClick={() => downloadFile(cell)}
              style={{ backgroundColor: '#eef0f4', borderRadius: '6px', cursor: 'pointer', padding: '9px' }}>
              <img src={downloadIcon} alt="" style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} />
              <span style={{ color: '#00122d', fontWeight: 600 }}>{t('budget.table.download')}</span>
            </div>
            <Button
              className={`common-blue-border-btn common-secondary-btn`}
              onClick={() => openPreview(cell)}
              style={{ marginLeft: '1rem' }}>
              <span style={{ color: '#e21315' }}>{t('common.preview.btn')}</span>
            </Button>
          </div>
        </>
      );
    },
  },
];
