import React from 'react';
import ReactJoyride from 'react-joyride';

interface Props {
  steps: any[];
  run: boolean;
  isShowProgress?: boolean;
  zIndex?: number;
  onFinish?: () => void;
}

const CustomOnBoard = (props: Props) => {
  const { steps, run, isShowProgress, zIndex, onFinish } = props;

  return (
    <ReactJoyride
      steps={steps}
      run={run}
      beaconComponent={() => <span style={{ display: 'none' }}></span>}
      hideCloseButton
      continuous
      showSkipButton
      scrollOffset={5}
      disableScrolling={true}
      showProgress={isShowProgress ?? true}
      locale={{ close: 'Next', last: 'Finish' }}
      callback={(data) => {
        if (data.action === 'reset') {
          onFinish && onFinish();
        }
      }}
      styles={
        zIndex
          ? {
              options: {
                primaryColor: '#e21315',
                zIndex,
              },
            }
          : {
              options: {
                primaryColor: '#e21315',
              },
            }
      }
      // tooltipComponent={CustomTooltip}
    />
  );
};

export default CustomOnBoard;
