import { Carousel, Progress } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import css from '../../../../assets/styles/calendar.module.css';
import doneIcon from '../../../../assets/images/ic-check-mark-gray-copy.svg';
import arrowIcon from '../../../../assets/images/ic-arrow-back.svg';
import houseIcon from '../../../../assets/images/ic-house.svg';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../contexts/AppContextProvider';
import TimelineService from '../../../../services/TimelineService';
import { StepModel, StepsModel } from '../../../../models/TimelineModel';
import { useLessThen801 } from '../../../../helpers/mediaDetect';
import { IAppContext } from '../../../../typings/IApp';

interface DeadlineProps {
  step: StepModel;
}

const service = new TimelineService();

const ProgressConsumerComponent = () => {
  const {
    app: { currentProperty },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();
  const [steps, setSteps] = useState<StepsModel>([]);
  const stepsLength = steps && steps.length;
  const stepsDoneLength = steps && steps.filter((step: StepModel) => step.status.code === 'done').length;
  const progressPercent =
    stepsLength && stepsDoneLength ? Number(((stepsDoneLength / stepsLength) * 100).toFixed(0)) : 0;

  useEffect(() => {
    getSteps();
  }, [currentProperty]);

  const getSteps = () => {
    service
      .getTimeline(currentProperty.id)
      .then((value) => setSteps(value))
      .catch((e) => console.log(e));
  };

  const stepByStatus = (step: StepModel, index: number) => {
    switch (step.status.code) {
      case 'to_do':
        return StepTodo(step, index);
      case 'in_progress':
        return StepInProgress(step, index);
      default:
        return StepDone(step, index);
    }
  };

  const StepTodo = (step: StepModel, index: number) => {
    return (
      <>
        <div className={css['step-block-todo']}>
          <span className={css['step-date']}>
            <span className={css['step-title']}>{`${t('calendar.step')} ${index + 1}`}</span>
            <span>
              {index + 1 === stepsLength ? (
                <img src={houseIcon} alt="" className={css['house-icon']} />
              ) : (
                <span className={css['arrow-icon-todo-container']}>
                  <img src={arrowIcon} alt="" className={css['arrow-icon']} />
                </span>
              )}
            </span>
          </span>
          <span>{step?.description ?? ''}</span>
          <br />
          <Deadline step={step} />
        </div>
      </>
    );
  };

  const StepInProgress = (step: StepModel, index: number) => {
    return (
      <>
        <div className={css['step-block-current']}>
          <span className={css['step-date']}>
            <span className={css['step-title']}>{`${t('calendar.step')} ${index + 1}`}</span>
            <span className={css['arrow-icon-progress-container']}>
              <img src={arrowIcon} alt="" className={css['arrow-icon']} />
            </span>
          </span>
          <span style={{ color: '#e21315' }}>{step?.description ?? ''}</span>
          <br />
          <Deadline step={step} />
        </div>
      </>
    );
  };

  const StepDone = (step: StepModel, index: number) => {
    return (
      <>
        <div className={css['step-block']}>
          <span className={css['step-date']}>
            <span className={css['step-title']}>{`${t('calendar.step')} ${index + 1}`}</span>
            <span>
              <img src={doneIcon} alt="" className={css['done-icon']} />
            </span>
          </span>
          <span style={{ color: '#778dac' }}>{step?.description ?? ''}</span>
          <Deadline step={step} />
        </div>
      </>
    );
  };

  const Deadline = (props: DeadlineProps) => {
    const { step } = props;

    return (
      <div className={css['deadline-container']}>
        <span style={{ fontSize: '10px', color: '#778dac' }}>{t('calendar.indicative.deadline')}</span>
        <br />
        <span style={{ color: '#e21315', fontSize: '12px', fontWeight: 600 }}>{step.dateDescription}</span>
      </div>
    );
  };

  if (useLessThen801()) {
    return (
      <div className={css['progress-container-consumer']}>
        <span style={{ fontWeight: 800, fontSize: '18px' }}>{t('calendar.progress.title')}</span>
        <Progress
          percent={progressPercent}
          trailColor={'#d99396'}
          strokeColor={'#e21315'}
          className={css['progress-line']}
          style={{ height: '1px' }}
        />
        <Carousel>
          {steps.length > 0 &&
            steps.map((step: StepModel, index: number) => <div key={step.id}>{stepByStatus(step, index)}</div>)}
        </Carousel>
      </div>
    );
  }

  return (
    <div className={css['progress-container-consumer']}>
      <span style={{ fontWeight: 800, fontSize: '18px' }}>{t('calendar.progress.title')}</span>
      <div className={css['steps-container']}>
        {steps.length > 0 &&
          steps.map((step: StepModel, index: number) => <div key={step.id}>{stepByStatus(step, index)}</div>)}
      </div>
      <Progress
        percent={progressPercent}
        trailColor={'#d99396'}
        strokeColor={'#e21315'}
        className={css['progress-line']}
        style={{ height: '1px' }}
      />
    </div>
  );
};

export default ProgressConsumerComponent;
