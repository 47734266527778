exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".statistics_container__3vq3Z {\n  padding: 0 1rem;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  overflow: hidden;\n  height: 100%;\n  width: 100%;\n  padding: 1rem;\n  background-color: #f5f7fa;\n  border: solid 1px #c4c6ce;\n  border-top: solid 3px #e21315;\n}\n\n.statistics_control-container__3EeGd {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  padding: 1rem;\n  margin-bottom: 0.5rem;\n}\n\n.statistics_card-container-body__r4Gr8 {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.statistics_title__E_Ju7 {\n  font-weight: 600;\n  font-size: 12px;\n  color: #8d9bb5;\n}\n\n.statistics_status__2Y7q4 {\n  font-size: 12px;\n  color: #000;\n}\n.statistics_status-container__1hJsk {\n  display: flex;\n  align-items: center;\n  border-top: 1px solid #e8e9eb;\n  padding: 8px 0;\n  cursor: pointer;\n}\n.statistics_status-container__1hJsk:nth-last-child(1) {\n  border-bottom: 1px solid #e8e9eb;\n}\n.statistics_status-color-container__2y7-a {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  margin: 0 12px;\n  display: inline-block;\n}\n\n.statistics_project-count__LwIcW {\n  font-weight: 700;\n  font-size: 20px;\n  color: #000;\n}\n.statistics_project-persent__3m-jR {\n  font-weight: 700;\n  font-size: 16px;\n  color: #868686;\n}\n\n.statistics_column33__PfFZk {\n  display: flex;\n  flex: 1 1 33%;\n  flex-direction: column;\n  grid-gap: 10px;\n  gap: 10px;\n}\n\n.statistics_column66__1nUCh {\n  display: flex;\n  flex: 1 1 66%;\n  flex-wrap: wrap;\n  grid-gap: 10px;\n  gap: 10px;\n}\n\n.statistics_column100__1UpPM {\n  display: flex;\n  flex: 1 1 100%;\n  flex-wrap: wrap;\n  grid-gap: 10px;\n  gap: 10px;\n}\n\n/* progress */\n.statistics_progress-container__1tj99 {\n  width: 100%;\n  height: 16px;\n  border-radius: 32px;\n  display: flex;\n  justify-content: flex-start;\n  background-color: #fff;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "statistics_container__3vq3Z",
	"control-container": "statistics_control-container__3EeGd",
	"card-container-body": "statistics_card-container-body__r4Gr8",
	"title": "statistics_title__E_Ju7",
	"status": "statistics_status__2Y7q4",
	"status-container": "statistics_status-container__1hJsk",
	"status-color-container": "statistics_status-color-container__2y7-a",
	"project-count": "statistics_project-count__LwIcW",
	"project-persent": "statistics_project-persent__3m-jR",
	"column33": "statistics_column33__PfFZk",
	"column66": "statistics_column66__1nUCh",
	"column100": "statistics_column100__1UpPM",
	"progress-container": "statistics_progress-container__1tj99"
};