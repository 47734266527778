import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Drawer, Form, Input, notification, Row, Select, Spin, Upload } from 'antd';
import css from '../../../assets/styles/file-management.module.css';
import { useTranslation } from 'react-i18next';
import { setTextColorByStatus } from '../../../helpers/setTextColorByStatus';
import ListPageServices from '../../../services/PropertiesService';
import { PropertiesModel } from '../../../models/PropertyModel';
import UsersService from '../../../services/UsersService';
import moment from 'moment';
import { convertToCoins, convertToCurrency } from '../../../helpers/convertToPrice';
import config from '../../../config';
import { useLessThen801, useLessThen768 } from '../../../helpers/mediaDetect';
import { IFlow } from '../../../models/CustomSignFlowModel';
import CustomSignFlow from '../../../services/CustomSignFlow';
import { generateFilterfromObj } from '../../../helpers/generateFIlterfromObj';
import { FilterTypes } from '../../../constants/filterTypes';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { StatusesDocumentsModel } from '../../../models/DocumentsModel';
import { onChangeSum, onChangeSumWithVat, onChangeVat } from '../../../helpers/onChangeSumAndVat';
import { IResponceData } from '../../../typings/IServiceOptions';
import { IUserEasy } from '../../../models/UserModel';
import { ConsumerModel } from '../../../models/ConsumerModel';
import useCloseTabWarning from '../../../hooks/useCloseTabWarning';
import useRequests from '../hooks/useRequest';
import { setCreateNotificationMessage, setUpdateNotificationMessage } from '../utils/getMessages';
import useGetPageTypeConfig from '../hooks/useGetPageTypeConfig';

interface IProps {
  isOpenUpload: boolean;
  pageType: string;
  closeUpload: () => void;
  user: any;
  currentId: number | null;
  setIsOpenUploadLoader: any;
  setIsUploadingFile: (isLoading: boolean) => void;
}
//antd
const { Option } = Select;
const { TextArea } = Input;

//services
const propertyService = new ListPageServices();
const userService = new UsersService();
const serviceFlow = new CustomSignFlow();

const initialVat = 7.7;

const UploadFileManagement = (props: IProps) => {
  const { isOpenUpload, pageType, closeUpload, user, currentId, setIsOpenUploadLoader, setIsUploadingFile } = props;
  //common
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledStatus, setIsDisabledStatus] = useState(true);
  const [isRejectCancel, setIsRejectCancel] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [properties, setProperties] = useState<PropertiesModel>([]);
  const { routerPrompt: Prompt, setIsDirty } = useCloseTabWarning(t('common.modal.onbeforeunload.message'));
  const {
    setStatusByPageType,
    setByIdRequestByPageType,
    setUpdateRequestByPageType,
    setFileRequestByPageType,
    setCreateRequestByPageType,
  } = useRequests();
  const pageTypeConfig = useGetPageTypeConfig();
  //item
  const [item, setItem] = useState<any>(null);
  const [statuses, setStatuses] = useState<StatusesDocumentsModel>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [fileAttachmentList, setFileAttachmentList] = useState<any>([]);
  const [isHaveFile, setIsHaveFile] = useState(true);

  const [projectId, setProjectId] = useState<null | number>(null);
  const [propertyId, setPropertyId] = useState<null | number>(null);
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<null | string>(null);
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [consumers, setConsumers] = useState<ConsumerModel[]>([]);
  const [isLastAgentsReq, setIsLastAgentsReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [flows, setFlows] = useState<IFlow[]>([]);
  const [flowSteps, setFlowSteps] = useState<any[]>([]);
  const [paginationAgents, setPaginationAgents] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const SIZE_COLUMN = useLessThen801() ? 24 : 12;
  const HALF_COLUMN_SIZE = useLessThen768() ? 24 : 12;
  const QUARTER_COLUMN_SIZE = useLessThen768() ? 24 : 6;

  useEffect(() => {
    setIsDirty(fetchingData);
  }, [fetchingData]);

  useEffect(() => {
    setFetchingData(true);
    setStatusByPageType()
      .then((value: StatusesDocumentsModel) => {
        setStatuses(value);
        if (currentId) {
          setByIdRequestByPageType(currentId)
            .then((value: any) => {
              serviceFlow
                .getFlows(
                  {
                    filters: generateFilterfromObj({
                      'projects/id': {
                        type: FilterTypes.SELECT,
                        value: +value?.property?.project?.id,
                      },
                      'document_types/code': {
                        type: FilterTypes.SELECT,
                        value: pageTypeConfig.isDocuments
                          ? 'documents'
                          : pageTypeConfig.isQuotes
                          ? 'quotes'
                          : pageTypeConfig.isAmendments
                          ? 'amendments'
                          : pageTypeConfig.isInvoices
                          ? 'invoices'
                          : null,
                      },
                    }),
                  },
                  value.flow ? value.flow.id : null,
                )
                .then((flows: IFlow | IFlow[]) => {
                  const isArrayFlows = Array.isArray(flows);
                  setFlows((isArrayFlows ? flows : [flows]) as IFlow[]);
                  setProjectId(value?.property?.project?.id);
                  getProperties(value.property.project.key);
                  getConsumers(value.property.id);
                  getAgents(
                    value?.signatories
                      ?.filter((item: any) => item.stepCode.split('_').includes('agent'))
                      .map((item: any) => item.consumerId),
                    [],
                    value?.property?.project?.id,
                  );
                  setItem(value);

                  let fieldsValues: any = {};
                  if (pageTypeConfig.isDocuments) {
                    fieldsValues = {
                      name: value.title,
                      status: value.statusV2.code,
                      description: value.description,
                      comment: value.comment || value?.signInfo,
                      property: value.property.id,
                      project: value.property.project.id,
                      flow: value.flow ? value.flow.id : '',
                      responsible_agent: value.createdUser.id,
                    };
                  }
                  if (pageTypeConfig.isQuotes) {
                    fieldsValues = {
                      number: value.number,
                      status: value.statusV2.code,
                      sum: convertToCurrency(
                        value.sumWithFees !== null ? value.sumWithFees : value.sum !== null ? value.sum : 0,
                      ),
                      sumWithVat: convertToCurrency(value.sumWithVat),
                      property: value.property.id,
                      project: value.property.project.id,
                      description: value.description,
                      comment: value.comment || value?.signInfo,
                      deadline: !!value.deadline ? moment(value.deadline) : null,
                      createdDate: !!value.createdDate ? moment(value.createdDate) : null,
                      flow: value.flow ? value.flow.id : '',
                      responsible_agent: value.createdUser.id,
                    };
                  }
                  if (pageTypeConfig.isAmendments) {
                    fieldsValues = {
                      name: value.name,
                      number: value.number,
                      status: value.statusV2.code,
                      sum: convertToCurrency(value.deltaWithFees),
                      sumWithVat: convertToCurrency(value.deltaWithVat),
                      property: value.property.id,
                      project: value.property.project.id,
                      description: value.description,
                      comment: value.comment || value?.signInfo,
                      deadline: !!value.deadline ? moment(value.deadline) : null,
                      createdDate: !!value.createdDate ? moment(value.createdDate) : null,
                      flow: value.flow ? value.flow.id : '',
                      responsible_agent: value.createdUser.id,
                    };
                  }
                  if (pageTypeConfig.isInvoices) {
                    fieldsValues = {
                      number: value.number,
                      status: value.statusV2.code,
                      sum: convertToCurrency(
                        value.sumWithFees !== null ? value.sumWithFees : value.sum !== null ? value.sum : 0,
                      ),
                      sumWithVat: convertToCurrency(value.sumWithVat),
                      project: value.property.project.id,
                      property: value.property.id,
                      description: value.description,
                      comment: value.comment || value?.signInfo,
                      paymentDate: !!value.paymentDate ? moment(value.paymentDate) : null,
                      flow: value.flow ? value.flow.id : '',
                      responsible_agent: value.createdUser.id,
                    };
                  }
                  (
                    (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                  )?.flowsSteps
                    .map((item) => ({
                      stepCode: item.stepCode,
                      signatoriesName: item.step.signatoriesName,
                    }))
                    .forEach((item) => {
                      fieldsValues[item.stepCode] = value.signatories
                        .filter((sign: any) => sign.stepCode === item.stepCode)
                        .map((item: any) => item.consumerId);
                    });
                  form.setFieldsValue(fieldsValues);
                  if (value.flow && value.flow.id) {
                    setFlowSteps(
                      (
                        (isArrayFlows ? (flows as IFlow[]).find((item) => item.id === value.flow?.id) : flows) as IFlow
                      )?.flowsSteps.map((item) => ({
                        signatoriesName: item.step.signatoriesName,
                        code: item.step.code,
                      })) || [],
                    );
                  }
                  if (value.file) {
                    setFileList([
                      {
                        name: `${value.file.slice(0, 40)}...`,
                        uid: value.file,
                        url: `${config.storeUrl}/${value.file}`,
                      },
                    ]);
                  }
                  setIsDisabled(true);
                  setIsDisabledStatus(
                    value.flow === null
                      ? ['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code)
                      : true,
                  );
                  setIsRejectCancel(['rejected', 'done', 'canceled', 'signed'].includes(value.statusV2.code));
                });
            })
            .catch((e: any) =>
              notification.error({
                message: e.message,
              }),
            );
        }
      })
      .catch((e: any) => notification.error({ message: e.message }))
      .finally(() => setFetchingData(false));
  }, []);

  useEffect(() => {
    if (!currentId && flows.length > 0) {
      const defaultFlowId = flows.find((item) => item.isDefault)?.id;
      form.setFieldsValue({ flow: defaultFlowId || '' });
      setFlowSteps(
        flows
          .find((item) => item.id === defaultFlowId)
          ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
      );
    }
  }, [flows]);

  useEffect(() => {
    if (!currentId && projectId) {
      if (pageTypeConfig.isDocuments) {
        getAgents([user.id]);
      } else {
        getAgents();
      }
    }
  }, [projectId]);

  useEffect(() => {
    if (searchValue === '') {
      return getAgents([], currentSelectedUsers);
    }
    if (searchValue) {
      getAgents();
    }
  }, [searchValue]);

  const handleFile = (info: any) => {
    setFileList(info.fileList);
    setIsHaveFile(true);
  };

  const handleFileAttachment = (info: any) => {
    setFileAttachmentList(info.fileList);
  };

  const getProperties = (key: string) => {
    setFetchingData(true);
    propertyService
      .getProperties(key)
      .then((value: PropertiesModel) => setProperties(value.filter((prop) => prop.childrens.length === 0)))
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const getAgents = (agentsId?: number[], agentsRes: IUserEasy[] = [], projectIdProp?: number) => {
    if (agentsId && agentsId.length > 0) {
      userService
        .getEasyUsers({ userId: agentsId })
        .then((res: IUserEasy[]) => {
          setCurrentSelectedUsers(res);
          getAgents([], res, projectIdProp);
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
      return;
    }
    const optionUser: any = {
      count: true,
      top: paginationAgents.pageSize,
      current: paginationAgents.current,
      skip: paginationAgents.pageSize * (paginationAgents.current - 1) || 0,
      fullName: searchValue || '',
      projectId: [projectIdProp || projectId],
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPaginationAgents({ ...paginationAgents, current: current + 1, total: count, pageSize: top });
        let agentsId: number[] = [];
        if (agents.length > 0) {
          agentsId = agents.map((agent) => agent.id);
        } else if (agentsRes.length > 0) {
          agentsId = agentsRes.map((agent) => agent.id);
        }
        setAgents(
          [...agents, ...agentsRes, ...items.filter((item) => !agentsId.includes(item.id))].sort((a, b) =>
            a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
          ),
        );
        setIsLastAgentsReq(current * top >= count);
      })
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingUserData(false));
  };

  const getConsumers = (propertyId: number) => {
    setFetchingData(true);
    userService
      .getConsumersByProperty(propertyId)
      .then((value: ConsumerModel[]) => setConsumers(value))
      .catch((e) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  const resetUsers = () => {
    //agents
    setPaginationAgents({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    setIsLastAgentsReq(false);
    setCurrentSelectedUsers([]);
    setAgents([]);
    //consumers
    setConsumers([]);

    const fieldsValues: any = {};
    const fieldValue = form.getFieldsValue();
    flows
      .find((el) => el.id === fieldValue.flow)
      ?.flowsSteps.map((item) => ({
        stepCode: item.stepCode,
        signatoriesName: item.step.signatoriesName,
      }))
      .forEach((el) => {
        fieldsValues[el.stepCode] = [];
      });

    if (!isEmptyObject(fieldsValues)) {
      form.setFieldsValue(fieldsValues);
    }
  };

  const changeProject = (id: number) => {
    resetUsers();
    setProjectId(id);
    setPropertyId(null);
    const fieldValue = form.getFieldsValue();
    form.setFieldsValue({ ...fieldValue, property: null });
    const key = user.projects.find((project: any) => project.id === id).key;
    getProperties(key);
    serviceFlow
      .getFlows({
        filters: generateFilterfromObj({
          'projects/id': {
            type: FilterTypes.SELECT,
            value: id,
          },
          'document_types/code': {
            type: FilterTypes.SELECT,
            value: pageTypeConfig.isDocuments
              ? 'documents'
              : pageTypeConfig.isQuotes
              ? 'quotes'
              : pageTypeConfig.isAmendments
              ? 'amendments'
              : pageTypeConfig.isInvoices
              ? 'invoices'
              : null,
          },
        }),
      })
      .then((flows: IFlow[]) => {
        setFlows(flows);
      });
  };

  const changeProperty = (propertyId: number) => {
    resetUsers();
    getConsumers(propertyId);
    getAgents();
    setPropertyId(propertyId);
  };

  const onSubmit = (values: any) => {
    if (fileList.length < 1) {
      setIsHaveFile(false);
      return;
    }

    setFetchingData(true);
    const filesData = new FormData();
    if (!pageTypeConfig.isAmendments || (pageTypeConfig.isAmendments && item)) {
      for (const file of fileList) {
        if (file.originFileObj) {
          filesData.append(pageTypeConfig.isAmendments ? `main_${item.id}` : 'file', file.originFileObj);
        }
      }
    }

    if (!item) {
      for (const file of fileAttachmentList) {
        if (file.originFileObj) {
          filesData.append('attachments', file.originFileObj);
        }
      }
    }

    const body = {
      statusV2: {
        code: values.status ?? item?.status.code,
      },
      property: {
        id: values.property,
      },
      description: values.description || null,
    } as any;

    const signatiriesFlow: any[] = [];
    const currentFlow = flows
      ?.find((item) => values.flow && item.id === +values.flow)
      ?.flowsSteps?.sort((a, b) => a.ord - b.ord);

    currentFlow &&
      currentFlow.forEach((item) => {
        values[item.step.code].forEach((id: number) => {
          signatiriesFlow.push({ consumerId: id, stepCode: item.stepCode });
        });
      });

    if (item) {
      body.id = item.id;
    } else {
      body.flow = values.flow ? { id: values.flow } : null;
      if (signatiriesFlow.length > 0) {
        body['signatories'] = signatiriesFlow;
      }
    }

    if (pageTypeConfig.isDocuments) {
      body.title = values.name;
      body.hasSignature = signatiriesFlow.length !== 0;
    }

    if (pageTypeConfig.isAmendments) {
      body.name = values.name;
      body.number = values.number;
      body.deltaWithFees = convertToCoins(values.sum);
      body.deltaWithVat = convertToCoins(values.sumWithVat);
      body.objectText = t('ammendment.template.object');
      body.deadline = moment(values.deadline).utc().format('YYYY-MM-DD HH:mm');
    }

    if (pageTypeConfig.isQuotes) {
      body.deadline = moment(values.deadline).utc().format('YYYY-MM-DD HH:mm');
    }

    if (pageTypeConfig.isInvoices) {
      body.paymentDate = values.paymentDate;
    } else {
      body.comment = values.comment || null;
    }

    if (!pageTypeConfig.isDocuments && !pageTypeConfig.isAmendments) {
      body.number = values.number;
      body.sum = convertToCoins(values.sum);
      body.sumWithVat = convertToCoins(values.sumWithVat);
    }

    if (item) {
      setUpdateRequestByPageType(body)
        .then(() => {
          if (filesData.getAll(pageTypeConfig.isAmendments && item ? `main_${item.id}` : 'file').length > 0) {
            closeUpload();
            setIsUploadingFile(true);
            setFileRequestByPageType(item.id, filesData)
              .then(() => {
                notification.success({
                  message: setUpdateNotificationMessage(pageTypeConfig),
                });
              })
              .catch((e: any) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => setIsUploadingFile(false));
          } else {
            notification.success({
              message: setUpdateNotificationMessage(pageTypeConfig),
            });
            closeUpload();
          }
        })
        .catch((e: any) => {
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setFetchingData(false);
        });
    } else {
      setCreateRequestByPageType(body)
        .then((value: any) => {
          if (pageTypeConfig.isAmendments) {
            for (const file of fileList) {
              if (file.originFileObj) {
                filesData.append(`main_${value.id}`, file.originFileObj);
              }
            }
          }
          if (filesData.getAll(pageTypeConfig.isAmendments ? `main_${value.id}` : 'file').length > 0) {
            closeUpload();
            setIsUploadingFile(true);
            setIsOpenUploadLoader(true);
            setFileRequestByPageType(value.id, filesData)
              .then(() => {
                notification.success({
                  message: setCreateNotificationMessage(pageTypeConfig),
                });
              })
              .catch((e: any) => {
                notification.error({
                  message: e.message || t('common.error.internalServerError'),
                });
              })
              .finally(() => setIsUploadingFile(false));
          } else {
            notification.success({
              message: setCreateNotificationMessage(pageTypeConfig),
            });
            closeUpload();
          }
        })
        .catch((e: any) => {
          notification.error({
            message: e.message || t('common.error.internalServerError'),
          });
        })
        .finally(() => {
          setFetchingData(false);
        });
    }
  };

  const onSelectFlow = (value: number | '') => {
    form.setFieldsValue({ status: 'new' });
    setFlowSteps(
      flows
        .find((item) => item.id === value)
        ?.flowsSteps.map((item) => ({ signatoriesName: item.step.signatoriesName, code: item.step.code })) || [],
    );
  };

  return (
    <Drawer className="upload-form-drawer upload-fileManagment" visible={isOpenUpload} placement="top" closable={false}>
      <Spin spinning={fetchingData}>
        <Form autoComplete="off" onFinish={onSubmit} form={form}>
          <Form.Item>
            <div className={css['btns-container']}>
              <Button
                className={'common-gray-border-btn common-secondary-btn'}
                onClick={() => closeUpload()}
                style={{ height: '42px' }}>
                {t('common.return.btn')}
              </Button>
              <Button
                className={'common-blue-btn common-primary-btn'}
                htmlType="submit"
                style={{ height: '42px' }}
                disabled={fetchingData}>
                {currentId ? t('product.create.title.update') : t('common.btn.upload')}
              </Button>
            </div>
          </Form.Item>
          <div className={useLessThen801() ? '' : 'common-flex-between-style'} style={{ height: '90%' }}>
            <div className={css['file-container']}>
              <Form.Item
                className={css['form-item-budget']}
                colon={false}
                label={t('quotes.modal.upload.file')}
                labelCol={{ span: 24 }}
                initialValue={fileList}
                name="file">
                <>
                  <Upload
                    className="consumers-import-upload-item file-management-upload"
                    style={{ width: '100%' }}
                    maxCount={1}
                    beforeUpload={() => false}
                    onChange={handleFile}
                    accept=".pdf"
                    fileList={fileList}
                    disabled={isDisabled}>
                    {fileList.length < 1 && (
                      <div className={css['upload-item-budget']}>
                        <div
                          className={css['upload-img']}
                          style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
                        <span style={{ color: '#e21315' }}>{t('budget.modal.upload.btn.title')}</span>
                      </div>
                    )}
                  </Upload>
                  {!item && (
                    <>
                      <div style={{ width: '100%', borderBottom: '1px solid #dbdde4', margin: '8px 0' }}></div>
                      <Upload
                        className="consumers-import-upload-item upload-list documents-attachments"
                        style={{ width: '100%' }}
                        beforeUpload={() => false}
                        onChange={handleFileAttachment}
                        onRemove={() => true}
                        multiple={true}
                        fileList={fileAttachmentList}
                        accept=".pdf"
                        disabled={isDisabled}>
                        <div className={css['upload-item-budget']}>
                          <span style={{ color: '#e21315' }}>{t('documents.modal.upload.attachment.btn.title')}</span>
                        </div>
                      </Upload>
                    </>
                  )}
                  {!isHaveFile && <span style={{ color: '#ff4d4f' }}>{t('common.validation.required')}</span>}
                </>
              </Form.Item>
            </div>
            <div className={css['form-info-container']}>
              <Form.Item>
                <Row gutter={[24, 4]}>
                  {pageTypeConfig.isAmendments && (
                    <Col span={SIZE_COLUMN}>
                      <Form.Item
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        name="name"
                        colon={false}
                        label={<span style={{ color: '#778dac' }}>{t('documents.form.name')}</span>}
                        className={css['form-item']}
                        labelAlign="left">
                        <Input className="common-animation-primary" disabled={isDisabled} />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={SIZE_COLUMN}>
                    {pageTypeConfig.isDocuments ? (
                      <Form.Item
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        name="name"
                        colon={false}
                        label={<span style={{ color: '#778dac' }}>{t('documents.form.name')}</span>}
                        className={css['form-item']}
                        labelAlign="left">
                        <Input className="common-animation-primary" disabled={isDisabled} />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        name="number"
                        colon={false}
                        label={<span style={{ color: '#778dac' }}>{t('budget.form.number')}</span>}
                        className={css['form-item']}
                        labelAlign="left">
                        <Input className="common-animation-primary" disabled={isDisabled} />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="status"
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: t('common.validation.required'),
                        },
                      ]}
                      label={
                        <span style={{ color: '#778dac' }} className="documents-form-label">
                          {t('documents.form.status')}
                        </span>
                      }
                      initialValue={'new'}
                      className={css['form-item']}
                      labelAlign="left">
                      <Select
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        disabled={!currentId || isDisabledStatus}>
                        {statuses.length > 0 &&
                          statuses
                            .filter((status) =>
                              item && item?.flow === null
                                ? status.docTypesStatuses.find((item) => item.typeCode === pageType)?.isManual
                                : true,
                            )
                            .map((item) => (
                              <Select.Option key={item.code} value={item.code}>
                                <span style={{ color: `${setTextColorByStatus(item.code)}` }}>{item.description}</span>
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {pageTypeConfig.isAmendments && <Col span={SIZE_COLUMN}></Col>}
                  {!pageTypeConfig.isDocuments && (
                    <>
                      <Col span={QUARTER_COLUMN_SIZE}>
                        <Form.Item
                          name="sum"
                          colon={false}
                          labelAlign="left"
                          rules={[{ required: true, message: t('common.validation.required') }]}
                          label={<span style={{ color: '#778dac' }}>{t('budget.form.sum')}</span>}
                          className={css['form-item']}>
                          <Input
                            className="common-animation-primary"
                            disabled={isDisabled}
                            type="number"
                            onChange={(e) => onChangeSum(e, form)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={QUARTER_COLUMN_SIZE}>
                        <Form.Item
                          name="sumWithVat"
                          colon={false}
                          rules={[{ required: true, message: t('common.validation.required') }]}
                          label={<span style={{ color: '#778dac' }}>{t('budget.form.sum.with.vat')}</span>}
                          className={css['form-item']}
                          labelAlign="left">
                          <Input
                            className="common-animation-primary"
                            disabled={true}
                            type="number"
                            onChange={(e) => onChangeSumWithVat(e, form)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={HALF_COLUMN_SIZE}>
                        <Form.Item
                          name="vat"
                          colon={false}
                          rules={[{ required: true, message: t('common.validation.required') }]}
                          label={<span style={{ color: '#778dac' }}>{t('budget.form.vat2')}</span>}
                          className={css['form-item']}
                          labelAlign="left"
                          initialValue={initialVat}>
                          <Input
                            className="common-animation-primary"
                            disabled={isDisabled}
                            type="number"
                            onChange={(e) => onChangeVat(e, form)}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="project"
                      colon={false}
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      label={<span style={{ color: '#778dac' }}>{t('tickets.create.project')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <Select
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                        className="common-select common-animation-primary"
                        dropdownClassName="documents-control-select-dropdown"
                        onChange={changeProject}
                        disabled={isDisabled}
                        placeholder={t('tickets.create.placeholder.project')}
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }>
                        {user &&
                          user.projects &&
                          user.projects
                            .sort((a: any, b: any) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase()))
                            .map((project: any) => (
                              <Option value={project.id} key={project.id} className="documents-control-option">
                                <span style={{ fontSize: '14px', fontWeight: 600 }}>{project.title}</span>
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="property"
                      colon={false}
                      rules={[{ required: true, message: t('common.validation.required') }]}
                      label={<span style={{ color: '#778dac' }}>{t('tickets.create.property')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <Select
                        getPopupContainer={(trigger: any) => trigger.parentElement}
                        className="common-select common-animation-primary"
                        dropdownClassName="documents-control-select-dropdown"
                        placeholder={t('tickets.create.placeholder.property')}
                        onChange={changeProperty}
                        disabled={!form.getFieldsValue().project || isDisabled}
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }>
                        {properties.length > 0 &&
                          properties
                            .sort((a: any, b: any) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase()))
                            .map((project: any) => (
                              <Option value={project.id} key={project.id} className="documents-control-option">
                                <span style={{ fontSize: '14px', fontWeight: 600 }}>{project.title}</span>
                              </Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="description"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('budget.form.description')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <TextArea
                        bordered={false}
                        className="consumer-form-input common-animation-primary"
                        autoSize={{ minRows: 2, maxRows: 10 }}
                        disabled={isRejectCancel}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={SIZE_COLUMN}>
                    <Form.Item
                      name="comment"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('fileManagement.upload.comment')}</span>}
                      className={css['form-item']}
                      labelAlign="left">
                      <TextArea
                        bordered={false}
                        className="consumer-form-input common-animation-primary"
                        autoSize={{ minRows: 2, maxRows: 10 }}
                        disabled={isRejectCancel}
                      />
                    </Form.Item>
                  </Col>
                  {(pageTypeConfig.isQuotes || pageTypeConfig.isAmendments) && (
                    <Col span={SIZE_COLUMN}>
                      <Form.Item
                        name="deadline"
                        colon={false}
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        label={<span style={{ color: '#778dac' }}>{t('budget.form.deadline')}</span>}
                        className={css['form-item']}
                        initialValue={moment().add(10, 'days')}
                        labelAlign="left">
                        <DatePicker
                          showTime={{ format: 'HH:mm' }}
                          getPopupContainer={(trigger: any) => trigger.parentElement}
                          disabled={isRejectCancel}
                          style={{ width: '100%' }}
                          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                          className="common-date-picker common-animation-primary"
                          format="DD.MM.YYYY HH:mm"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {pageTypeConfig.isInvoices && (
                    <Col span={SIZE_COLUMN}>
                      <Form.Item
                        name="paymentDate"
                        colon={false}
                        rules={[{ required: true, message: t('common.validation.required') }]}
                        label={<span style={{ color: '#778dac' }}>{t('budget.form.date')}</span>}
                        className={css['form-item']}
                        initialValue={moment()}
                        labelAlign="left">
                        <DatePicker
                          getPopupContainer={(trigger: any) => trigger.parentElement}
                          disabled={isRejectCancel}
                          style={{ width: '100%' }}
                          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                          className="common-date-picker common-animation-primary"
                          format="DD.MM.YYYY"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {(pageTypeConfig.isQuotes || pageTypeConfig.isAmendments) && (
                    <Col span={SIZE_COLUMN}>
                      <Form.Item
                        name="createdDate"
                        colon={false}
                        initialValue={moment()}
                        label={<span style={{ color: '#778dac' }}>{t('fileManagement.upload.date')}</span>}
                        className={css['form-item']}
                        labelAlign="left">
                        <DatePicker
                          getPopupContainer={(trigger: any) => trigger.parentElement}
                          disabled
                          style={{ width: '100%' }}
                          suffixIcon={<div className={'calendar-img'} style={{ width: '12px', height: '12px' }}></div>}
                          className="common-date-picker common-animation-primary"
                          format="DD.MM.YYYY"
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={[24, 4]}>
                  <Col span={24}>
                    <Form.Item
                      name="flow"
                      colon={false}
                      label={<span style={{ color: '#778dac' }}>{t('contract.flow.title')}</span>}
                      className={css['form-item']}
                      initialValue={''}
                      labelAlign="left">
                      <Select
                        className="common-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        style={{ marginTop: '0' }}
                        onSelect={onSelectFlow}
                        disabled={isDisabled || !propertyId}>
                        <Select.Option value={''}>{t('contract.flow.noFlow')}</Select.Option>
                        {flows.length > 0 &&
                          flows.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {flowSteps.length > 0 ? (
                  <Row gutter={[24, 4]}>
                    {flowSteps.map((el) => (
                      <Col span={SIZE_COLUMN} key={el.code}>
                        <Form.Item
                          name={el.code}
                          colon={false}
                          label={<span style={{ color: '#778dac' }}>{el.signatoriesName} *</span>}
                          rules={[{ required: !item, message: t('common.validation.required') }]}
                          className={css['form-item']}
                          labelAlign="left">
                          <Select
                            className="common-select common-animation-primary"
                            mode="multiple"
                            filterOption={(input, option: any) =>
                              pageTypeConfig.isDocuments || el.code.split('_').includes('agent') > 0
                                ? true
                                : option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onDropdownVisibleChange={(value) => {
                              if (
                                !pageTypeConfig.isDocuments &&
                                el.code.split('_').includes('agent') > 0 &&
                                !value &&
                                searchValue
                              ) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                setSearchValue('');
                              }
                            }}
                            onSearch={(value) => {
                              if (!pageTypeConfig.isDocuments && el.code.split('_').includes('agent') > 0) {
                                setAgents([]);
                                setPaginationAgents({ ...paginationAgents, current: 1, skip: 0 });
                                clearTimeout(currentTimeout);
                                setCurrentTimeout(setTimeout(() => setSearchValue(value), 1000));
                              }
                            }}
                            dropdownClassName={`documents-control-select-dropdown ${
                              fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''
                            }`}
                            onPopupScroll={(e: any) => {
                              e.persist();
                              let target = e.target;
                              if (
                                !pageTypeConfig.isDocuments &&
                                el.code.split('_').includes('agent') > 0 &&
                                !fetchingUserData &&
                                !isLastAgentsReq &&
                                target.scrollTop + target.offsetHeight === target.scrollHeight
                              ) {
                                getAgents();
                              }
                            }}
                            suffixIcon={
                              <div
                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                style={{ width: '8px', height: '4px' }}></div>
                            }
                            disabled={isDisabled || !propertyId}>
                            {el.code.split('_').includes('agent') > 0 ? (
                              agents.length > 0 ? (
                                agents
                                  .filter((agent) =>
                                    currentId || !pageTypeConfig.isDocuments ? true : agent.id === user.id,
                                  )
                                  .map((agent) => (
                                    <Option value={agent.id} key={agent.id} className="documents-control-option">
                                      <span
                                        style={{
                                          fontSize: '14px',
                                          fontWeight: 600,
                                        }}>{`${agent.firstName} ${agent.lastName}`}</span>
                                    </Option>
                                  ))
                              ) : (
                                <Option value={user.id} key={user.id} className="documents-control-option">
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 600,
                                    }}>{`${user.firstName} ${user.lastName}`}</span>
                                </Option>
                              )
                            ) : (
                              consumers.length > 0 &&
                              consumers
                                .sort((a, b) => a.firstName?.toLowerCase().localeCompare(b.firstName?.toLowerCase()))
                                .map((consumer) => (
                                  <Option value={consumer.id} key={consumer.id} className="documents-control-option">
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                      }}>{`${consumer.firstName} ${consumer.lastName}`}</span>
                                  </Option>
                                ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    ))}
                    {
                      <Col span={SIZE_COLUMN}>
                        <Form.Item
                          name="responsible_agent"
                          colon={false}
                          label={
                            <span style={{ color: '#778dac' }}>{t('fileManagement.control.all.responsible')}</span>
                          }
                          className={css['form-item']}
                          initialValue={user.id}
                          labelAlign="left">
                          <Select className="common-select common-animation-primary" disabled={true}>
                            {item && item.createdUser && !isEmptyObject(item.createdUser) && (
                              <Option value={item.createdUser.id} className="documents-control-option">
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                  }}>{`${item.createdUser.firstName} ${item.createdUser.lastName}`}</span>
                              </Option>
                            )}
                            <Option value={user.id} className="documents-control-option">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                }}>{`${user.firstName} ${user.lastName}`}</span>
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    }
                  </Row>
                ) : (
                  <Row gutter={[24, 4]}>
                    <Col span={SIZE_COLUMN}>
                      <Form.Item
                        name="responsible_agent"
                        colon={false}
                        label={<span style={{ color: '#778dac' }}>{t('fileManagement.control.all.responsible')}</span>}
                        className={css['form-item']}
                        initialValue={user.id}
                        labelAlign="left">
                        <Select className="common-select common-animation-primary" disabled={true}>
                          {item && item.createdUser && !isEmptyObject(item.createdUser) && (
                            <Option value={item.createdUser.id} className="documents-control-option">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                }}>{`${item.createdUser.firstName} ${item.createdUser.lastName}`}</span>
                            </Option>
                          )}
                          <Option value={user.id} className="documents-control-option">
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: 600,
                              }}>{`${user.firstName} ${user.lastName}`}</span>
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
      {Prompt}
    </Drawer>
  );
};

export default UploadFileManagement;
