import { RouterListModel } from '../../models/RouterListModel';
import PropertiesPage from '../../views/Properties/PropertiesPage';
import PropertyEditPage from '../../views/Properties/PropertyEditPage';
import UsersPage from '../../views/Users/UsersPage';
import UserModifyPage from '../../views/Users/UserModifyPage';
import RolesPage from '../../views/Roles/RolesPage';
import RolesModifyPage from '../../views/Roles/RolesModifyPage';
import ProjectsPage from '../../views/Projects/ProjectsPage';
import TypologiesModifyPage from '../../views/Typologies/TypologiesModifyPage';
import LevelsModifyPage from '../../views/Typologies/LevelsModifyPage';
import VariantsModifyPage from '../../views/Typologies/VariantsModifyPage';
import NotFoundPage from '../../views/common/NotFoundPage';
import TypologiesLayout from '../../views/Typologies/TypologiesLayout';
import ProductModifyPage from '../../views/Product/ProductModifyPage';
import ProductCreatePage from '../../views/Product/ProductCreatePage';
import ProductPage from '../../views/Product/ProductPage';
import CategoriesLayout from '../../views/Categories/CategoriesLayout';
import ConsumersPage from '../../views/Consumers/ConsumersPage';
import ConsumerModifyPage from '../../views/Consumers/ConsumerModifyPage';
import ConsumerRequestsPage from '../../views/ConsumerRequests/ConsumerRequestsPage';
import ConsumerRequestsView from '../../views/ConsumerRequests/ConsumerRequestsView';
import DashboardPage from '../../views/Dashboard/DashboardPage';
import LeadsPage from '../../views/Leads/LeadsPage';
import LeadsModifyPage from '../../views/Leads/LeadsModifyPage';
import CalendarPage from '../../views/Calendar/CalendarPage';
import PropertyManagePage from '../../views/Properties/PropertyManagePage';
import ConsumerBudget from '../../views/Budget/ConsumerBudget';
import ConsumerDetailsPage from '../../views/DetailsProject/ConsumerDetailsPage';
import ManageFAQ from '../../views/FAQ/ManageFAQ/ManageFAQ';
import ManageFAQModify from '../../views/FAQ/ManageFAQ/ManageFAQModify';
import MyProfilePage from '../../views/MyProfile/MyProfilePage';
import ConsumerGallery from '../../views/Gallery/ConsumerGallery';
import ConsumerDocuments from '../../views/Documents/ConsumerDocuments';
import TopicsPage from '../../views/FAQ/ManageFAQ/components/TopicsPage';
import ConsumerFAQ from '../../views/FAQ/ConsumerFAQ/ConsumerFAQ';
import ConsumerQuotes from '../../views/Quotes/QuotesConsumerPage';
import TicketsPage from '../../views/Tickets/TicketsPage';
import TicketNewPage from '../../views/Tickets/TicketNewPage';
import TicketViewPage from '../../views/Tickets/TicketViewPage';
import ReportsPage from '../../views/Reports/ReportsPage';
import ReportDataPage from '../../views/Reports/ReportDataPage';
import InternalDocuments from '../../views/InternalDocuments/InternalDocumentsPage';
import Tasks from '../../views/Tasks/Tasks';
import Companies from '../../views/Companies/Companies';
import CompaniesModifyPage from '../../views/Companies/CompaniesModifyPage';
import MeetingsPage from '../../views/Meetings/MeetingsPage';
import FileManagementPage from '../../views/FIleManagement/FIleManagementPage';
import NotificationHistoryPage from '../../views/NotificationHistory/NotificationHistoryPage';
import PostsPage from '../../views/Posts/PostsPage';
import ProjectManagePage from '../../views/Projects/ProjectManagePage';
import AmendmentConsumerPage from '../../views/Amendment/AmendmentConsumerPage';
import ConsumerManagePage from '../../views/Consumers/ConsumerManagePage';
import TopicsHIWPage from '../../views/HowItWorks/ManageHowItWorks/components/TopicsPage';
import ManageHIW from '../../views/HowItWorks/ManageHowItWorks/ManageHIW';
import ManageHIWModify from '../../views/HowItWorks/ManageHowItWorks/ManageHIWModify';
import HowItWorks from '../../views/HowItWorks/HowItWorks/HowItWorks';
import ChangeOrders from '../../views/ChangeOrders/ChangeOrders';
import AICreatingPage from '../../views/FAQ/ManageFAQ/AI/AICreatingPage';
import FinalAccountConsumerPage from '../../views/FinalAccount/FinalAccountConsumerPage';

const RouteList: RouterListModel[] = [
  {
    path: '/dashboard',
    code: 'dashboard',
    icon: 'dashboard-icon',
    component: DashboardPage,
  },
  {
    path: '/calendar',
    code: 'calendar',
    icon: 'calendar-icon',
    component: CalendarPage,
  },
  {
    path: '/budget',
    code: 'budget',
    icon: 'budget-icon',
    component: ConsumerBudget,
  },
  {
    path: '/quotes',
    code: 'quotes',
    icon: 'quotes-icon',
    component: ConsumerQuotes,
  },
  {
    path: '/amendments',
    code: 'amendments',
    icon: 'quotes-icon',
    component: AmendmentConsumerPage,
  },
  // WARNING: HIDE FOR SWISSROC
  // {
  //   path: '/final_accounts',
  //   code: 'final_accounts',
  //   icon: 'quotes-icon',
  //   component: FinalAccountConsumerPage,
  // },
  {
    path: '/profile',
    code: 'profile',
    icon: 'profile-icon',
    component: MyProfilePage,
  },
  {
    path: '/documents',
    code: 'documents',
    icon: 'documents-icon',
    component: ConsumerDocuments,
  },
  {
    path: '/tasks',
    code: 'tasks',
    icon: 'tasks-icon',
    component: Tasks,
  },
  {
    path: '/companies',
    code: 'companies',
    icon: 'companies-icon',
    component: Companies,
    routes: [
      {
        path: '/companies/modify/:id?',
        code: 'companies-modify',
        component: CompaniesModifyPage,
      },
    ],
  },
  {
    path: '/gallery',
    code: 'gallery',
    icon: 'gallery-icon',
    component: ConsumerGallery,
  },
  {
    path: '/post',
    code: 'post',
    icon: 'post-icon',
    component: PostsPage,
  },
  {
    path: '/details_project',
    code: 'details_project',
    icon: 'details-project-icon',
    component: ConsumerDetailsPage,
  },
  {
    path: '/properties',
    code: 'properties',
    icon: 'home-outlined',
    component: PropertiesPage,
    group: {
      code: 'customizing_projects',
      description: 'customizing projects',
    },
    routes: [
      {
        path: [
          '/properties/:parentId?/property/:id?/project/:projectId?/:defaultType?',
          '/properties/property/:id?/project/:projectId?',
          '/properties/*/property/:id?/project/:projectId?',
        ],
        code: 'property',
        component: PropertyEditPage,
      },
      {
        path: '/properties/:propertyId/project/:projectId/manage/:tabName',
        code: 'consumers-manage',
        component: PropertyManagePage,
      },
    ],
  },
  {
    code: 'access',
    icon: 'team-outlined',
    group: {
      code: 'settings',
      description: 'settings',
    },
    children: [
      {
        path: '/users',
        code: 'users',
        icon: 'user-outlined',
        component: UsersPage,
        routes: [
          {
            path: '/users/modify/:id?',
            code: 'users-modify',
            component: UserModifyPage,
          },
        ],
      },
      {
        path: '/roles',
        code: 'roles',
        icon: 'usergroup-add-outlined',
        component: RolesPage,
        routes: [
          {
            path: '/roles/modify/:name?',
            code: 'roles-modify',
            component: RolesModifyPage,
          },
        ],
      },
    ],
  },
  {
    code: 'configurator',
    icon: 'settings',
    group: {
      code: 'customizing_projects',
      description: 'customizing projects',
    },
    children: [
      {
        path: '/typologies',
        code: 'typologies',
        component: TypologiesLayout,
        icon: 'apartment-outlined',
        routes: [
          {
            path: '/typologies/modify/:id?',
            code: 'typology-modify',
            component: TypologiesModifyPage,
          },
          {
            path: '/typologies/:typologyId/levels/modify/:id?',
            code: 'levels-modify',
            component: LevelsModifyPage,
          },
          {
            path: '/typologies/:typologyId/levels/:levelId/variants/modify/:id?',
            code: 'variants-modify',
            component: VariantsModifyPage,
          },
        ],
      },
    ],
  },
  {
    code: 'inventory',
    icon: 'database-outlined',
    group: {
      code: 'customizing_projects',
      description: 'customizing projects',
    },
    children: [
      {
        path: '/products',
        code: 'products',
        component: ProductPage,
        icon: 'barcode-outlined',
        routes: [
          {
            path: '/products/create',
            code: 'products-create',
            component: ProductCreatePage,
          },
          {
            path: '/products/modify/:id',
            code: 'products-modify',
            component: ProductModifyPage,
          },
        ],
      },
      {
        path: '/categories',
        code: 'categories',
        component: CategoriesLayout,
        icon: 'unordered-list-outlined',
        routes: [],
      },
    ],
  },
  {
    path: '/projects',
    code: 'projects',
    icon: 'project-outlined',
    group: {
      code: 'customizing_projects',
      description: 'customizing projects',
    },
    component: ProjectsPage,
    routes: [
      {
        path: '/projects/modify/:id?/:tabName',
        code: 'projects-modify',
        component: ProjectManagePage,
      },
    ],
  },
  {
    code: 'crm',
    icon: 'solution-outlined',
    group: {
      code: 'settings',
      description: 'settings',
    },
    children: [
      {
        path: '/leads',
        code: 'leads',
        component: LeadsPage,
        icon: 'team-outlined',
        routes: [
          {
            path: '/leads/modify/:id?',
            code: 'leads-modify',
            component: LeadsModifyPage,
          },
        ],
      },
      {
        path: '/requests',
        code: 'requests',
        component: ConsumerRequestsPage,
        icon: 'delivered-procedure-outlined',
        routes: [
          {
            path: '/requests/view/:id?',
            code: 'requests-view',
            component: ConsumerRequestsView,
          },
        ],
      },
    ],
  },
  {
    path: '/consumers',
    code: 'consumers',
    component: ConsumersPage,
    group: {
      code: 'managing_projects',
      description: 'managing projects',
    },
    icon: 'team-outlined',
    routes: [
      {
        path: '/consumers/modify/:id?',
        code: 'consumers-modify',
        component: ConsumerModifyPage,
      },
      {
        path: '/consumers/project/:projectId/property/:propertyId/manage/:tabName',
        code: 'consumers-manage',
        component: ConsumerManagePage,
      },
    ],
  },
  {
    path: '/tickets',
    code: 'tickets',
    component: TicketsPage,
    group: {
      code: 'managing_projects',
      description: 'managing projects',
    },
    icon: 'tickets-icon',
    routes: [
      {
        path: '/tickets/new/:id?',
        code: 'ticket-new',
        component: TicketNewPage,
      },
      {
        path: '/tickets/view/:id?',
        code: 'ticket-view',
        component: TicketViewPage,
      },
    ],
  },
  {
    code: 'not-found',
    component: NotFoundPage,
  },
  {
    path: '/manage_faq',
    code: 'manage_faq',
    icon: 'faq-icon',
    component: ManageFAQ,
    routes: [
      {
        path: '/manage_faq/modify/:id?',
        code: 'manage_faq-modify',
        component: ManageFAQModify,
      },
      {
        path: '/manage_faq/topics',
        code: 'manage_faq-topics',
        component: TopicsPage,
      },
      {
        path: '/manage_faq/ai_creating',
        code: 'manage_faq-ai_creating',
        component: AICreatingPage,
      },
    ],
  },
  {
    path: '/faq',
    code: 'faq',
    icon: 'faq-icon',
    component: ConsumerFAQ,
  },
  {
    path: '/reports',
    code: 'reports',
    icon: 'reports-icon',
    component: ReportsPage,
    routes: [
      {
        path: '/reports/:name',
        code: 'report-data',
        component: ReportDataPage,
      },
    ],
  },
  {
    path: '/internal_signature',
    code: 'internal_signature',
    icon: 'internal-documents-icon',
    component: InternalDocuments,
  },
  {
    path: '/meetings',
    code: 'meetings',
    icon: 'meeting-icon',
    component: MeetingsPage,
  },
  {
    code: 'file_management',
    group: {
      code: 'managing_projects',
      description: 'managing projects',
    },
    icon: 'internal-documents-icon',
    children: [
      {
        path: '/file_management/invoices',
        code: 'management_invoices',
        icon: 'invoice-file-icon',
        component: FileManagementPage,
      },
      {
        path: '/file_management/quotes',
        code: 'management_quotes',
        icon: 'quote-file-icon',
        component: FileManagementPage,
      },
      {
        path: '/file_management/documents',
        code: 'management_documents',
        icon: 'document-file-icon',
        component: FileManagementPage,
      },
      {
        path: '/file_management/amendments',
        code: 'management_amendments',
        icon: 'document-file-icon',
        component: FileManagementPage,
      },
      {
        path: '/file_management/change_orders',
        code: 'change_orders',
        icon: 'document-file-icon',
        component: ChangeOrders,
      },
    ],
  },
  {
    path: '/notification_history',
    code: 'notification_history',
    icon: 'notification-history-icon',
    component: NotificationHistoryPage,
  },
  {
    path: '/post',
    code: 'post',
    icon: 'post-icon',
    component: PostsPage,
  },
  {
    path: '/manage_hiw',
    code: 'manage_hiw',
    icon: 'faq-icon',
    component: ManageHIW,
    routes: [
      {
        path: '/manage_hiw/modify/:id?',
        code: 'manage_hiw-modify',
        component: ManageHIWModify,
      },
      {
        path: '/manage_hiw/topics',
        code: 'manage_hiw-topics',
        component: TopicsHIWPage,
      },
    ],
  },
  {
    path: '/hiw',
    code: 'hiw',
    icon: 'faq-icon',
    component: HowItWorks,
  },
  {
    path: '/change_orders',
    code: 'change_orders',
    icon: 'documents-icon',
    component: ChangeOrders,
  },
];

export default RouteList;
