exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".product_product-add-new__2EUed,\n.product_product-add-new__2EUed:hover,\n.product_product-add-new__2EUed:active,\n.product_product-add-new__2EUed:focus {\n  border-radius: 4px;\n  box-shadow: 0 8px 16px 0 rgba(0, 18, 45, 0.12);\n  background-color: #e21315;\n  color: #fff;\n  height: 42px;\n  font-weight: 600;\n  width: 100px;\n}\n.product_product-dropdown-btn__1JQPf,\n.product_product-dropdown-btn__1JQPf:active,\n.product_product-dropdown-btn__1JQPf:focus {\n  background-color: #eef0f4;\n  border-radius: 50%;\n  height: auto;\n  padding: 0 4px 1px;\n  width: auto;\n  border: none;\n}\n.product_product-dropdown-btn__1JQPf:hover {\n  background-color: #e4e6eb;\n}\n.product_update-menu-item__1rmCX {\n  display: flex;\n  align-items: center;\n  /* TODO: temporary removed. probably that can broke the mobile view */\n  /* background-color: #eef0f4;\n  padding-left: 0.5rem;\n  border-radius: 6px; */\n}\n.product_update-menu-item__1rmCX:hover {\n  background-color: #eef0f4;\n}\n.product_product-control-container__3Rw4q {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n}\n.product_product-filter-control__RittQ {\n  display: flex;\n  align-items: flex-start;\n  height: 100%;\n}\n.product_product-filter-title__1xXvH {\n  margin-right: 1rem;\n  color: #00122d;\n  font-size: 14px;\n  font-weight: 600;\n}\n.product_product-filters__2lbZM {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.product_arrow-drop__3sF3_ {\n  transform: rotate(180deg);\n}\n@media screen and (max-width: 801px) {\n  .product_product-filter-control__RittQ {\n    width: 100%;\n  }\n  .product_product-filters__2lbZM {\n    width: 100%;\n    margin-top: 1rem;\n    flex-direction: column;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"product-add-new": "product_product-add-new__2EUed",
	"product-dropdown-btn": "product_product-dropdown-btn__1JQPf",
	"update-menu-item": "product_update-menu-item__1rmCX",
	"product-control-container": "product_product-control-container__3Rw4q",
	"product-filter-control": "product_product-filter-control__RittQ",
	"product-filter-title": "product_product-filter-title__1xXvH",
	"product-filters": "product_product-filters__2lbZM",
	"arrow-drop": "product_arrow-drop__3sF3_"
};