import React from 'react';
import { Form, Button, Input, Card, Row, Col, Radio, notification } from 'antd';
import logo from '../../assets/images/logo_dark.svg';
import logo_big from '../../assets/images/login_big.jpg';
import css from './../../assets/styles/login.module.css';

import LoginPageServices from '../../services/LoginPageServices';

import { useTranslation } from 'react-i18next';
import { langs } from '../../utils/i18n';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const LanguageRadio = ({ i18n, changeLanguage }: any) => (
  <Radio.Group
    size="small"
    defaultValue={i18n.language}
    onChange={(e) => changeLanguage(e.target.value)}
    className={css['language-group-btn']}>
    {langs &&
      langs.map((lng: any) => (
        <Radio.Button key={lng.key} value={lng.key} className={css['language-btn']}>
          {lng.label}
        </Radio.Button>
      ))}
  </Radio.Group>
);

const RestorePasswordPage: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const loginPageServices = new LoginPageServices();
  const history = useHistory();

  const handleSubmit = (values: any) => {
    loginPageServices.restorePassword(values).then(
      (res: any) => {
        notification.info({ message: t('login.page.resrorePass.access') });
        history.push('/login');
      },
      (err) => {
        // TODO: create global cheking
        notification.error({
          message: t('login.page.resrorePass.error'),
          description: err.message,
        });
      },
    );
  };

  const changeLanguage = (key: string) => {
    if (i18n.language !== key) {
      i18n.changeLanguage(key);
    }
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: '100vh', background: '#f0f2f5' }}
      className={css['login-container']}>
      <Col>
        <div style={{ background: '#fff', width: '351px' }}>
          <img src={logo_big} alt="" className={css['login-img']} />
        </div>
      </Col>
      <Col>
        <Card style={{ width: 400, height: 500, border: 'none' }} className={css['restorePass-body']}>
          <img src={logo} alt="Logo" className={css['logo-login']} />
          <p className={css['login-form-title']}>{t('login.page.resrorePass.btn.restore')}</p>
          <Form onFinish={handleSubmit} className={css['login-form']}>
            <p className={css['login-title']}>{t('login.page.resrorePass.email.placeholder')}</p>
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('login.page.resrorePass.email.validation') }, { type: 'email' }]}>
              <Input className="common-animation-primary" style={{ borderRadius: '6px' }} autoComplete="off" />
            </Form.Item>
            <div style={{ height: '100%' }}>
              <Button
                type="primary"
                htmlType="submit"
                className={`${css['login-form-button']} common-primary-btn`}
                block
                shape="round">
                {t('login.page.resrorePass.btn.restore')}
              </Button>
            </div>
            <div style={{ height: '100%' }}>
              <Link to={'/login'} className={`${css['restorePass-btn']} common-animation-secondary`}>
                {t('login.page.btn')}
              </Link>
            </div>
          </Form>
          <div className={css['language-radio-restore']}>
            <LanguageRadio i18n={i18n} changeLanguage={changeLanguage} />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default RestorePasswordPage;
