import { Button } from 'antd';
import moment from 'moment';
import React from 'react';
import { TFunction } from 'react-i18next';
import { convertToPrice } from '../../../../../helpers/convertToPrice';
import { IQuote } from '../../../../../models/DashboardModel';
import { IStatusModel } from '../../../../../models/StatusModel';
import { IAmendmentModel } from '../../../../../models/AmendmentModel';

interface QuotesColumnsProps {
  t: TFunction<'translation'>;
  isDisabled: boolean;
  isQuote: boolean;
  redirectToConsumer: (propertyId: number) => void;
}

const colors: any = {
  new: '#1374e6',
  approved: '#19ac87',
  signed: '#00d29f',
};

const setImgByStatus = (status: IStatusModel) => {
  const { code, description } = status;
  return <span style={{ color: colors[code] || '#eb7d5a', fontSize: '14px' }}>{description.toLowerCase()}</span>;
};

export const getDiagramColumns = ({ t, redirectToConsumer, isDisabled, isQuote }: QuotesColumnsProps) => [
  {
    title: () => <span className="budget-table-tr">№</span>,
    key: 'number',
    width: '15%',
    render: (cell: IQuote) => <span>{cell.number}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('documents.table.column.description')}</span>,
    key: 'description',
    width: '20%',
    render: (cell: IQuote) => <span style={{ wordWrap: 'break-word' }}>{cell.description}</span>,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.sum')}</span>,
    key: isQuote ? 'sumWithVat' : 'deltaWithVat',
    width: '10%',
    render: (cell: IQuote | IAmendmentModel) =>
      `${cell.currency?.description || t('budget.currency')} ${
        isQuote
          ? (cell as IQuote).sumWithVat !== null
            ? convertToPrice((cell as IQuote).sumWithVat)
            : '-'
          : (cell as IAmendmentModel).deltaWithVat !== null
          ? convertToPrice((cell as IAmendmentModel).deltaWithVat)
          : '-'
      }`,
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.form.date.of.signature')}</span>,
    key: 'lastSignDateTime',
    width: '80px',
    render: (cell: IQuote) =>
      cell.statusV2?.code === 'signed' && !!cell.lastSignDateTime
        ? moment(cell.lastSignDateTime).format('DD.MM.YYYY HH:mm')
        : '',
  },
  {
    title: () => <span className="budget-table-tr">{t('budget.table.status')}</span>,
    key: 'status',
    width: '100px',
    render: (cell: IQuote) => setImgByStatus(cell.statusV2),
  },
  {
    title: () => <span className="budget-table-tr">{t('users.table.columns.action')}</span>,
    key: 'action',
    width: '65px',
    render: (cell: IQuote) => {
      return (
        <Button
          className="common-secondary-btn"
          style={{ borderColor: '#e21315', backgroundColor: '#eef0f4', borderRadius: '6px' }}
          disabled={isDisabled}
          onClick={() => redirectToConsumer(cell.property.id)}>
          <span style={{ color: '#e21315' }}>{t('consumers.table.columns.manage')}</span>
        </Button>
      );
    },
  },
];
