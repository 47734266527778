import React, { useContext, useEffect, useRef } from 'react';
import { Button, Divider, Dropdown, Layout, Menu, notification, Space } from 'antd';
import css from './../../assets/styles/header.module.css';
import {
  CHANGE_IS_NEED_OPEN_MODAL_SAVE,
  SET_IS_OPEN_HELPER,
  SET_LOGOUT_REF,
  SET_NON_AUTHENTICATED,
  SET_PROFILE,
} from '../../constants/actionTypes/appConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import LoginPageServices from '../../services/LoginPageServices';
import LogoComponent from './LogoComponent';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import i18n, { langs } from '../../utils/i18n';
import NotificationsBell from './NotificationsBell';
import { useLocation, useHistory } from 'react-router';
import UsersService from '../../services/UsersService';
import { setOnboarding } from '../../helpers/onboarding';
import { IAppContext } from '../../typings/IApp';
import iconInfo from '../../assets/images/icon-info.png';

const { Header } = Layout;
const loginService: LoginPageServices = new LoginPageServices();
const userService: UsersService = new UsersService();

const HeaderComponent: React.FC = (props: any) => {
  const {
    app,
    appDispatch,
    app: { user, isConsumer },
  } = useContext<IAppContext>(AppContext);
  const {
    header: { currentPage },
  } = useContext(HeaderContext);
  const location = useLocation();
  const logoutRef = useRef<HTMLImageElement>(null);
  const history = useHistory();
  const isDashboard = location.pathname === '/dashboard';

  useEffect(() => {
    if (user && user.language) {
      const userLang = langs.find((lang) => lang.key.split('-')[0] === user.language);
      if (userLang) {
        i18n.changeLanguage(userLang.key);
      }
    }
  }, [user]);

  useEffect(() => {
    const isClose = window.localStorage.getItem('isClosedHelper');
    if (isClose) {
      appDispatch({ type: SET_IS_OPEN_HELPER, isOpenHelper: false });
    }
  }, []);

  const logOut = async () => {
    if (app.isNeedSaveForProperties) {
      appDispatch({ type: SET_LOGOUT_REF, logoutRef: logoutRef });
      appDispatch({ type: CHANGE_IS_NEED_OPEN_MODAL_SAVE, isNeedOpenModalSave: true });
    } else {
      window.localStorage.removeItem('dashboardHelper');
      window.localStorage.removeItem('isClosedHelper');
      loginService.logOut().finally(() => {
        appDispatch({ type: SET_NON_AUTHENTICATED });
        props.history.push('/login');
      });
    }
  };

  const changeLanguage = async (key: string) => {
    if (i18n.language !== key && key.split('-')[0] !== user.language) {
      await userService
        .changeLanguage(key.split('-')[0])
        .then(() => {
          loginService
            .getProfile()
            .then((res) => {
              setOnboarding(res.boardCheckpoint);
              appDispatch({ type: SET_PROFILE, user: res });
            })
            .catch((e) =>
              notification.error({
                message: e.message,
              }),
            );
        })
        .catch((e) =>
          notification.error({
            message: e.message,
          }),
        );
    }
  };

  const menu = (
    <Menu className={css['dropdown-lang']}>
      {langs &&
        langs
          .filter((lang) => lang.key != i18n.language)
          .map((lang) => (
            <Menu.Item onClick={() => changeLanguage(lang.key)} className={css['dropdown-lang-item']} key={lang.key}>
              {lang.label}
            </Menu.Item>
          ))}
    </Menu>
  );

  return (
    <Header style={{ background: '#eef0f4', padding: 0, height: '40px' }} className="header-sh-box">
      <div className="container-header">
        <div className={css['page-title']}>
          <span className={css['page-name']}>{currentPage}</span>
        </div>
        <span className="profile">
          <Space split={<Divider type="vertical" />} size={4}>
            {!isConsumer && isDashboard && (
              <span
                className={css['header-profile-dropdown']}
                onClick={() => {
                  appDispatch({ type: SET_IS_OPEN_HELPER, isOpenHelper: true });
                }}>
                <img src={iconInfo} />
              </span>
            )}
            <span className={css['header-profile-dropdown']}>
              <NotificationsBell />
            </span>
            <Dropdown overlay={menu} overlayClassName={css['dropdown-lang']}>
              <Button className={css['lang-btn']}>
                <span className={css['lang-btn-container']}>
                  {langs.filter((lang) => lang.key === i18n.language)[0].label}
                  <div style={{ height: '6px', width: '10px', marginLeft: '10px' }} className={css['arrow']}></div>
                </span>
              </Button>
            </Dropdown>
            {/* TODO: uncommit when will be functional */}
            {/* <span className={css["header-mini-icon"]}>
              <img src={messageIcon} alt=""/>
              <span className={css["header-mini-icon-desc"]}>10</span>
            </span>
            <span className={css["header-mini-icon"]}>
              <img src={notifyIcon} alt=""/>
              <span className={css["header-mini-icon-desc"]} style={{backgroundColor: '#e21315'}}>100</span>
            </span>
            <span className={css['header-profile']} /> */}
            {app.user && (
              <span
                className={css['header-profile-dropdown']}
                onClick={() => {
                  isConsumer && history.push('/profile');
                }}>
                <LogoComponent
                  id={app.user.id}
                  name={`${app.user.firstName} ${app.user.lastName}`}
                  image={app.user.image || ''}
                />
                <span className={css['header-profile-username']}>
                  {app?.user ? [app.user.firstName, app.user.lastName].join(' ') : 'User Name'}
                </span>
              </span>
            )}
          </Space>
        </span>
        <div ref={logoutRef} className={`${css['log-out-btn']} common-animation-primary`} onClick={logOut}></div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
