import { notification, Spin, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHANGE_CURRENT_PAGE } from '../../constants/actionTypes/headerConstants';
import { HeaderContext } from '../../contexts/HeaderContextProvider';
import { InternalDocumentsModel } from '../../models/InternalDocumentsModel';
import InternalDocumentsService from '../../services/InternalDocumentsService';
import { getColumns } from './components/InternalDocumentsColumns';
import InternalDocumentsControl from './components/InternalDocumentsControl';
import SelectTypeModal from './components/SelectTypeModal';
import { generateFilterfromObj } from '../../helpers/generateFIlterfromObj';
import DeleteInternalForm from './components/DeleteInternalForm';
import PreviewPDF from './components/PreviewPDF';
import UploadInternalForm from './components/UploadInternalDocument/UploadInternalForm';
import ArchivesInternalDocuments from './components/ArchiveInternal/ArchiveInternal';
import ArchiveConfirmForm from './components/ArchiveInternal/ArchiveConfirmForm';
import { AppContext } from '../../contexts/AppContextProvider';
import { getStorageEnvelopeId, setStorageEnvelopeId } from '../../helpers/envelopeStorage';
import { docusignEventStatuses } from '../../constants/docusignEventStatuses';
import { getUriSearchValue } from '../../helpers/proccessUri';
import { useHistory } from 'react-router';
import SigningConfirmForm from '../common/SigningConfirmForm';
import { FiltersContext } from '../../contexts/FiltersContextProvider';
import { FilterTypes } from '../../constants/filterTypes';
import css from '../../assets/styles/internal.module.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

interface ICurrentInternal {
  documentType: string;
  documentName: string;
  currentId: number;
  isAllocate: boolean;
}

const internalService = new InternalDocumentsService();

const InternalDocuments = () => {
  const { headerDispatch } = useContext(HeaderContext);
  const { height: windowHeight } = useWindowDimensions();
  const {
    app: { user },
  } = useContext<IAppContext>(AppContext);
  const {
    filters: { internalDocumentsFilter },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [currentFilters, setCurrentFilters] = useState<any>({
    'type/code': {
      type: FilterTypes.SELECT,
      value: internalDocumentsFilter.type,
    },
    'status/code': {
      type: FilterTypes.SELECT,
      value: internalDocumentsFilter.status,
    },
    'signatories_filter/user/id': {
      type: FilterTypes.SELECT,
      value: internalDocumentsFilter.agent,
    },
    'consumer/id': {
      type: FilterTypes.SELECT,
      value: internalDocumentsFilter.consumer,
    },
    'project/id': {
      type: FilterTypes.SELECT,
      value: internalDocumentsFilter.project,
    },
    createdDate: {
      type: FilterTypes.DATE_RANGE,
      value: internalDocumentsFilter.dateRange,
    },
  });
  const [internalDocuments, setInternalDocuments] = useState<InternalDocumentsModel>([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [currentInternal, setCurrentInternal] = useState<ICurrentInternal | null>(null);
  const [isOpenArchiveConfirm, setIsOpenArchiveConfirm] = useState(false);
  const [isOpenArchives, setIsOpenArchives] = useState(false);
  const [isOpenSigningConfirm, setIsOpenSigningConfirm] = useState(false);
  const event = getUriSearchValue(location.search, 'event');
  const [isDocusignConfirm, setIsDocusignConfirm] = useState(
    !!event && event === docusignEventStatuses.signingComplete,
  );
  const [isDocusignDecline, setIsDocusignDecline] = useState(!!event && event === docusignEventStatuses.decline);
  const [signInfo, setSignInfo] = useState<string | null | undefined>(null);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
  });
  const userId = user.id;

  const option = {
    count: true,
    filters: {
      filter: generateFilterfromObj(currentFilters).filter,
    },
    top: pagination.pageSize,
    current: pagination.current,
    orderBy: ['createdDate desc'],
  };

  useEffect(() => {
    headerDispatch({
      type: CHANGE_CURRENT_PAGE,
      currentPage: t('internal.documents.title'),
      path: 'internal_signature',
    });
  }, [i18n.language]);

  useEffect(() => {
    const envelopeId = getStorageEnvelopeId();
    if (isDocusignConfirm) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignConfirm(false);
      } else {
        internalService
          .confirmSigning(envelopeId)
          .catch((e: any) => console.error(e))
          .finally(() => {
            setIsDocusignConfirm(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    } else if (isDocusignDecline) {
      setFetchingData(true);
      if (!envelopeId) {
        setFetchingData(false);
        setIsDocusignDecline(false);
      } else {
        internalService
          .cancelSigning(envelopeId)
          .catch((e: any) => console.error(e))
          .finally(() => {
            setIsDocusignDecline(false);
            setStorageEnvelopeId('');
            history.replace({ search: '' });
          });
      }
    }
    if (location.search.includes('event')) {
      history.replace({ search: '' });
    }
  }, []);

  const getInternalDocuments = (option: any) => {
    setFetchingData(true);
    internalService
      .getInternalDocumentsNoArchive(option)
      .then((res: any) => {
        const { count, items } = res;
        const { current, top } = option;
        setPagination({ ...pagination, current, total: count, pageSize: top });
        setInternalDocuments(items);
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      )
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    getInternalDocuments(option);
  }, [isDocusignConfirm]);

  const isEmptyFilter = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const applyNewFilter = (filters: any) => {
    setCurrentFilters({ ...currentFilters, ...filters });
    return { ...currentFilters, ...filters };
  };

  const onTableChange = (pagination?: any, filters?: any, sorter?: any) => {
    let params: any = {
      ...option,
    };

    if (filters && !isEmptyFilter(filters)) {
      params = {
        ...params,
        filters: generateFilterfromObj(applyNewFilter(filters)),
      };
    } else {
      params = {
        ...params,
        filters: generateFilterfromObj(currentFilters),
      };
    }
    if (pagination) {
      params = {
        ...params,
        top: pagination.pageSize,
        skip: pagination.pageSize * (pagination.current - 1),
        current: pagination.current,
      };
    }

    getInternalDocuments(params);
  };

  const setOpenDelete = (internalDocument: ICurrentInternal) => {
    setIsOpenDeleteModal(true);
    setCurrentInternal(internalDocument);
  };

  const setCloseDelete = () => {
    setIsOpenDeleteModal(false);
    setCurrentInternal(null);
  };

  const openPreview = (id?: number) => {
    setIsOpenPreview(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const closePreview = () => {
    setIsOpenPreview(false);
    setCurrentId(null);
    getInternalDocuments(option);
  };

  const openUpload = (internalDocument?: ICurrentInternal) => {
    setIsOpenUpload(true);
    if (internalDocument) {
      setCurrentInternal(internalDocument);
    }
  };

  const closeUpload = () => {
    setIsOpenUpload(false);
    setCurrentId(null);
    getInternalDocuments(option);
  };

  const openArchiveConfirm = (id?: number) => {
    setIsOpenArchiveConfirm(true);
    if (id) {
      setCurrentId(id);
    }
  };

  const closeArchiveConfirm = () => {
    setIsOpenArchiveConfirm(false);
    setCurrentId(null);
    getInternalDocuments(option);
  };

  const openSigningConfirm = (id?: number, signInfo?: string) => {
    setIsOpenSigningConfirm(true);
    if (id) {
      setCurrentId(id);
      setSignInfo(signInfo);
    }
  };

  const closeSigningConfirm = () => {
    setIsOpenSigningConfirm(false);
    setCurrentId(null);
    setSignInfo(null);
    getInternalDocuments(option);
  };

  const signInternalDocument = () => {
    if (currentId) {
      setFetchingData(true);
      internalService
        .getSigningUrl(currentId, window.location.href, userId)
        .then((res: any) => {
          const { alreadySigned = false, declined = false } = res;
          if (!alreadySigned && !declined) {
            setStorageEnvelopeId(res.envelopeId);
            window.location.href = res.redirectUrl;
          } else {
            if (alreadySigned) {
              notification.warning({
                message: t('quotes.modal.already.signed.message'),
              });
            }
            if (declined) {
              notification.warning({
                message: t('quotes.modal.already.decline.message'),
              });
            }
          }
        })
        .catch((e: any) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          setFetchingData(false);
        });
    }
  };

  const rejectDocument = (id: number | null, comment: string) => {
    if (id) {
      internalService
        .rejectInternalDocument(id, comment)
        .catch((e: any) =>
          notification.error({
            message: e.message,
          }),
        )
        .finally(() => {
          closeSigningConfirm();
        });
    }
  };

  return (
    <>
      <Spin spinning={fetchingData}>
        <InternalDocumentsControl setIsOpenSelect={setIsOpenSelect} onTableChange={onTableChange} />
        <Table
          columns={getColumns({
            t,
            setOpenDelete,
            openPreview,
            openArchiveConfirm,
            openUpload,
            userId,
            openSigningConfirm,
          })}
          dataSource={internalDocuments}
          showHeader={true}
          size="middle"
          scroll={{
            y: windowHeight - 280,
            x: 1000,
          }}
          onChange={onTableChange}
          style={{ marginTop: '1rem' }}
          className="consumers-info-table"
          rowClassName="common-table-row--pointer"
          rowKey={(record) => record.id}
          pagination={pagination}
        />
        <div className="common-flex-end-style">
          <span onClick={() => setIsOpenArchives(true)} className={`common-archive-icon-item common-animation-primary`}>
            <div className={css['archive-img']} style={{ marginRight: '0.5rem', width: '18px', height: '14px' }}></div>
            <span style={{ color: '#e21315', marginRight: '0.5rem', textDecoration: 'underline' }}>
              {t('common.archive.title')}
            </span>
          </span>
        </div>
        {isOpenSelect && (
          <SelectTypeModal isOpen={isOpenSelect} setIsOpen={setIsOpenSelect} setIsOpenUpload={openUpload} />
        )}
        {isOpenDeleteModal && (
          <DeleteInternalForm
            currentId={currentInternal?.currentId}
            documentName={currentInternal?.documentName}
            documentType={currentInternal?.documentType}
            getInternalDocuments={getInternalDocuments}
            option={option}
            isOpen={isOpenDeleteModal}
            setClose={setCloseDelete}
          />
        )}
        {isOpenPreview && <PreviewPDF id={currentId} isOpen={isOpenPreview} setClose={closePreview} />}
        {isOpenUpload && (
          <UploadInternalForm
            isOpenModal={isOpenUpload}
            currentId={currentInternal?.currentId}
            getInternalDocuments={getInternalDocuments}
            option={option}
            setClose={closeUpload}
            type={currentInternal?.documentType}
            isAllocate={currentInternal?.isAllocate}
          />
        )}
        {isOpenArchives && (
          <ArchivesInternalDocuments
            isOpen={isOpenArchives}
            setIsOpen={setIsOpenArchives}
            getInternalDocuments={getInternalDocuments}
            internalOption={option}
          />
        )}
        {isOpenArchiveConfirm && (
          <ArchiveConfirmForm id={currentId} isOpen={isOpenArchiveConfirm} setClose={closeArchiveConfirm} />
        )}
        {isOpenSigningConfirm && (
          <SigningConfirmForm
            isOpen={isOpenSigningConfirm}
            setClose={closeSigningConfirm}
            sign={signInternalDocument}
            fetchingData={fetchingData}
            signInfo={signInfo}
            reject={rejectDocument}
            id={currentId}
          />
        )}
      </Spin>
    </>
  );
};

export default InternalDocuments;
