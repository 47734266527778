import React, { useEffect, useState, useContext, useRef } from 'react';
import { Button, Card, Cascader, Col, Form, Input, notification, Row, Select, Spin, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ListPageServices from '../../services/PropertiesService';
import DictionariesServices from '../../services/DictionariesServices';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { imageURLToAntdModel } from '../../helpers/imageToAntdModel';
import Coordinates from './components/Coordinates';
import TypologiesService from '../../services/TypologiesService';
import DynamicFields from './components/DynamicFields';
import { AppContext } from '../../contexts/AppContextProvider';
import css from '../../assets/styles/properties.module.css';
import { useHistory, useParams } from 'react-router';
import { ProjectModel } from '../../models/ProjectModel';
import { StepsModel } from '../../models/TimelineModel';
import TimelineService from '../../services/TimelineService';
import ModalSaveForUnmount from '../../components/common/Modals/ModalSaveForUnmount';
import {
  CHANGE_IS_NEED_OPEN_MODAL_SAVE,
  CHANGE_IS_NEED_SAVE_PROPERTIES,
} from '../../constants/actionTypes/appConstants';
import UsersService from '../../services/UsersService';
import { useLessThen801 } from '../../helpers/mediaDetect';
import BoardTourComponent from '../common/BoardTourComponent';
import CustomOnBoard from '../common/CustomOnBoard';
import { getOnboarding, OnboardingTypes, saveOnboarding } from '../../helpers/onboarding';
import { IResponceData } from '../../typings/IServiceOptions';
import { IUserEasy } from '../../models/UserModel';
import { IAppContext } from '../../typings/IApp';
import i18n from '../../utils/i18n';

const { Option } = Select;

const dictionaryNames = [
  {
    dbName: 'property_statuses',
    key: 'propertyStatuses',
  },
  {
    dbName: 'property_types',
    key: 'propertyTypes',
  },
  {
    dbName: 'property_fields',
    key: 'propertyFields',
  },
];

const service: ListPageServices = new ListPageServices();
const dictionariesService: DictionariesServices = new DictionariesServices();
const typologiesService: TypologiesService = new TypologiesService();
const timelineService = new TimelineService();
const userService = new UsersService();

const EditPropertyPage = (props: any) => {
  const { t } = useTranslation();
  const {
    app: { user, isNeedOpenModalSave, logoutRef },
    appDispatch,
  } = useContext<IAppContext>(AppContext);
  const [form] = Form.useForm();

  const [item, setItem] = useState(null as any);
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState<{ [name: string]: string }>({});

  const [dictionaries, setDictionaries] = useState({
    propertyStatuses: [],
    propertyTypes: [],
    propertyFields: [],
  } as any);
  const [typologies, setTypologies] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [neighbours, setNeighbours] = useState([]);
  const [plans, setPlans] = useState([] as any[]);
  const [isTachPlans, setIsTachPlans] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isShowAddSelect, setIsShowAddSelect] = useState(false);
  const [deleteImages, setDeleteImages] = useState<number[]>([]);
  const [steps, setSteps] = useState<any>(null);
  const { projectId, id, parentId, defaultType } = useParams<any>();
  const history = useHistory();
  const params: any = useParams();
  const isCreate = !id;
  const projectKey = (user && user.projects.find((project) => project.id === +projectId)?.key) || '';
  const [type, setType] = useState('');
  const [fetchingData, setFetchingData] = useState(false);
  const [statuses, setStatuses] = useState<any>([]);
  const [isNeedSave, setIsNeedSave] = useState(false);
  const [isOpenModalSave, setIsOpenModalSave] = useState(false);
  const [agents, setAgents] = useState<IUserEasy[]>([]);
  const [pathName, setPathName] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [parentsId, setParentsId] = useState<any>([]);
  const [searchParentId, setSearchParentId] = useState<null | number>(null);
  const BtnSaveRef = useRef<HTMLElement>(null);

  const [isLastUserReq, setIsLastUserReq] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(false);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  //board
  const [boardSteps, setBoardSteps] = useState<any>([
    {
      target: '.property-modify-first-step',
      content: (
        <BoardTourComponent title={t('property.manage.first.title')} description={t('property.manage.first.text')} />
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.property-modify-second-step',
      content: (
        <BoardTourComponent title={t('property.manage.second.title')} description={t('property.manage.second.text')} />
      ),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.property-modify-third-step',
      content: (
        <BoardTourComponent title={t('property.manage.third.title')} description={t('property.manage.third.text')} />
      ),
      disableBeacon: true,
      placement: 'top',
    },
    {
      target: '.property-modify-forth-step',
      content: (
        <BoardTourComponent title={t('property.manage.forth.title')} description={t('property.manage.forth.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
    },
    {
      target: '.property-modify-fifth-step',
      content: (
        <BoardTourComponent title={t('property.manage.fifth.title')} description={t('property.manage.fifth.text')} />
      ),
      disableBeacon: true,
      placement: 'left',
    },
  ]);
  const [run, setRun] = useState(false);

  const handleCloseModal = () => {
    setIsOpenModalSave(false);
    appDispatch({ type: CHANGE_IS_NEED_OPEN_MODAL_SAVE, isNeedOpenModalSave: false });
  };
  const handleSaveModal = () => {
    handleCloseModal();
    BtnSaveRef.current?.click();
  };
  const handleContinueModal = () => {
    changeIsNeedSaveProperties(false);
    if (isNeedOpenModalSave) {
      return logoutRef.current.click();
    }
    history.push(pathName, [false]);
  };
  const changeIsNeedSaveProperties = (isNeedSave: boolean) => {
    appDispatch({ type: CHANGE_IS_NEED_SAVE_PROPERTIES, isNeedSaveForProperties: isNeedSave });
  };

  useEffect(() => {
    changeIsNeedSaveProperties(isNeedSave);
    const unblock = history.block(({ pathname, state }: any) => {
      setPathName(pathname);
      if (!isNeedSave || (state && !state[0])) {
        unblock();
        if (pathname) {
          return history.push(pathname);
        }
      }

      setIsOpenModalSave(true);
      return false;
    });
    return unblock;
  }, [isNeedSave, history]);

  useEffect(() => {
    form.resetFields();
    setPlans(item?.plans ? item.plans.map((image: any) => imageURLToAntdModel(image)) : []);
    if (item && item?.parent?.length > 0) {
      getParentsId(item.parent[item.parent.length - 1]);
    }
  }, [item]);

  useEffect(() => {
    if (user && projectKey) {
      setFetchingData(true);
      getAsyncData();
      getAgents();
    }
  }, [props?.match?.params?.id, projectKey, i18n.language]);

  const getAsyncData = async () => {
    const actions = [getTypologies(), getDictionaries(), getTimelineStatuses()];
    if (props?.match?.params?.id) {
      actions.push(getData());
      actions.push(getTimelines());
    }

    Promise.all(actions).finally(() => {
      setFetchingData(false);
      setRun(getOnboarding(isCreate ? OnboardingTypes.PROPERTY_MODIFY_NEW : OnboardingTypes.PROPERTY_MODIFY_EDIT));
    });
  };

  const searchChildrenIdInParent = (obj: any, searchId: number) => {
    if (obj.id === searchId) {
      return obj;
    }
    if (obj.childrens && obj.childrens.length > 0) {
      obj.childrens.find((el: any) => searchChildrenIdInParent(el, searchId));
    }
  };

  const getParentsId = async (id: number) => {
    setFetchingData(true);
    const parentsId = await formParentSequence({ parent: { id } }).finally(() => setFetchingData(false));
    setParentsId(parentsId);
    setSearchParentId(parentsId[0] || null);
    getParent(groups, parentsId[0]);

    form.setFieldsValue({
      parent: parentsId,
    });
  };

  const getParent = async (items: any, id: number | null) => {
    if (!id) {
      return;
    }
    loadData([
      Array.isArray(items)
        ? items.find((el: any) => searchChildrenIdInParent(el, id))
        : items?.childrens?.find((el: any) => searchChildrenIdInParent(el, id)),
    ]);
  };

  useEffect(() => {
    if (isFirstLoad) {
      if (parentId) {
        getParentsId(+parentId);
      }
      form.setFieldsValue({
        type: { code: defaultType || undefined },
      });
      setIsFirstLoad(false);
    }
  }, [defaultType, parentId]);

  useEffect(() => {
    let breads = props.history.location.pathname
      .replace('/properties', '')
      .replace(`${parentId}/`, '')
      .replace(/\/property\/\d+/, '/Modifications')
      .replace(/\/property/, `/${t('common.title.create')}`)
      .replace(/\/project/, `/${t('properties.breads.projects')}`)
      .split('/')
      .slice(1);
    breads.pop();
    breads.pop();
    breads.reduce(
      (res: any, curr: any, index: number) => ({
        ...res,
        [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
      }),
      { '/properties': t('properties.bread.root') },
    ),
      setBreadcrumbNameMap(
        breads.reduce(
          (res: any, curr: any, index: number) => ({
            ...res,
            [[Object.keys(res)[index], curr].join('/')]: curr.split(':').shift(),
          }),
          { '/properties': t('properties.bread.root') },
        ),
      );
  }, [props.history.location.pathname]);

  const getTimelines = () => {
    return timelineService
      .getTimeline(id)
      .then((value: StepsModel) => {
        setSteps(value);
      })
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const getTimelineStatuses = () => {
    return timelineService
      .getStatuses()
      .then((value: any) => setStatuses(value))
      .catch((e: any) =>
        notification.error({
          message: e.message,
        }),
      );
  };

  const getAgents = () => {
    const optionUser: any = {
      count: true,
      top: pagination.pageSize,
      current: pagination.current,
      skip: pagination.pageSize * (pagination.current - 1) || 0,
      projectId: [projectId],
      roles: ['agent', 'admin', 'super_agent'],
    };

    setFetchingUserData(true);
    userService
      .getEasyUsers(optionUser)
      .then((res: IResponceData<IUserEasy>) => {
        const { count, items } = res;
        const { current, top } = optionUser;
        setPagination({ ...pagination, current: current + 1, total: count, pageSize: top });
        setAgents([...agents, ...items]);
        setIsLastUserReq(current * top >= count);
      })
      .catch((e) => notification.error({ message: e.message }))
      .finally(() => setFetchingUserData(false));
  };

  const getNeighbours = (parentId: number | string) => {
    service
      .getPropertyWithFilter({
        searchObjects: { 'parent/id': String(parentId) },
        projectKey,
      })
      .then(setNeighbours);
  };

  const formParentSequence = async (item: any): Promise<any[]> => {
    if (item?.parent?.id) {
      const property = await service.getPropertyById({
        id: item.parent.id,
        projectKey,
      });
      const form = await formParentSequence(property);
      return [...form, item.parent.id];
    }
    return [];
  };

  const getDictionaries = () => {
    dictionaryNames.forEach((item) =>
      dictionariesService
        .getDictionary(item.dbName)
        .then((data) => {
          setDictionaries((prev: any) => ({ ...prev, [item.key]: data }));
        })
        .finally(() => setFetchingData(false)),
    );
    return service
      .getPropertyWithFilter({
        searchObjects: { parent: null },
        projectKey,
      })
      .then((data) => {
        propertiesTree(data);
      });
  };

  const getTypologies = () => {
    return typologiesService.getTypologies(projectKey).then((res: any) => {
      setTypologies(res);
    });
  };

  const getData = () => {
    return service
      .getPropertyById({
        id: props.match.params.id,
        projectKey,
      })
      .then(async (data) => {
        if (data?.parent?.id) {
          getNeighbours(data?.parent?.id);
        }
        setItem({
          ...data,
          neighbours: { id: data.neighbours?.map((item: any) => item.id) || [] },
          parent: await formParentSequence(data),
          agent: data.agent?.id || null,
          fields: data?.fields?.reduce((result: any, item: any) => {
            return {
              ...result,
              [item.field.code]: {
                value: item.value ? (isNaN(item.value) ? item.value : +item.value) : null,
                type: item.field.type,
                title: item.field.title,
                description: item.field.description,
                code: item.field.code,
                id: item.id,
                dictionaryName: item.field.dictionaryName,
              },
            };
          }, {}),
          viewCoordinates: data?.viewCoordinates?.reduce((result: any, item: any) => {
            return {
              ...result,
              [item.id]: {
                type: item.type || null,
                coordinates: item?.coordinates?.join(',') || null,
              },
            };
          }, {}),
        });
      });
  };

  const onFinish = (values: any) => {
    setIsSaving(true);

    const toUpdate = {
      title: values?.title || null,
      parent: values?.parent?.length ? values.parent[values.parent.length - 1] : null,
      type: values?.type?.code ? values?.type : null,
      status: values?.status?.code ? values?.status : null,
      isAvaliable: values?.isAvaliable || false,
      neighbours: values?.neighbours?.id
        ? values?.neighbours?.id?.map((id: any) => {
            return { id: id };
          })
        : null,
      fields: Object.entries(values?.fields || []).map(([code, element]: [any, any]) => ({
        id: item?.fields[code]?.id,
        value: element.value || null,
        field: dictionaries.propertyFields.find((item: any) => item.code === code),
      })),
      viewCoordinates: Object.entries(values?.viewCoordinates || []).map(([id, element]: [any, any]) => ({
        ...(isNaN(id) ? {} : { id: Number(id) }),
        type: element.type || null,
        coordinates: element?.coordinates?.split(',') || null,
      })),
      typology: !values?.typology?.id ? null : values?.typology,
      agent: values.agent ? { id: values.agent } : null,
    };

    service
      .saveProperty({
        body: toUpdate,
        id: props?.match?.params?.id,
        projectKey,
      })
      .then((res: any) => {
        const promises: Promise<any>[] = isTachPlans ? [service.saveImages(res.id, plans, 'plans')] : [];
        if (deleteImages && deleteImages.length > 0) {
          promises.push(service.deleteImages(deleteImages));
        }
        const newSteps = values.steps?.map((step: any, index: number) => {
          const newStep = {
            ord: index + 1,
            description: step.description,
            dateDescription: step.dateDescription || null,
          } as any;
          if (step.id) {
            newStep.id = step.id;
          }
          if (step.status?.code) {
            newStep.status = {
              code: step.status.code,
            };
          } else {
            newStep.status = null;
          }
          return newStep;
        });
        const stepsDTO = {
          steps: newSteps,
        } as any;

        if (values.copyTo?.length > 0) {
          const newCopyTo: any = [];
          values.copyTo.map((item: any) => newCopyTo.push(item[item.length - 1]));
          stepsDTO.copyToProperties = newCopyTo;
        }
        if ((values.steps && JSON.stringify(values.steps) !== JSON.stringify(steps)) || values.copyTo?.length > 0) {
          promises.push(timelineService.createTimeline(stepsDTO, res.id));
        }
        Promise.all(promises)
          .then(() => {
            notification.success({
              message: props?.match?.params?.id
                ? t('property.notification.success.update')
                : t('property.notification.success.create'),
            });
            setIsNeedSave(false);
            setIsSaving(false);
            history.goBack();
          })
          .catch((e) => {
            setIsSaving(false);
            notification.error({
              message: `Error while property ${props?.match?.params?.id ? 'update' : 'create'}`,
            });
          });
      })
      .catch((e) => {
        setIsSaving(false);
        notification.error({
          message: e.message,
        });
      })
      .finally(() => setFetchingData(false));
  };

  const handleShowSelect = (isShow?: boolean) => {
    setIsShowAddSelect(isShow === undefined ? !isShowAddSelect : isShow);
  };

  const onChangeDeleteList = (file: any) => {
    const hasProperty = Object.prototype.hasOwnProperty;
    if (!hasProperty.call(file, 'originFileObj')) {
      setDeleteImages([...deleteImages, +file.uid]);
    }
  };

  const loadData = async (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption?.loading) {
      targetOption.loading = true;
    }
    if (!targetOption) {
      return;
    }
    setFetchingData(true);
    const propertyChildren = await service
      .getPropertyChildren({
        id: targetOption?.id || targetOption,
        projectKey,
      })
      .finally(() => {
        if (targetOption?.loading) {
          targetOption.loading = false;
        }
        setFetchingData(false);
      });
    propertiesTree(propertyChildren, targetOption);
  };

  const propertiesTree = (data: any[], targetOption?: any) => {
    let newData: any[] = [];
    if (targetOption) {
      targetOption.childrens = data.map((item: any) => {
        const { childrens, ...rest } = item;
        return {
          ...rest,
          disabled: item.id === +params.id,
          isLeaf: childrens.length === 0,
        };
      });
    } else {
      newData = data.map((item: any) => {
        const { childrens, ...rest } = item;
        return {
          ...rest,
          disabled: item.id === +params.id,
          isLeaf: childrens.length === 0,
        };
      });
    }
    if (searchParentId && targetOption) {
      const index = parentsId.indexOf(searchParentId);
      let searchId;
      if (parentsId.length - 1 === index) {
        setSearchParentId(null);
        searchId = null;
      } else {
        setSearchParentId(index === -1 ? null : parentsId[index + 1]);
        searchId = index === -1 ? null : parentsId[index + 1];
      }

      getParent(targetOption, searchId);
    }
    setGroups(targetOption ? [...groups] : newData);
  };

  const onCancel = () => {
    history.push('/properties');
  };

  if (useLessThen801()) {
    return (
      <Form
        layout="vertical"
        onFieldsChange={() => setIsNeedSave(true)}
        onFinish={onFinish}
        form={form}
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
        initialValues={
          item
            ? {
                ...item,
              }
            : {}
        }>
        <Spin spinning={fetchingData} wrapperClassName="property-spin">
          <HeaderTable
            title={`${props?.match?.params?.id ? 'Modifications' : t('common.title.create')} ${item?.techType || ''}`}
            breadcrumb={breadcrumbNameMap}>
            <div style={{ marginTop: '1rem', display: 'flex' }}>
              <Button
                htmlType="button"
                onClick={onCancel}
                className={`common-blue-border-btn common-secondary-btn`}
                style={{ height: '42px', marginRight: '0.5rem', width: '100%' }}>
                {t('common.btn.cancel')}
              </Button>
              <Button
                loading={isSaving}
                name="submitButton"
                size="large"
                ref={BtnSaveRef}
                htmlType="submit"
                className="common-green-btn"
                style={{ width: '100%' }}>
                {t('common.btn.save')}
              </Button>
            </div>
          </HeaderTable>
          <Row gutter={24} style={{ height: '100%' }}>
            <Col span={24} style={{ marginBottom: '1rem' }}>
              <Card
                loading={!item && !!props?.match?.params?.id}
                style={{ padding: '1rem', borderRadius: '6px', height: '100%', overflowY: 'auto' }}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('properties.create.items.title')}</span>}
                      name="title">
                      <Input className="faq-modify-select common-animation-primary" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('properties.create.items.status')}</span>}
                      name={['status', 'code']}>
                      <Select
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        className="faq-modify-select common-animation-primary">
                        {dictionaries.propertyStatuses.map((item: any) => (
                          <Option key={item.code} value={item.code}>
                            {item?.description}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('properties.create.items.type')}</span>}
                      initialValue={defaultType || undefined}
                      name={['type', 'code']}>
                      <Select
                        onSelect={(value: any) => setType(value)}
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }
                        disabled={true}
                        className="faq-modify-select common-animation-primary">
                        {dictionaries.propertyTypes.map((item: any) => (
                          <Option key={item.code} value={item.code}>
                            {item?.description}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('properties.create.items.goods')}</span>}
                      name={['parent']}>
                      <Cascader
                        //NOTE: need research why is note supported in 4.17.0 v of antd
                        // suffixIcon={
                        //   <div
                        //     className={`arrow-drop-img ${css['arrow-drop']}`}
                        //     style={{ width: '8px', height: '4px' }}></div>
                        // }
                        className="faq-modify-select common-animation-primary"
                        placeholder={t('properties.create.items.goods.placeholder')}
                        fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
                        loadData={loadData}
                        options={groups}
                        disabled={true}
                        changeOnSelect
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ display: 'none' }}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('properties.create.items.neighbors')}</span>}
                      name={['neighbours', 'id']}>
                      <Select mode="multiple" className="faq-modify-select common-animation-primary">
                        {neighbours
                          .filter((element: any) => element?.id !== Number(props?.match?.params?.id))
                          .map((item: any) => (
                            <Option key={item.id} value={item.id}>
                              {item.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('properties.create.items.typologies')}</span>}
                      name={['typology', 'id']}>
                      <Select
                        allowClear
                        placeholder={t('properties.create.items.goods.placeholder')}
                        className="faq-modify-select common-animation-primary"
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }>
                        {typologies.map((item: any) => (
                          <Option key={item.id} value={item.id}>
                            {item?.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('consumers.table.columns.agent')}</span>}
                      name={['agent']}>
                      <Select
                        allowClear
                        placeholder={t('consumers.table.columns.agent')}
                        className="faq-modify-select common-animation-primary"
                        onPopupScroll={(e: any) => {
                          e.persist();
                          let target = e.target;
                          if (
                            !fetchingUserData &&
                            !isLastUserReq &&
                            target.scrollTop + target.offsetHeight === target.scrollHeight
                          ) {
                            getAgents();
                          }
                        }}
                        suffixIcon={
                          <div
                            className={`arrow-drop-img ${css['arrow-drop']}`}
                            style={{ width: '8px', height: '4px' }}></div>
                        }>
                        {agents.length > 0 &&
                          agents.map((item: any) => (
                            <Option key={item.id} value={item.id}>
                              {`${item.firstName} ${item.lastName}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <DynamicFields
                    name="fields"
                    isSelect={isShowAddSelect}
                    handleSelect={handleShowSelect}
                    fields={dictionaries.propertyFields}
                    values={item?.fields || {}}
                  />
                  <Col span={24}>
                    <Form.Item
                      label={<span style={{ color: '#778dac' }}>{t('properties.create.items.floorPlan')}</span>}
                      name="plans">
                      <div className="dropbox">
                        <Upload.Dragger
                          listType="picture"
                          accept=".pdf, image/*"
                          beforeUpload={() => false}
                          defaultFileList={plans}
                          style={{ borderRadius: '6px' }}
                          onChange={(e) => {
                            setIsTachPlans(true);
                            setPlans(e.fileList);
                          }}
                          onRemove={onChangeDeleteList}
                          multiple>
                          <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                            <PlusOutlined style={{ color: '#e21315' }} />
                          </p>
                        </Upload.Dragger>
                      </div>
                    </Form.Item>
                  </Col>
                  {((item && item.childrens.length === 0) || type === 'unit') && (
                    <Col span={24} style={{ marginTop: '24px', width: 'inherit' }}>
                      <Coordinates name="viewCoordinates" values={item?.viewCoordinates || {}} />
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
            {!((item && item.childrens.length === 0) || type === 'unit' || defaultType === 'unit') && (
              <Col span={24}>
                <Card
                  style={{ marginBottom: '24px', padding: '1rem', borderRadius: '6px' }}
                  loading={!item && !!props?.match?.params?.id}>
                  <Row gutter={12}>
                    <Col span={24} style={{ marginTop: '24px' }}>
                      <Coordinates name="viewCoordinates" values={item?.viewCoordinates || {}} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
            {((item && item.childrens.length === 0) || type === 'unit' || defaultType === 'unit') && (
              <Col span={24}>
                <Card style={{ padding: '1rem', borderRadius: '6px' }}>
                  <span style={{ fontSize: '18px' }}>{t('properties.steps.title')}</span>
                  {steps && steps.length > 0 && (
                    <Form.List
                      name="steps"
                      initialValue={
                        steps &&
                        steps.map((step: any) => {
                          return {
                            ...step,
                          };
                        })
                      }>
                      {(fields, { add, remove }) => {
                        return (
                          <div style={{ marginTop: '1rem' }}>
                            {fields.map((field, index) => {
                              return (
                                <div key={field.key}>
                                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <span className={css['step-index']}>{index + 1}</span>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                      }}>
                                      <div style={{ display: 'flex' }}>
                                        <Form.Item
                                          name={[index, 'description']}
                                          style={{ margin: 0, width: '100%' }}
                                          rules={[
                                            { required: true, message: t('common.validation.required') },
                                            { max: 40, message: t('properties.length.rule') },
                                          ]}>
                                          <Input
                                            className="consumer-form-input common-animation-primary"
                                            style={{ width: '100%' }}
                                          />
                                        </Form.Item>
                                        <Button
                                          className={css['btn-delete']}
                                          onClick={() => remove(field.name)}
                                          style={{ marginLeft: '1rem', border: 'none' }}></Button>
                                      </div>
                                      <Row
                                        gutter={[24, 4]}
                                        style={{ marginTop: '1rem' }}
                                        className={css['steps-settings-bottom-container']}>
                                        <Col span={16} className={css['steps-settings-bottom-item']}>
                                          <Form.Item
                                            name={[index, 'dateDescription']}
                                            className={css['steps-settings-bottom-item-form']}
                                            label={
                                              <span style={{ color: '#778dac' }}>
                                                {t('calendar.status.title').toLowerCase()}
                                              </span>
                                            }>
                                            <Input
                                              className={`${css['property-date-input']} common-animation-primary`}
                                              placeholder={t('calendar.data.placeholder')}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={8} className={css['steps-settings-bottom-item']}>
                                          <Form.Item
                                            name={[index, 'status', 'code']}
                                            initialValue="to_do"
                                            rules={[{ required: true, message: t('common.validation.required') }]}
                                            label={
                                              <span style={{ color: '#778dac' }}>
                                                {t('requests.view.card.info.status').toLowerCase()}
                                              </span>
                                            }>
                                            <Select
                                              className="faq-modify-select common-animation-primary"
                                              suffixIcon={
                                                <div
                                                  className={`arrow-drop-img ${css['arrow-drop']}`}
                                                  style={{ width: '8px', height: '4px' }}></div>
                                              }>
                                              {statuses.map((status: any) => (
                                                <Option value={status.code} key={status.code}>
                                                  {status.description}
                                                </Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <Button
                              className={`${css['property-add-new']} common-primary-btn`}
                              style={{ height: '30px', width: '100%' }}
                              onClick={() => add()}>
                              {t('budget.upload.attachment.btn.add')}
                            </Button>
                          </div>
                        );
                      }}
                    </Form.List>
                  )}
                  {((steps && steps.length === 0) || !steps) && (
                    <>
                      <br />
                      <Button
                        className={`${css['property-add-new']} common-primary-btn`}
                        style={{ height: '30px', width: 'fit-content', marginTop: '1rem' }}
                        onClick={() => setSteps([{ description: null }])}>
                        {t('budget.upload.attachment.btn.add')}
                      </Button>
                    </>
                  )}
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.copy.to')}</span>}
                    name="copyTo"
                    style={{ marginTop: '1rem' }}>
                    <Cascader
                      //NOTE: need research why is note supported in 4.17.0 v of antd
                      // suffixIcon={
                      //  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                      //}
                      className="faq-modify-select common-animation-primary"
                      placeholder={t('properties.create.items.goods.placeholder')}
                      fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
                      options={groups}
                      loadData={loadData}
                      multiple
                      changeOnSelect
                    />
                  </Form.Item>
                </Card>
              </Col>
            )}
          </Row>
        </Spin>
        <ModalSaveForUnmount
          isVisible={isNeedOpenModalSave || isOpenModalSave}
          handleClose={handleCloseModal}
          handleSave={handleSaveModal}
          handleContinue={handleContinueModal}
        />
      </Form>
    );
  }

  return (
    <Form
      layout="vertical"
      onFieldsChange={() => setIsNeedSave(true)}
      onFinish={onFinish}
      form={form}
      style={{ paddingRight: '1rem', height: 'calc(100% - 16px)', overflow: 'hidden' }}
      initialValues={
        item
          ? {
              ...item,
            }
          : {}
      }>
      <CustomOnBoard
        steps={
          defaultType === 'unit'
            ? boardSteps
            : boardSteps.filter((boardStep: any) => boardStep.target !== '.property-modify-forth-step')
        }
        run={run}
        onFinish={() =>
          saveOnboarding(
            isCreate ? OnboardingTypes.PROPERTY_MODIFY_NEW : OnboardingTypes.PROPERTY_MODIFY_EDIT,
            user.boardCheckpoint,
          )
        }
      />
      <Spin spinning={fetchingData} wrapperClassName="property-spin">
        <HeaderTable
          title={`${props?.match?.params?.id ? 'Modifications' : t('common.title.create')} ${item?.techType || ''}`}
          breadcrumb={breadcrumbNameMap}>
          <div className="property-modify-fifth-step" style={{ display: 'flex' }}>
            <Button
              htmlType="button"
              onClick={onCancel}
              className={`common-blue-border-btn common-secondary-btn`}
              style={{ height: '42px', marginRight: '0.5rem', width: '100%' }}>
              {t('common.btn.cancel')}
            </Button>
            <Button
              loading={isSaving}
              name="submitButton"
              size="large"
              ref={BtnSaveRef}
              htmlType="submit"
              className="common-green-btn"
              style={{ width: 'auto' }}>
              {t('common.btn.save')}
            </Button>
          </div>
        </HeaderTable>
        <Row gutter={24} style={{ height: '100%' }}>
          <Col span={12} style={{ height: '90%' }}>
            <Card loading={!item && !!props?.match?.params?.id} className={css['property-edit-card-left']}>
              <Row gutter={12} className="property-modify-first-step">
                <Col span={24}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.items.title')}</span>}
                    name="title">
                    <Input className="faq-modify-select common-animation-primary" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.items.status')}</span>}
                    name={['status', 'code']}>
                    <Select
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }
                      className="faq-modify-select common-animation-primary">
                      {dictionaries.propertyStatuses.map((item: any) => (
                        <Option key={item.code} value={item.code}>
                          {item?.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.items.type')}</span>}
                    name={['type', 'code']}>
                    <Select
                      onSelect={(value: any) => setType(value)}
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }
                      disabled={true}
                      className="faq-modify-select common-animation-primary">
                      {dictionaries.propertyTypes.map((item: any) => (
                        <Option key={item.code} value={item.code}>
                          {item?.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.items.goods')}</span>}
                    name={['parent']}>
                    <Cascader
                      //NOTE: need research why is note supported in 4.17.0 v of antd
                      // suffixIcon={
                      //   <div
                      //     className={`arrow-drop-img ${css['arrow-drop']}`}
                      //     style={{ width: '8px', height: '4px' }}></div>
                      // }
                      className="faq-modify-select common-animation-primary"
                      placeholder={t('properties.create.items.goods.placeholder')}
                      fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
                      loadData={loadData}
                      options={groups}
                      disabled={true}
                      changeOnSelect
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ display: 'none' }}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.items.neighbors')}</span>}
                    name={['neighbours', 'id']}>
                    <Select mode="multiple" className="faq-modify-select common-animation-primary">
                      {neighbours
                        .filter((element: any) => element?.id !== Number(props?.match?.params?.id))
                        .map((item: any) => (
                          <Option key={item.id} value={item.id}>
                            {item.title}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.items.typologies')}</span>}
                    name={['typology', 'id']}>
                    <Select
                      allowClear
                      placeholder={t('properties.create.items.goods.placeholder')}
                      className="faq-modify-select common-animation-primary"
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }>
                      {typologies
                        .sort((a, b) => a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()))
                        .map((item: any) => (
                          <Option key={item.id} value={item.id}>
                            {item?.title}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('consumers.table.columns.agent')}</span>}
                    name={['agent']}>
                    <Select
                      allowClear
                      placeholder={t('consumers.table.columns.agent')}
                      className="faq-modify-select common-animation-primary"
                      dropdownClassName={`${fetchingUserData ? 'consumers-control-select-dropdown--progress' : ''}`}
                      onPopupScroll={(e: any) => {
                        e.persist();
                        let target = e.target;
                        if (
                          !fetchingUserData &&
                          !isLastUserReq &&
                          target.scrollTop + target.offsetHeight === target.scrollHeight
                        ) {
                          getAgents();
                        }
                      }}
                      suffixIcon={
                        <div
                          className={`arrow-drop-img ${css['arrow-drop']}`}
                          style={{ width: '8px', height: '4px' }}></div>
                      }>
                      {agents.length > 0 &&
                        agents.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {`${item.firstName} ${item.lastName}`}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className="property-modify-second-step">
                <DynamicFields
                  name="fields"
                  isSelect={isShowAddSelect}
                  handleSelect={handleShowSelect}
                  fields={dictionaries.propertyFields}
                  values={item?.fields || {}}
                />
              </Row>
              <Row gutter={12}>
                <Col span={24} className="property-modify-third-step">
                  <Form.Item
                    label={<span style={{ color: '#778dac' }}>{t('properties.create.items.floorPlan')}</span>}
                    name="plans">
                    <div className="dropbox">
                      <Upload.Dragger
                        listType="picture"
                        accept=".pdf, image/*"
                        beforeUpload={() => false}
                        defaultFileList={plans}
                        style={{ borderRadius: '6px' }}
                        onChange={(e) => {
                          setIsTachPlans(true);
                          setPlans(e.fileList);
                        }}
                        onRemove={onChangeDeleteList}
                        multiple>
                        <p className="ant-upload-drag-icon" style={{ marginTop: 0, marginBottom: 0 }}>
                          <PlusOutlined style={{ color: '#e21315' }} />
                        </p>
                      </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Col>
                {((item && item.childrens.length === 0) || type === 'unit') && (
                  <Col span={24} style={{ marginTop: '24px', width: 'inherit' }}>
                    <Coordinates name="viewCoordinates" values={item?.viewCoordinates || {}} />
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
          {!((item && item.childrens.length === 0) || type === 'unit' || defaultType === 'unit') && (
            <Col span={12} style={{ overflowY: 'auto', height: '85%' }}>
              <Card
                style={{ marginBottom: '24px', padding: '1rem', borderRadius: '6px' }}
                loading={!item && !!props?.match?.params?.id}>
                <Row gutter={12}>
                  <Col span={24} style={{ marginTop: '24px' }}>
                    <Coordinates name="viewCoordinates" values={item?.viewCoordinates || {}} />
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
          {((item && item.childrens.length === 0) || type === 'unit' || defaultType === 'unit') && (
            <Col span={12} style={{ overflowY: 'auto', height: '85%' }} className="property-modify-forth-step">
              <Card style={{ padding: '1rem', borderRadius: '6px' }}>
                <span style={{ fontSize: '18px' }}>{t('properties.steps.title')}</span>
                {steps && steps.length > 0 && (
                  <Form.List
                    name="steps"
                    initialValue={
                      steps &&
                      steps.map((step: any) => {
                        return {
                          ...step,
                        };
                      })
                    }>
                    {(fields, { add, remove }) => {
                      return (
                        <div style={{ marginTop: '1rem' }}>
                          {fields.map((field, index) => {
                            return (
                              <div key={field.key}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                  <span className={css['step-index']}>{index + 1}</span>
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                    <div style={{ display: 'flex' }}>
                                      <Form.Item
                                        name={[index, 'description']}
                                        style={{ margin: 0 }}
                                        rules={[
                                          { required: true, message: t('common.validation.required') },
                                          { max: 40, message: t('properties.length.rule') },
                                        ]}>
                                        <Input
                                          className="consumer-form-input common-animation-primary"
                                          style={{ width: '400px' }}
                                        />
                                      </Form.Item>
                                      <Button
                                        className={css['btn-delete']}
                                        onClick={() => remove(field.name)}
                                        style={{ marginLeft: '1rem', border: 'none' }}></Button>
                                    </div>
                                    <Row gutter={[24, 4]} style={{ marginTop: '1rem' }}>
                                      <Col span={16}>
                                        <Form.Item
                                          name={[index, 'dateDescription']}
                                          label={
                                            <span style={{ color: '#778dac' }}>
                                              {t('calendar.status.title').toLowerCase()}
                                            </span>
                                          }>
                                          <Input
                                            className={`${css['property-date-input']} common-animation-primary`}
                                            placeholder={t('calendar.data.placeholder')}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <Form.Item
                                          name={[index, 'status', 'code']}
                                          initialValue="to_do"
                                          rules={[{ required: true, message: t('common.validation.required') }]}
                                          label={
                                            <span style={{ color: '#778dac' }}>
                                              {t('requests.view.card.info.status').toLowerCase()}
                                            </span>
                                          }>
                                          <Select
                                            className="faq-modify-select common-animation-primary"
                                            suffixIcon={
                                              <div
                                                className={`arrow-drop-img ${css['arrow-drop']}`}
                                                style={{ width: '8px', height: '4px' }}></div>
                                            }>
                                            {statuses.map((status: any) => (
                                              <Option value={status.code} key={status.code}>
                                                {status.description}
                                              </Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <Button
                            className={`${css['property-add-new']} common-primary-btn`}
                            style={{ height: '30px', width: 'fit-content' }}
                            onClick={() => add()}>
                            {t('budget.upload.attachment.btn.add')}
                          </Button>
                        </div>
                      );
                    }}
                  </Form.List>
                )}
                {((steps && steps.length === 0) || !steps) && (
                  <>
                    <br />
                    <Button
                      className={`${css['property-add-new']} common-primary-btn`}
                      style={{ height: '30px', width: 'fit-content', marginTop: '1rem' }}
                      onClick={() => setSteps([{ description: null }])}>
                      {t('budget.upload.attachment.btn.add')}
                    </Button>
                  </>
                )}
                <Form.Item
                  label={<span style={{ color: '#778dac' }}>{t('properties.create.copy.to')}</span>}
                  name="copyTo"
                  style={{ marginTop: '1rem' }}>
                  <Cascader
                    //NOTE: need research why is note supported in 4.17.0 v of antd
                    // suffixIcon={
                    //  <div className={`arrow-drop-img ${css['arrow-drop']}`} style={{ width: '8px', height: '4px' }}></div>
                    //}
                    className="faq-modify-select common-animation-primary"
                    placeholder={t('properties.create.items.goods.placeholder')}
                    fieldNames={{ label: 'title', value: 'id', children: 'childrens' }}
                    options={groups}
                    loadData={loadData}
                    multiple
                    changeOnSelect
                  />
                </Form.Item>
              </Card>
            </Col>
          )}
        </Row>
      </Spin>
      <ModalSaveForUnmount
        isVisible={isNeedOpenModalSave || isOpenModalSave}
        handleClose={handleCloseModal}
        handleSave={handleSaveModal}
        handleContinue={handleContinueModal}
      />
    </Form>
  );
};

export default EditPropertyPage;
